import { Component, ViewEncapsulation } from '@angular/core';
import { ModalService } from '../shared/services/modal.service';

@Component({
  selector: 'materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MaterialsComponent {
  orderMaterialsMessageBody = "I would like to order MyClassroomEconomy materials! Additional information regarding my request can be found below:";
  orderMaterialsMessageSubject = "Order Kit for My Classroom Economy";

  constructor(
    public modalService: ModalService
  ) {}
}
