<div class="modal fade" id="propertySimulator" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 400px">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="myModalLabel">Insurance Simulator - What's the Damage?</h4>
      </div>
      <div class="modal-body">
        <p>
          <img src="assets/images/INSURANCE_01.png" class="img" />
          <br />
          <span class="damage">No Damage: </span>
          <span class="cost">Pay $0.</span>
          <br />
          <button class="btn btn-default" onclick="runPropertySim()">Generate</button>
          <br />
        </p>
        <hr />
        <strong>Insurance Costs:</strong>
        <p>
          One time Yearly payment of:
          <strong>$1,200</strong>
          <br /> or monthly payments of:
          <strong>$200</strong>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="autoSimulator" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 475px">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="myModalLabel">Insurance Simulator - What's the Damage?</h4>
      </div>
      <div class="modal-body">
        <p>
          <img src="assets/images/AUTO_01.png" class="img" />
          <br />
          <span class="damage">No Damage </span>
          <span class="cost">Pay $0.</span>
          <br />
          <button class="btn btn-default" onclick="runAutoSim()">Generate</button>
          <br />
        </p>
        <hr />
        <strong>Insurance Costs:</strong>
        <p>
          One time Yearly payment of:
          <strong>$1,200</strong>
          <br /> or monthly payments of:
          <strong>$200</strong>
        </p>
      </div>
    </div>
  </div>
</div>


<div class="parallax">
  <div class="gradesHeaderImg container">
    <div class="heroContent english">
      <h1>Grades 11&ndash;12</h1>
    </div>
    <div class="heroContent spanish hidden">
      <h1>Grados 11&ndash;12</h1>
    </div>
  </div>
  <section class="backgroundImages" style="top: 0px;">
    <div class="centeredDiv zoom-images">
      <div class="heroOverlay" data-start="background:rgba(0, 0, 0, 0)" data-600-start="background:rgba(0, 0, 0, 0.5)" anchor-target="body"></div>
      <div id="highschoolHero" class="backgroundImage" data-start="transform: scale(1)" data-600-start="transform: scale(1.3)"
           anchor-target="body"></div>
    </div>
  </section>
  <div class="container main" id="skrollr-body">
    <!--<div  class="gradeIndicator nineten col-lg-12"><p>Grades K—1</p></div>-->

    <div class="col-md-4 col-sm-12 col-xs-12 sidebar nineten">
      <div class="sidebarNav">
                <span class="accordionCloseFull" id="gettingStarted" data-toggle="collapse" href="#collapse1">
                    <h4 class="nineten english">Getting started</h4>
                    <h4 class="nineten spanish hidden">Empezando</h4>
                </span>
        <ul class="panel-collapse collapse in accordion" id="collapse1">
          <li href="#howWork" class="active sideNav english">How it works</li>
          <li href="#curStand" class="sideNav english">Curriculum standards</li>
          <li href="#howWork" class="active sideNav spanish hidden">Cómo funciona</li>
          <li href="#curStand" class="sideNav spanish hidden">Estándares curriculares</li>
        </ul>
      </div>
      <div class="sidebarNav">
                <span class="accordionOpenFull" id="planning" data-toggle="collapse" href="#collapse2">
                    <h4 class="nineten english">Planning</h4>
                    <h4 class="nineten spanish hidden">Planificación</h4>
                </span>
        <ul class="panel-collapse collapse accordion" id="collapse2">
          <li href="#proOut" class="sideNav english">Program outline</li>
          <li href="#ecoSystem" class="sideNav english">Economic system</li>
          <li href="#proOut" class="sideNav spanish hidden">Esquema del programa</li>
          <li href="#ecoSystem" class="sideNav spanish hidden">Sistema económico</li>
          <ul>
            <li href="#investing" class="sideNav investorsim english">Investing</li>
            <li href="#insurance" class="sideNav english">Insurance</li>
            <li href="#investing" class="sideNav investorsim spanish hidden">Invertir</li>
            <li href="#insurance" class="sideNav spanish hidden">Seguro</li>
          </ul>
          <li href="#prepMat" class="sideNav english">Preparing materials</li>
          <li href="#rewards" class="sideNav english">Rewards</li>
          <li href="#advMod" class="sideNav english">Advanced modules</li>
          <li href="#prepMat" class="sideNav spanish hidden">Preparación de materiales</li>
          <li href="#rewards" class="sideNav spanish hidden">Recompensas</li>
          <li href="#advMod" class="sideNav spanish hidden">Módulos avanzados</li>
        </ul>
      </div>
      <div class="sidebarNav">
                <span class="accordionOpenFull" id="activities" data-toggle="collapse" href="#collapse3">
                    <h4 class="nineten english">Activities</h4>
                    <h4 class="nineten spanish hidden">Ocupaciones</h4>
                </span>
        <ul class="panel-collapse collapse accordion" id="collapse3">
          <li href="#firstDay" class="sideNav english">First day</li>
          <li href="#secondDay" class="sideNav english">Second day</li>
          <li href="#everyDay" class="sideNav english">Every day</li>
          <li href="#jobAss" class="sideNav english">Job assignment</li>
          <li href="#syllabus" class="sideNav english">Syllabus day</li>
          <li href="#payAndAuc" class="sideNav english">Payday and auction</li>
          <li href="#billday" class="sideNav english">Bill day</li>
          <li href="#taxDay" class="sideNav english">Tax day</li>
          <li href="#budgetDay" class="sideNav english">Budget day</li>
          <li href="#creditDay" class="sideNav english">Credit card day</li>
          <li href="#inflationDay" class="sideNav english">Inflation day</li>
          <li href="#yearEnd" class="sideNav english">Year-end wrap-up</li>
          <li href="#firstDay" class="sideNav spanish hidden">Primer día</li>
          <li href="#secondDay" class="sideNav spanish hidden">Segundo día</li>
          <li href="#everyDay" class="sideNav spanish hidden">Cada día</li>
          <li href="#jobAss" class="sideNav spanish hidden">Asignación de trabajo</li>
          <li href="#syllabus" class="sideNav spanish hidden">Día del plan de estudios</li>
          <li href="#payAndAuc" class="sideNav spanish hidden">Día de pago y subasta</li>
          <li href="#billday" class="sideNav spanish hidden">Día de factura</li>
          <li href="#taxDay" class="sideNav spanish hidden">Día de impuestos</li>
          <li href="#budgetDay" class="sideNav spanish hidden">Día de presupuesto</li>
          <li href="#creditDay" class="sideNav spanish hidden">Día de la tarjeta de crédito</li>
          <li href="#inflationDay" class="sideNav spanish hidden">Día de la inflación</li>
          <li href="#yearEnd" class="sideNav spanish hidden">Cierre de fin de año</li>
        </ul>
      </div>
      <hr>
      <div class="printguide english">
        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Program_Guide_11-12.pdf')">
          Print complete program grades 11-12</a>
      </div>
      <div class="printguide spanish hidden">
        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Program_Guide_11-12_ES.pdf')">
          Imprimir programa completo grados 11-12
        </a>
      </div>
    </div>

    <div class="col-md-8 col-sm-12 col-xs-12 gradeContent nineten">

      <!-- HOW IT WORKS -->
      <div id="howWork" class="gradesInnerContent">
        <h3 class="nineten english">How My Classroom Economy Works</h3>
        <h3 class="nineten spanish hidden">Como Funciona My Classroom Economy</h3>
        <div class="infoBlock">
          <div class="english">
            <p>The classroom economy program is an effective and enjoyable way to teach your students fundamental
              financial literacy concepts without altering your curriculum. Implementing the program is an
              easy three-step process:
            </p>
            <ul class="denselist">
              <li>Before the school year starts, you spend a few hours gathering materials and planning how to
                customize the program for your school and your own classroom.</li>
              <li>During the first month of school, you spend some classroom time introducing the program and training
                the students to complete their tasks.</li>
              <li>Throughout the year, you monitor the students' progress and provide support and guidance when
                necessary.
              </li>
            </ul>
            <p>As they perform their tasks, the students will be helping you manage the classroom and, in the process,
              learning valuable skills—such as responsibility, the value of saving, and delayed gratification—that
              they will carry with them throughout life.</p>

            <p>The beauty of the program is that you don't need to teach these lessons; rather, your students will
              experience them and learn for themselves.</p>

            <h3 class="nineten">Core objectives for students in Grades 11&#8211;12</h3>

            <p>Through My Classroom Economy, students learn valuable life skills in an experiential way.</p>
            <p>Specifically, they are able to:</p>

            <ul>
              <li>Take part in a simulation of real-world economic activity.</li>
              <li>Learn the value of earning a paycheck, as they bank the salaries from their jobs.</li>
              <li>Learn how to budget their spending, manage a loan, balance a checking account, and make scheduled
                payments.
              </li>
              <li>Understand the importance of sound recordkeeping.</li>
              <li>Discover the importance of saving money for future goals.</li>
              <li>Describe the purpose of paying taxes.</li>
              <li>Explain the benefits of holding insurance.</li>
              <li>Find out that every decision has an opportunity cost.</li>
            </ul>

            <p>At these grade levels, students learn more advanced financial concepts. In addition to earning a
              salary and paying bills, students are also encouraged to keep a more detailed budget to help
              manage the costs of purchasing insurance and investing in a simulated retirement account.</p>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>La economía de la clase es un programa efectivo y divertido que les enseña a sus estudiantes los
              componentes fundamentales para aprender finanzas sin alterar su currículo. Implementar el programa
              es un proceso sencillo que se hace en tres pasos:</p>
            <ul class="denselist">
              <li>Antes de que comience el año escolar, pase algunas horas reuniendo materiales y planificando
                cómo personalizar el programa para su escuela y su propia clase.</li>
              <li>Durante el primer mes de clases, pase un tiempo en la clase presentando el programa y enseñándoles
                a los estudiantes como completar sus tareas.</li>
              <li>Durante todo el año, supervise el progreso de los estudiantes y proporcione apoyo y orientación
                cuando sea necesario.</li>
            </ul>

            <p>A medida que realizan sus tareas, los estudiantes lo ayudarán a administrar la clase y en el proceso,
              aprenderán habilidades valiosas, como la responsabilidad, el valor del ahorro y la gratificación
              retrasada, que llevarán consigo durante toda la vida.</p>
            <p>La belleza del programa es que usted no necesita enseñar estas lecciones; más bien, sus estudiantes
              tienen estas experiencias y aprenderán por sí mismos.</p>

            <h3 class="nineten">Objetivos principales para los estudiantes en Grados 11&#8211;12</h3>

            <p>A través de My Classroom Economy, los estudiantes aprenden valiosas habilidades para la vida de una
              manera experiencial.
            </p>
            <p>Los estudiantes son capas de:</p>

            <ul>
              <li>Participar en una simulación de la economía del mundo real.</li>
              <li>Aprender el valor de ganar su propio sueldo.</li>
              <li>Aprender como presupuestar sus gastos, administrar préstamos, equilibrar una cuenta de cheque,
                y hacer pagos programados.</li>
              <li>Comprender la importancia de mantener un presupuesto.</li>
              <li>Descubrir la importancia de ahorrar dinero para futuros objetivos.</li>
              <li>Describir el propósito de pagar impuestos.</li>
              <li>Explicar los beneficios de tener seguros.</li>
              <li>Descubrir que cada decisión tiene un precio.</li>
            </ul>

            <p>En estos niveles de grado, los estudiantes aprenden conceptos financieros más avanzados. Además de
              ganar un salario y pagar facturas, los estudiantes también son alentados a mantener un presupuesto
              más detallado para ayudar a manejar los costos de compra de seguros e invertir en una cuenta
              de jubilación simulada.
            </p>
          </div>
        </div>
      </div>

      <!-- CURRICULUM STANDARDS -->
      <div id="curStand" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Connections to Financial Literacy Standards</h3>
        <h3 class="nineten spanish hidden">Conexiones a los conceptos financieros</h3>
        <div class="infoBlock">
          <p class="english">My Classroom Economy is designed to help students learn financial literacy from an early age. This page
            will help you to connect common financial literacy standards with the experience of the classroom
            economy.
          </p>

          <p class="english">Our program addresses many of the standards included in the Jump$tart Coalition for Personal Financial
            Literacy's
            <a href="http://www.jumpstart.org/national-standards.html" target="_blank">National Standards in K-12 Personal Finance Education</a>.</p>

          <table class="table">
            <th class="english">
              <h3>Standard</h3>
            </th>
            <th class="english">
              <h3>My Classroom Economy</h3>
            </th>
            <tr class="english">
              <td>
                <strong>Financial responsibility and decision-making</strong>
                <br>Take responsibility for personal financial decisions.</td>
              <td>Students are part of a microeconomy in which all of their decisions, such as whether or not to
                buy insurance, invest their money, or even pay their bills, affect their life in the economy.
              </td>
            </tr>
            <tr class="english">
              <td class="noline">Make financial decisions by systematically considering alternatives and consequences.</td>
              <td class="noline">Students use their bank logs and budget logs to analyze their income and expenses and to set
                financial goals. They experience a loss of purchasing power on Inflation Day and must decide
                how their budgets are affected. They also owe taxes on their income throughout the year and
                must file a tax return on Tax Day.</td>
            </tr>

            <tr class="english">
              <td>
                <strong>Income and careers</strong>
                <br>Explore career options.</td>
              <td>Students have the opportunity to start a business within the classroom economy. Before doing
                so, they must submit an application and business plan for approval.</td>
            </tr>
            <tr class="english">
              <td class="noline">Identify sources of personal income.</td>
              <td class="noline">Students can earn income from their jobs, from bonuses, from investments, and from a business
                enterprise if they start one.</td>
            </tr>
            <tr class="english">
              <td class="noline">Describe factors affecting take-home pay.</td>
              <td class="noline">Students can have taxes withheld from their pay throughout the year. They experience a loss of
                purchasing power on Inflation Day.</td>
            </tr>

            <tr class="english">
              <td>
                <strong>Planning and money management</strong>
                <br>Develop a plan for spending and saving.</td>
              <td>Students use bank logs and budget logs to track their income and expenses and to meet their financial
                goals.
                <br>
                <br>Through probing questions, the teacher illustrates how financial decisions can contribute
                to wealth-building throughout the year. Two examples: Students discover the benefits of purchasing
                instead of renting their desks and of contributing to a long-term investment portfolio.
              </td>
            </tr>
            <tr class="english">
              <td class="noline">Develop a system for keeping and using financial records.</td>
              <td class="noline">Students must keep track of their bank logs and other important documents throughout the year.
                <br>
                <br>The importance of maintaining good records is underscored by regular auditing.</td>
            </tr>
            <tr class="english">
              <td class="noline">Apply consumer skills to purchase decisions.</td>
              <td class="noline">Students learn that if they save up to buy their desks, they will escape rent and have more money
                in the long term. Class auctions offer the possibility of overpaying for items or not having
                enough money to purchase items that are needed or desired. Students experience a loss of
                purchasing power on Inflation Day.</td>
            </tr>
            <tr class="english">
              <td class="noline">Consider charitable giving.</td>
              <td class="noline">Students are encouraged to demonstrate charitable giving (both in time and classroom money) in
                order to receive tax credits.</td>
            </tr>


            <tr class="english">
              <td>
                <strong>Credit and debit</strong>
                <br>Identify the costs and benefits of various types of credit.</td>
              <td>Students can learn about this important topic in the credit card assignment. They specifically
                research the cost of borrowing and other potential pitfalls.</td>
            </tr>
            <tr class="english">
              <td class="noline">Explain the purpose of a credit record and identify borrowers' credit report rights.</td>
              <td class="noline">Students can learn about the importance of a good credit record in the credit card assignment.
                They research credit scores and the importance of having a good record in order to obtain
                a card.</td>
            </tr>
            <tr class="english">
              <td class="noline">Describe ways to avoid or correct credit problems.</td>
              <td class="noline">Students can research the nature of credit records in the credit card assignment.</td>
            </tr>

            <tr class="english">
              <td>
                <strong>Risk management and insurance</strong>
                <br>Identify common types of risks and basic risk management methods.</td>
              <td>Students experience property risk and the benefit of purchasing insurance through the insurance
                simulator.
              </td>
            </tr>
            <tr class="english">
              <td class="noline">Explain the purpose and importance of property and liability insurance protection.</td>
              <td class="noline">Students experience property damage and the potential financial consequences through the insurance
                simulator.
              </td>
            </tr>

            <tr class="english">
              <td>
                <strong>Saving and investing</strong>
                <br>Discuss how saving contributes to financial well-being.</td>
              <td>Students learn that if they save, they will be able to buy their desks and have more money. They
                also can use their savings to acquire more wealth through the investment simulator.</td>
            </tr>
            <tr class="english">
              <td class="noline">Explain how investing builds wealth and helps meet financial goals.</td>
              <td class="noline">Students can amass wealth through the investment simulator and can see how their earnings are
                affected by the factors listed. They are encouraged to build on their experience with the
                simulator by using teacher-provided websites to answer probing questions.</td>
            </tr>
            <tr class="english">
              <td class="noline">Evaluate Investment alternatives.</td>
              <td class="noline">Students gain a basic understanding of risk and return by choosing an asset allocation and comparing
                results. They answer probing questions about diversification as they experience the results
                of their investment portfolio decisions.</td>
            </tr>
          </table>

          <!-- spanish curriculum standards -->
          <p class="spanish hidden">My Classroon Economy está diseñado para ayudar a los estudiantes a aprender sobre los conceptos financieros
            desde una edad temprana. Esta página tiene la intención de ayudarle a conectar los conceptos financieros
            con los estándares de la economía de la clase.</p>

          <p class="spanish hidden">Nuestro programa menciona muchos de los estándares incluidos en el Jump$tart Coalition for Personal Financial
            Literacy's
            <a href="http://www.jumpstart.org/national-standards.html" target="_blank">National Standards in K-12 Personal Finance Education</a>.
          </p>

          <table class="table">
            <th class="spanish hidden">
              <h3>Estándar </h3>
            </th>
            <th class="spanish hidden">
              <h3>My Classroom Economy</h3>
            </th>
            <tr class="spanish hidden">
              <td>
                <strong>Responsabilidad financiera y toma de decisiones</strong>
                <br>Asumir la responsabilidad de las decisiones financieras personales.</td>
              <td>Los estudiantes son parte de una microeconomía en la que todas sus decisiones, tales como si
                comprar o no un seguro, invertir su dinero, o incluso pagar sus facturas, afectan su vida
                en la economía.</td>
            </tr>
            <tr class="spanish hidden">
              <td class="noline">Tomar decisiones financieras considerando otras alternativas y las consecuencias.</td>
              <td class="noline">Los estudiantes usan sus registros bancarios y registros presupuestarios para analizar sus ingresos
                y gastos y establecer metas financieras. Experimentan una pérdida de poder adquisitivo en
                el día de la inflación y deben decidir cómo se ven afectados sus presupuestos. También deben
                impuestos sobre sus ingresos durante todo el año y deben presentar una declaración de impuestos
                en el día del impuesto.</td>
            </tr>
            <tr class="spanish hidden">
              <td>
                <strong>Ingresos y profesiones</strong>
                <br>Explorar las opciones de diferentes carreras.</td>
              <td>Los estudiantes tienen la oportunidad de iniciar un negocio dentro de la economía de la clase.
                Antes de hacerlo, deben presentar una aplicación y un plan de negocios para su aprobación.</td>
            </tr>
            <tr class="spanish hidden">
              <td class="noline">Identificar las fuentes de ingresos personales.</td>
              <td class="noline">Los estudiantes pueden ganar ingresos de sus empleos, de bonos, de inversiones, y de una empresa
                comercial si comienzan una.</td>
            </tr>
            <tr class="spanish hidden">
              <td class="noline">Describir los factores que afectan el salario neto.</td>
              <td class="noline">Los estudiantes pueden tener los impuestos retenidos de su pago a traves del año. Ellos experimentan
                una pérdida de poder adquisitivo en el día de la inflación.</td>
            </tr>
            <tr class="spanish hidden">
              <td>
                <strong>Planificación y administración de dinero</strong>
                <br>Desarrollar un plan para gastar y ahorrar.</td>
              <td>Los estudiantes usan registros bancarios y registros presupuestarios para rastrear sus ingresos
                y gastos y para cumplir con sus metas financieras.
                <br>
                <br>A través de varias preguntas, el profesor ilustra cómo las decisiones financieras pueden
                contribuir a la creación de riqueza durante todo el año. Dos ejemplos: los estudiantes descubren
                los beneficios de comprar en lugar de alquilar sus escritorios y de contribuir a una cartera
                de inversión a largo plazo.</td>
            </tr>
            <tr class="spanish hidden">
              <td class="noline">Desarrollar un sistema para mantener y usar registros financieros.</td>
              <td class="noline">Los estudiantes deben mantener buen record de sus registros bancarios y de otros documentos importantes
                a lo largo de todo el año.
                <br>
                <br>La importancia de mantener buenos registros se aplica por medio de auditorías periódicas.</td>
            </tr>
            <tr class="spanish hidden">
              <td class="noline">Aplicar las habilidades del consumidor a las decisiones de compra.</td>
              <td class="noline">Los estudiantes aprenden que si ahorran para la compra sus escritorios, evitarán el alquiler
                y tendrán más dinero a largo plazo. Las subastas de la clase ofrecen la posibilidad de pagar
                demasiado por artículos o no tener bastante dinero para comprar artículos que son necesarios
                o deseados. Los estudiantes experimentan una pérdida del poder adquisitivo durante el Día
                de Inflación.</td>
            </tr>
            <tr class="spanish hidden">
              <td class="noline">Considere donar a una caridad.</td>
              <td class="noline">Se anima que los estudiantes demuestren las donaciones caritativas (tanto con su tiempo como
                con en el dinero de la economía de la clase) para recibir créditos de impuestos.</td>
            </tr>


            <tr class="spanish hidden">
              <td>
                <strong>Crédito y débito</strong>
                <br>Identificar los costos y beneficios de varios tipos de crédito.</td>
              <td>Los estudiantes pueden aprender sobre este tema tan importante en asignación de tarjetas de crédito.
                Ellos específicamente investigan el costo de realizar préstamos y otros peligros potenciales.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td class="noline">Explicar el objetivo de un registro de créditos e identificar los derechos del informe de crédito
                de los prestatarios.</td>
              <td class="noline">Identifique los derechos de reporte de crédito de los prestatarios. Los estudiantes pueden aprender
                sobre la Importancia de tener un buen historial crediticio en la asignación de tarjetas de
                crédito. Ellos Investigan acerca del crédito y la importancia de tener buen crédito para
                poder obtener una tarjeta de crédito.</td>
            </tr>
            <tr class="spanish hidden">
              <td class="noline">Describir maneras de evitar o corregir problemas de crédito.</td>
              <td class="noline">Los estudiantes pueden investigar la naturaleza de los registros de crédito en la asignación
                de tarjeta de crédito.</td>
            </tr>


            <tr class="spanish hidden">
              <td>
                <strong>Administración de riesgo y seguros</strong>
                <br>Identifique riesgos comunes y métodos básicos para la administración de riesgo.</td>
              <td>Los estudiantes tienen experiencia con el riesgo de propiedad y el beneficio de comprar seguro
                a través del simulador de seguros.</td>
            </tr>
            <tr class="spanish hidden">
              <td class="noline">Explicar el propósito y la importancia de la propiedad y la responsabilidad de la protección
                de un seguro.</td>
              <td class="noline">Los estudiantes tienen experiencia con daños a la propiedad y las posibles consecuencias financieras
                a través del simulador de seguros.</td>
            </tr>
            <tr class="spanish hidden">
              <td>
                <strong>Como ahorrar e invertir</strong>
                <br>Discuta como el ahorro contribuye al bienestar financiero.</td>
              <td>Los estudiantes aprenden que si ahorran, podrán comprar sus escritorios y tener más dinero. También
                pueden utilizar sus ahorros para adquirir más riqueza a través del simulador de inversiones.</td>
            </tr>
            <tr class="spanish hidden">
              <td class="noline">Explicar cómo la invertir genera riqueza y ayuda a cumplir los objetivos financieros.</td>
              <td class="noline">Los estudiantes pueden acumular riqueza a través del simulador de inversiones y pueden ver cómo
                sus ganancias se ven afectadas por los factores enumerados. Se les alienta a aprovechar su
                experiencia con el simulador utilizando sitios web proporcionados por el profesor para responder
                preguntas.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td class="noline">Evaluar inversiones alternativas.</td>
              <td class="noline">Los estudiantes obtienen una comprensión básica del riesgo y regresan eligiendo una asignación
                de activos y comparando los resultados. Responden preguntas sobre la diversificación a medida
                que experimentan los resultados de sus decisiones de la cartera de inversiones.</td>
            </tr>
          </table>
        </div>
      </div>


      <!-- PROGRAM OUTLINE -->
      <div id="proOut" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Program Outline</h3>
        <div class="infoBlock english">
          <b>Planning and preparation</b>
          <p>Begin planning your classroom economy before the school year starts.</p>
          <ul>
            <li>Choose the jobs and advanced topics you will make available to your students, and customize the syllabus
              to your classroom.</li>
            <li>Prepare the materials you will need for the classroom economy activities. You can download and print
              the materials or order a free kit.</li>
            <li>Start gathering rewards for students to purchase at auctions.</li>
          </ul>
          <p>Allow 2 to 3 hours to choose jobs, bonuses, and fines, and an additional 2 to 3 hours to prepare all
            of the materials.</p>

          <b>Introductory activities</b>
          <p> On or near the first day of school, briefly introduce your students to the classroom economy program
            and provide them with your customized syllabus. Instruct the students to read the syllabus as homework,
            and follow up with a question-and-answer session within the next few days.</p>

          <p>Collect job applications from students interested in holding a classroom job (in addition to their job
            of being an exemplary student). Approximately 1 week later, assign jobs to students and provide short
            training sessions.</p>

          <p>A few weeks later, review the advanced topics section of the syllabus and remind the students to be prepared
            to buy insurance and/or open an investment account on the next Bill Day, if they choose to do so.</p>

          <p>Allot a portion of 3 or 4 class periods to complete these activities. Keep in mind, this time does not
            all need to be taken from your curriculum schedule, because it can replace some of the time you would
            otherwise spend explaining your class rules and establishing procedures.</p>

          <b>Ongoing activities</b>
          <p>Every month students earn paychecks and bonus money. They also pay fines, rent for their desks, and electricity
            bills; purchase prizes at auctions; and have the opportunity to invest expendable income, purchase
            insurance policies, and make tax withholding payments—all with classroom economy currency. Although
            students may need more time and guidance at the beginning of the school year, they typically will
            become more efficient at banking as the year progresses. To avoid interfering with the curriculum,
            we encourage you to have students perform classroom economy tasks during transition periods, such
            as at the beginning and end of the class period. You might also allow students to work on classroom
            economy activities if they finish curriculum materials early.</p>

          <b>Tax Day</b>
          <p>Once a year, around April 15—the tax filing deadline for their parents—the students file an income tax
            return based on their earnings and any taxes they've paid throughout the year. Students may need
            to pay additional taxes at this time, or they may receive a refund check. Encourage students to earn
            tax credits throughout the year through charitable giving.</p>


          <b>Year-end wrap-up</b>
          <p>Bring the classroom economy program to an end by encouraging a group discussion and giving students a
            chance to reflect. Distribute certificates to highlight student successes, and provide opportunities
            for classroom celebrations.</p>

          <p>The year-end wrap-up is also a good time to reflect on the results of the investment simulator. By analyzing
            and comparing their results, the students can learn valuable lessons about investing.</p>

          <p>Allow approximately 1 class period for the year-end wrap-up.</p>
        </div>
        <!-- Spanish PROGRAM OUTLINE -->
        <h3 class="nineten spanish hidden">Resumen del Programa</h3>
        <div class="infoBlock spanish hidden">
          <b>Planificacion y Preparacion</b>
          <p>Comience a planificar la economía de su clase antes que comience el año escolar.</p>
          <ul>
            <li>Escoja los trabajos y temas avanzados que pondrá a disposición de sus estudiantes, y personalice
              el programa de estudios en su clase.</li>
            <li>Prepare los materiales que necesitará para las actividades de la economía de la clase. Puede descargar
              e imprimir los materiales o solicitar un kit gratuito.</li>
            <li>Comience a reunir recompensas que los estudiantes puedan comprar en las subastas.</li>
          </ul>
          <p>Permita 2 a 3 horas para elegir trabajos, bonos y multas, y 2 a 3 horas adicionales para preparar todos
            los materiales.</p>

          <b>Actividades Iniciales</b>
          <p>En el primer día de la escuela, brevemente presénteles a sus estudiantes el programa de My Classroon
            Economy y provéales el programa de estudio personalizado. Instruya a los estudiantes a leer el programa
            de estudios como la tarea y después usted puede continuar con una sesión de preguntas y respuestas
            dentro de los próximos días.</p>

          <p>Colecte solicitudes de empleo de los estudiantes interesados en la posicion de un trabajo de la clase
            (además de su trabajo principal de ser un Estudiante Ejemplar). Aproximadamente 1 semana más tarde,
            asigne los empleos a estudiantes y proporcione sesiones cortas de entrenamiento.</p>

          <p>Una semanas más tarde, revise la sección de temas avanzados del programa y recuérdele a los estudiantes
            que estén preparados para comprar los seguros y/o abrir una cuenta de inversiones el próximo día
            de factura, si desean hacerlo.</p>

          <p>Permita 3 a 4 periodos de clase para completar estas actividades. Tenga en cuenta que esta vez no necesita
            agregar más tiempo a su currículum porque puede reemplazar el tiempo que hubiera usado para explicar
            las reglas de su clase y los procedimientos de la clase.</p>

          <b>Actividades en Curso</b>
          <p>Cada mes, los estudiantes ganan salarios y bonos. También pagan multas, facturas de electricidad, y el
            alquiler de sus escritorios, y compran premios en la subastas con el dinero de My Classroon Economy.
            Cada mes, los estudiantes ganan salarios y bonos. También pagan multas, facturas de electricidad,
            y el alquiler de sus escritorios, y compran premios en la subastas con el dinero de My Classroon
            Economy. También tienen la oportunidad de invertir los ingresos excedentes y pueden comprar pólizas
            de seguros. Aunque los estudiantes pueden necesitar más tiempo y orientación al principio del año
            escolar, ellos suelen ser más eficientes en las transacciones bancarias a medida que avanza el año.
            Para evitar interferir con el currículo, animamos a los estudiantes a hacer sus operaciones bancarias
            durante los períodos de transición, como al comienzo y al final del periodo de la clase. También
            puede permitir que los estudiantes trabajen en las actividades de la economía de la clase si terminan
            los materiales del currículo temprano.
          </p>
          <b>Dia de Impuestos</b>
          <p>Una vez al año, alrededor del 15 de abril, esta es la fecha límite para la presentación de impuestos
            para sus padres, los estudiantes pagaran un impuesto sobre la renta basada en los impuestos que han
            pagado durante el año. Los estudiantes pueden necesitar pagar impuestos adicionales en este momento,
            o pueden recibir un cheque de reembolso. Anime a los estudiantes a ganar créditos fiscales durante
            todo el año a través de donaciones caritativas.</p>


          <b>Actividades del Fin de Año</b>
          <p>Termine el programa de My Classroom Economy con una discusión y una oportunidad para reflexionar. Distribuya
            los certificados para resaltar los éxitos de los estudiantes.</p>

          <p>El cierre de fin de año también es un buen momento para reflexionar sobre los resultados del simulador
            de inversiones. Al analizar y comparar sus resultados, los estudiantes pueden aprender valiosas lecciones
            sobre cómo invertir.</p>

          <p>Permita aproximadamente 1 periodo para las actividades del fin de año.</p>
        </div>
      </div>

      <!-- ECONOMIC SYSTEM -->
      <div id="ecoSystem" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Establishing Your Economic System</h3>
        <h3 class="nineten spanish hidden">Estableciendo su Sistema Económico</h3>
        <div class="infoBlock">
          <div class="english">
            <p>To start the classroom economy program, you'll need to select:</p>
            <ul>
              <li>A set of student jobs.</li>
              <li>A level of monthly rent.</li>
              <li>A list of bonus activities.</li>
              <li>A list of fines for violating class rules.</li>
              <li>The advanced topics you wish to include, such as investing, insurance, and budgeting.</li>
            </ul>
            <p>Your selections for bonuses and fines can be drawn from the lists we've provided or based on your
              own classroom rules. As an option, you may choose to involve your students in creating the bonus
              and fine lists.</p>

            <p>Once the lists are ready, use them to create a
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Syllabus_11-12.pdf')">customized syllabus</a> to distribute during the first few days of the program.</p>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>Para comenzar el programa de la economía de la clase, deberá seleccionar:</p>
            <ul>
              <li>Un conjunto de trabajos estudiantiles.</li>
              <li>Un nivel de alquiler mensual.</li>
              <li>Una lista de oportunidades para obtener bonos.</li>
              <li>Una lista de multas por romper las reglas de la clase.</li>
              <li>Los temas avanzados que desee incluir, como invertir y los seguros.</li>
            </ul>
            <p>Sus elecciones para los bonos y las multas pueden ser extraídas de las listas que hemos proporcionado
              o basadas en sus propias reglas de clase. Como opción, usted puede optar por involucrar a sus
              estudiantes en la creación de las listas de los bonos y las multas.</p>

            <p>Una vez que las listas estén listas, utilícelos para crear un
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Syllabus_11-12_ES.pdf')">programa de estudios </a>personalizado para distribuirlo durante los primeros días del programa.</p>
          </div>

          <!-- Nav tabs -->
          <ul class="nav nav-tabs nineten english" role="tablist">
            <li role="presentation" class="active">
              <a href="#jobs" aria-controls="jobs" role="tab" data-toggle="tab">Jobs</a>
            </li>
            <li role="presentation">
              <a href="#bills" aria-controls="bills" role="tab" data-toggle="tab">Bills</a>
            </li>
            <li role="presentation">
              <a href="#bonuses" aria-controls="bonuses" role="tab" data-toggle="tab">Bonuses</a>
            </li>
            <li role="presentation">
              <a href="#fines" aria-controls="fines" role="tab" data-toggle="tab">Fines</a>
            </li>
            <li role="presentation">
              <a href="#loans" aria-controls="loans" role="tab" data-toggle="tab">Loans</a>
            </li>
            <li role="presentation">
              <a href="#taxes" aria-controls="taxes" role="tab" data-toggle="tab">Taxes</a>
            </li>
          </ul>
          <!-- Nav tabs -->
          <ul class="nav nav-tabs nineten spanish hidden" role="tablist">
            <li role="presentation" class="active">
              <a href="#jobs" aria-controls="jobs" role="tab" data-toggle="tab">Trabajos </a>
            </li>
            <li role="presentation">
              <a href="#bills" aria-controls="bills" role="tab" data-toggle="tab">Facturas</a>
            </li>
            <li role="presentation">
              <a href="#bonuses" aria-controls="bonuses" role="tab" data-toggle="tab">Bonos</a>
            </li>
            <li role="presentation">
              <a href="#fines" aria-controls="fines" role="tab" data-toggle="tab">Multas</a>
            </li>
            <li role="presentation">
              <a href="#loans" aria-controls="loans" role="tab" data-toggle="tab">Préstamos</a>
            </li>
            <li role="presentation">
              <a href="#taxes" aria-controls="taxes" role="tab" data-toggle="tab">Impuestos</a>
            </li>
          </ul>

          <!-- Tab panes -->
          <div class="tab-content">
            <div role="tabpanel" class="tab-pane active" id="jobs">
              <div class="english">
                <p>Everyone in the class has the job of being an Exemplary Student. But to earn enough classroom
                  economy money to meet all expenses and participate in the auction, students will need
                  to earn bonuses or hold additional classroom jobs.</p>

                <p>Certain jobs are crucial to the program, because otherwise too much work would fall on your
                  shoulders. Other jobs are completely optional and are up to you and the students.</p>

                <p>The four core jobs we recommend you include are: Auditor, Bonus/Fine Administrator, Auctioneer,
                  and Electrician.</p>

                <p>If you are including insurance, investments, and loans in your classroom economy, we recommend
                  that you also hire at least one Investment Banker, Insurance Agent, and Loan Officer.</p>

                <p>Each job needs an appropriate salary. The highest pay should go to the position with the
                  most responsibility. In general, the more jobs you offer and the more students who have
                  jobs, the better it is for the classroom economy.</p>

                <p>This list shows suggested duties and monthly pay for some of the more common jobs.</p>
              </div>
              <div class="spanish hidden">
                <p>Todos en la clase tienen el trabajo de ser un "estudiante ejemplar". Pero para ganar suficiente
                  dinero en la economía de la clase para cubrir todos los gastos y participar en la subasta,
                  los estudiantes tendrán que ganar bonos o tener empleos adicionales en la clase.</p>

                <p>Algunos trabajos son cruciales para el programa, porque de otra manera demasiado trabajo
                  caería sobre sus hombros. Otros trabajos son completamente opcionales y depende de usted
                  y de los estudiantes.</p>

                <p>Los cuatro empleos principales que le recomendamos que incluyan son: Auditor, Administrador
                  de Bonos y Multas, Subastador y Electricista.</p>

                <p>Si usted está incluyendo seguros, inversiones y préstamos en su economía de la clase, le
                  recomendamos que también contrate al menos un banquero de inversiones, un agente de seguros
                  y un oficial de préstamos.</p>

                <p>Cada trabajo necesita un salario adecuado. El pago más alto debe ir a la posición con más
                  responsabilidad. En general, entre más trabajos ofrezca y entre más estudiantes tengan
                  trabajo sera mejor para la economía de la clase.</p>

                <p>Esta lista muestra los deberes sugeridos y el pago mensual de algunos de los trabajos más
                  comunes.
                </p>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <tbody class="english">
                  <tr>
                    <th>Jobs</th>
                    <th>Job description</th>
                    <th>Monthly salary</th>
                  </tr>
                  <tr>
                    <td>
                      <strong>Exemplary Student</strong>
                      <br>
                      <h5>Everyone in the class</h5>
                    </td>
                    <td>Arrive in class on time with all needed materials. Complete all classroom and
                      homework assignments as instructed and deliver the work when it is due.
                      <br>(Note: Students who don't meet these requirements are still paid their salaries
                      but are subject to classroom economy fines.)</td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Attendance Monitor</strong>
                      <br>
                      <h5>1 per class</h5>
                    </td>
                    <td>Lead the line as students walk through the hallway.</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Line Caboose</strong>
                      <br>
                      <h5>1 per class</h5>
                    </td>
                    <td>Take daily attendance and record absences. Report absences to the teacher. Provide
                      students with makeup materials when they return to class. A student must
                      have a strong attendance record to be considered for this job.</td>
                    <td>
                      <strong>$700</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Auctioneer</strong>
                      <br>
                      <h5>3&ndash;5 per class</h5>
                    </td>
                    <td>Poll the class to determine appropriate auction items. Identify ways to obtain
                      the items. Plan and facilitate fund-raising events to sponsor the auction.
                      Manage the auction.</td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Auditor</strong>
                      <br>
                      <h5>1 for every 5 students</h5>
                    </td>
                    <td>Analyze the bank logs of 4 to 6 students to ensure accuracy and compare with
                      classroom investment and insurance logs. Verify transactions by witnessing
                      them and initialing students' bank logs.
                      <br>
                      <i>Requires a recommendation.</i>
                    </td>
                    <td>
                      <strong>$800</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Loan Officer</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Allow students to acquire loans for a set amount. Hand out loan slips and calculate
                      interest. Keep a record of all loans. Work with the Banker to deposit money
                      from the students' accounts.
                    </td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Bonus/Fine Administrator</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Record student bonuses and fines on the bonus/fine log and keep bonus/fine slips
                      in order for ease of auditing. Mark slips as "processed" and store them in
                      the class folder after they are recorded.
                      <br>
                      <i>Requires a recommendation.</i>
                    </td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Clerk</strong>
                      <br>
                      <h5>2&ndash;3 per class</h5>
                    </td>
                    <td>Hand out papers and other materials to students. Collect homework and other items
                      from students when asked. Organize the class supply shelves and keep them
                      neat.
                    </td>
                    <td>
                      <strong>$700</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Economist</strong>
                      <br>
                      <h5>1 per class</h5>
                    </td>
                    <td>Monitor spending patterns during the class auction. Report to the teacher and
                      other students about the buying trends observed and how they compare with
                      previous auctions.</td>
                    <td>
                      <strong>$650</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Electrician</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Monitor electricity usage in the classroom. Determine the daily charge and report
                      the monthly total. Present creative ideas on how to conserve energy and reduce
                      the monthly bill.</td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Insurance Agent</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Sell insurance policies to students at their request. Keep a detailed log of
                      who purchased insurance each month, and record the damages that uninsured
                      students must pay. Be able to provide an up-to-date log to the Auditors when
                      required.
                    </td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Investment Banker</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Collect investments from students. Keep a detailed log of all investments, and
                      inform students when their investments mature. Be able to provide the log
                      to the Auditors when required.</td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Messenger</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Deliver written or spoken messages to people throughout the building. Answer
                      the class phone.</td>
                    <td>
                      <strong>$650</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Recyclist</strong>
                      <br>
                      <h5>2&ndash;3 per class</h5>
                    </td>
                    <td>Monitor the recycling bins and trash cans for appropriate deposits. Increase
                      the school's awareness of the importance of recycling by creating signs and
                      flyers.
                    </td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Tutor</strong>
                      <br>
                      <h5>As needed</h5>
                    </td>
                    <td>Provide one-on-one or small-group tutoring in subjects identified by the teacher.
                      Applicants must be high performers in the subject for which they want to
                      tutor.
                    </td>
                    <td>
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Visual Display Artist</strong>
                      <br>
                      <h5>2&ndash;3 per class</h5>
                    </td>
                    <td>Determine what type of information is presented on the class bulletin boards.
                      Ensure that the bulletin boards are updated with relevant information. Decorate
                      the boards for class events, holidays, and other special occasions.</td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Webmaster</strong>
                      <br>
                      <h5>1 per class</h5>
                    </td>
                    <td>Maintain and update the class' or teacher's webpage. Respond to relevant inquiries.
                      Post new information as directed by the teacher.</td>
                    <td>
                      <strong>$700</strong>
                    </td>
                  </tr>
                  </tbody>
                  <tbody class="spanish hidden">
                  <tr>
                    <th>Trabajo</th>
                    <th>Descripción del Trabajo</th>
                    <th>Salario Mensual</th>
                  </tr>
                  <tr>
                    <td>
                      <strong>Estudiante Ejemplar</strong>
                      <br>
                      <h5>Todos en la clase</h5>
                    </td>
                    <td>Llega a la clase a tiempo con todos los materiales necesarios. Completa todas
                      las tareas como sea instruido y entrega los trabajos cuando sea debido.
                      <br>(Nota: Los estudiantes que no cumplan estos requisitos se les siguen pagando
                      sus sueldos, pero están sujetos a las multas de la economía de la clase)</td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Línea Caboose</strong>
                      <br>
                      <h5>1 por clase</h5>
                    </td>
                    <td>Dirige la línea mientras los estudiantes caminan por el pasillo.</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Monitor de ausencias</strong>
                      <br>
                      <h5>1 por clase</h5>
                    </td>
                    <td>TakTiene que mantener lista diaria y registrar las ausencias. Le tocaría informar
                      las ausencias al maestro. También le tocaría darles a los estudiantes los
                      materiales para ponerse al día con las clases que perdieron. El estudiante
                      debe tener un buen record para ser considerado para este trabajo.</td>
                    <td>
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Subastador</strong>
                      <br>
                      <h5>3&ndash;5 por clase</h5>
                    </td>
                    <td>Averigüe con la clase que artículos les gustarían tener en la subasta. Identifique
                      alguna manera de obtener esos artículos. Planee y facilite eventos para recoger
                      fondos para patrocinar la subasta. Encárguese de la subasta.</td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Auditor</strong>
                      <br>
                      <h5>1 por cada 5 estudiantes</h5>
                    </td>
                    <td>Analice los registros bancarios de 4 a 6 estudiantes para garantizar su exactitud
                      y comparar con las inversiones de la clase y registros de seguros. Verifique
                      las transacciones siendo testigo de ellos y firme los registros bancarios
                      de los estudiantes.
                      <br>
                      <i>Requiere una recomendación.</i>
                    </td>
                    <td>
                      <strong>$800</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Oficial de Prestamos</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Permite que los estudiantes adquieran préstamos por un monto fijo. Entregue los
                      comprobantes de préstamo y calcule el interés. Mantenga un registro de todos
                      los préstamos. Trabaje con el banquero para depositar el dinero de las cuentas
                      bancarias de los estudiantes.
                    </td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Administrador de Bonos y Multas</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Registre los bonos y las multas de los estudiantes en el registro oficial de
                      bonos y multas. También guarde los tiquetes/recibos de las multas y los bonos
                      para facilitar la auditoria. Marque los tiquetes y los recibos como “procesados”
                      y guárdelos en la carpeta de la clase después de que se hayan registrado.
                      <br>
                      <i>Requiere una recomendación.</i>
                    </td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Asistente administrativo</strong>
                      <br>
                      <h5>2&ndash;3 por clase</h5>
                    </td>
                    <td>Entrega papeles y materiales a los estudiantes. Recoge los papeles o las tareas
                      de los estudiantes cuando se le pide. También tienen que organizar los materiales
                      de la clase y mantenerlos limpios.
                    </td>
                    <td>
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Economista</strong>
                      <br>
                      <h5>1 por clase</h5>
                    </td>
                    <td>Monitorea los hábitos y tendencias del gasto durante la subasta de clases. Informa
                      al profesor y a otros estudiantes sobre las tendencias de compra para la
                      subasta y cómo se comparan con las subastas anteriores.</td>
                    <td>
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Electricista</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Supervisa el uso de electricidad en la clase. Determina el uso diario e informa
                      el total mensual. Creativamente presenta ideas sobre cómo ahorrar energía
                      y reducir la factura mensual.</td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Agente de Seguros</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Venden pólizas de seguro a los estudiantes a petición de ellos. Mantiene un registro
                      detallado de quién compró el seguro cada mes, y anota los daños que los estudiantes
                      no asegurados deben pagar. Tiene que ser capaz de proporcionar un registro
                      actualizado a los auditores cuando sea necesario.
                    </td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Banquero de inversiones</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Reclama las inversiones de los estudiantes. Mantiene un registro detallado de
                      todas las inversiones, e informa a los estudiantes cuando sus inversiones
                      hayan madurado. Tiene que ser capaz de proporcionar el registro para los
                      auditores cuando sea necesario.</td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Mensajero</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Entrega los mensajes escritos o hablados a las personas en todo el edificio.
                      Contesta el teléfono de la clase.</td>
                    <td>
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Reciclador</strong>
                      <br>
                      <h5>2&ndash;3 por clase</h5>
                    </td>
                    <td>Supervisa los contenedores de reciclaje y basura para asegurarse que los depósitos
                      estén en los contenedores apropiados. Le le muestra al colegio la importancia
                      de reciclar creando folletos.
                    </td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Tutor</strong>
                      <br>
                      <h5>Según sea necesario</h5>
                    </td>
                    <td>Proporciona tutorías uno a uno o en grupos pequeños en temas identificados por
                      el maestro. Los tutores deben entender y poder explicar el tema para que
                      sean elegidos.
                    </td>
                    <td>
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Artista de presentaciones visuales</strong>
                      <br>
                      <h5>2&ndash;3 por clase</h5>
                    </td>
                    <td>Determina qué tipo de información se presenta en los tableros de anuncios de
                      la clase. Se asegura de que los tableros de anuncios de clase se actualicen
                      con información pertinente. Adorna los tableros para los acontecimientos
                      de la clase, los días de fiesta, y otras ocasiones especiales.</td>
                    <td>
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Administrador del sitio de web</strong>
                      <br>
                      <h5>1 por clase</h5>
                    </td>
                    <td>Mantiene y actualiza la página Web de la clase/profesor. Responde las preguntas
                      importantes.
                    </td>
                    <td>
                      <strong>$700</strong>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="tip english">
                <img src="assets/images/lightbulbIcon.svg">
                <strong>TIPS:</strong>
              </div>
              <div class="tip spanish hidden">
                <img src="assets/images/lightbulbIcon.svg">
                <strong>Sugerencias:</strong>
              </div>
              <ul class="english">
                <li>Consider creating jobs to match other tasks you normally assign to students.</li>
                <li>Changing jobs quarterly or in the middle of the year is an option, but it could require a
                  lot of retraining. If you decide to have the students change jobs, consider asking them
                  to train each other.</li>
                <li>If some of your jobs require a recommendation, consider giving advance notice to your students'
                  previous teachers. Let them know that they can recommend a student by contacting you
                  in person or by e-mail, phone, or letter. The primary purpose of the recommendation is
                  to help you learn which students are capable of performing jobs with increased responsibilities.
                  We believe that a teacher from a prior year is the best person to provide that assessment.</li>
              </ul>
              <ul class="spanish hidden">
                <li>Considere la posibilidad de crear trabajos para que coincidan con las tareas que normalmente
                  asigna a los estudiantes.</li>
                <li>Cambiar los empleos cada trimestre o en la mitad del año es una opción, pero podría requerir
                  una gran cantidad de entrenamiento. Si decide que los alumnos cambien de trabajo, considere
                  pedirles que se entrenen ellos mismos.</li>
                <li>Si algunos de sus trabajos requieren una recomendación, considere avisar con anticipación
                  a los maestros anteriores de sus estudiantes. Hágales saber que pueden recomendar a un
                  estudiante poniéndose en contacto con usted en persona o por correo electrónico, teléfono
                  o carta. El propósito principal de la recomendación es ayudarle a saber cuáles estudiantes
                  son capaces de realizar los trabajos con mayores responsabilidades. Creemos que el maestro
                  del año anterior es la mejor persona para proporcionar esa evaluación.
                </li>
              </ul>
            </div>

            <div role="tabpanel" class="tab-pane" id="bills">
              <div class="english">
                <p>The obligation to pay bills is central to the classroom economy. Here are the key concepts:</p>

                <ul>
                  <li>Students' base salaries should not quite cover these monthly bills (rent and electricity).
                    To make up the difference, the students need to earn bonus money or hold an additional
                    job.
                  </li>
                  <li>Students who do not make their payments should face some loss of privilege within the
                    system.
                  </li>
                  <li>Those who make their payments and manage to save additional money should reap benefits.</li>
                </ul>
              </div>
              <div class="spanish hidden">
                <p>La obligación de pagar las facturas es fundamental para la economía de la clase. Aquí están
                  los conceptos claves:</p>

                <ul>
                  <li>El salario de un estudiante no debería cubrir las facturas mensuales. Para compensar
                    la diferencia, los estudiantes deben ganar bonos haciendo trabajos adicionales.
                  </li>
                  <li>Los estudiantes que no cumplen con los pagos de las facturas deberían enfrentar alguna
                    pérdida de privilegios dentro del sistema.
                  </li>
                  <li>Quienes realicen sus pagos y logren ahorrar dinero adicional deberían obtener beneficios.</li>
                </ul>
              </div>
              <table class="table">
                <tbody class="english">
                <tr>
                  <th>Recommended</th>
                  <th>Amount</th>
                </tr>
                <tr>
                  <td>Monthly rent</td>
                  <td>
                    <strong>$800</strong>
                  </td>
                </tr>
                <tr>
                  <td>One-time desk purchase price</td>
                  <td>
                    <strong>$2,400</strong>
                  </td>
                </tr>
                <tr>
                  <td>Monthly electricity bill (before energy savings discount)</td>
                  <td>
                    <strong>$150</strong>
                  </td>
                </tr>
                </tbody>
                <tbody class="spanish hidden">
                <tr>
                  <th>Recomendado</th>
                  <th>Cantidad</th>
                </tr>
                <tr>
                  <td>Alquiler mensual</td>
                  <td>
                    <strong>$800</strong>
                  </td>
                </tr>
                <tr>
                  <td>Precio de compra de un escritorio</td>
                  <td>
                    <strong>$2,400</strong>
                  </td>
                </tr>
                <tr>
                  <td>Factura mensual de electricidad (antes de descuentos por el ahorro de energía)</td>
                  <td>
                    <strong>$150</strong>
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="english">
                <p>
                  <strong>Electricity bills</strong>

                <p>The $150 monthly electricity bill can be reduced through environmentally friendly actions.
                </p>

                <p>To keep track of electricity usage, we encourage you to hire an Electrician as one of
                  your classroom jobs so that he or she can motivate the students to conserve energy
                  and reduce the bill. Allow the Electrician to present ideas about how to reduce usage
                  and thus reduce the cost by $1 for each initiative (for example, turning out unneeded
                  lights, reducing the number of bulbs in some fixtures, and turning off computers
                  when not in use). The Electrician keeps a
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Energy_Log_11-12.pdf')">classroom energy log</a> of the daily electricity usage—from $0 to a maximum of $5.</p>

                <p>By monitoring electricity usage in the classroom, you establish a framework for fruitful
                  discussions about conservation and the environment.</p>

                <p>
                  <strong>Failure to pay bills</strong>
                </p>

                <p>We recommend that students who miss a rent payment or an electricity bill be excluded
                  from that month's auction. You may choose to have them forfeit other privileges,
                  but please remember that the program is designed to help students learn financial
                  responsibility—it is not to punish them.</p>

                <p>When students miss payments, it's important to get them back on track as soon as possible.
                  You can take a missed payment as an opportunity for a discussion about making responsible
                  choices, and then encourage the student to earn bonuses so that he or she can catch
                  up on bills and get in on the Auction Day fun.</p>

                <p>
                  <strong> Successful saving</strong>
                </p>

                <p>Students who make their payments and manage to save additional money can reap rewards:
                <p>

                <ul>
                  <li>They can bid on Auction Day.</li>
                  <li>They can be recognized in front of their peers during the year-end wrap-up.</li>
                  <li>They have the chance to buy their desks outright and be forever free of rent.</li>
                  <li>They can invest their money to generate wealth over the course of the year.
                  </li>
                </ul>
                <p>To support your students in successful financial planning, encourage them to
                  keep a monthly budget. For more information, view
                  <a href="#budgetday">Budget Day</a>.
                </p>
              </div>
              <div class="spanish hidden">
                <p>
                  <strong>Facturas de electricidad</strong>

                <p>La factura de electricidad mensual de $150 se puede reducir a través de acciones amigables
                  con el medio ambiente.
                </p>

                <p>Para el proceso del uso diario, le recomendamos que contrate a un electricista como uno
                  de sus puestos de trabajo en la clase para que él o ella puedan motivar a los estudiantes
                  a conservar la energía y reducir la factura. Permita que el electricista presente
                  ideas sobre cómo reducir el consumo y por lo tanto reducir el costo de $ 1 por cada
                  iniciativa (por ejemplo, apagando las luces innecesarias, reduciendo el número de
                  bombillos en algunos accesorios, apagando los computadores cuando no estén en uso).
                  El electricista debe mantener un
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Energy_Log_11-12_ES.pdf')">registro del uso de energía</a> del ahorro diario de la electricidad entre $0 a $5.</p>

                <p>Poder mantener un registro del uso diario de la electricidad proporciona un marco para
                  las discusiones sobre la conservación de energía y el medio ambiente.</p>

                <p>
                  <strong>Falta de pago de facturas</strong>
                </p>

                <p>Recomendamos que los estudiantes que falten un pago de factura o una factura de electricidad
                  sean excluidos de la subasta de ese mes. Pueden elegir excluirlos de otros privilegios,
                  pero recuerde que el programa está diseñado para ayudar a los estudiantes a aprender
                  la responsabilidad financiera, no para castigarlos.</p>

                <p>Cuando los estudiantes falten los pagos de los préstamos, es importante ponerlos al día
                  lo más pronto posible. Puede tomar la falta de los pagos como oportunidades para
                  discutir acerca de diferentes opciones, y luego alentar a los estudiantes a ganar
                  bonos para ayudarlos ponerse al día con los pagos del préstamo y participar en la
                  diversión del Día de la Subasta.</p>

                <p>
                  <strong>Éxito en ahorrar</strong>
                </p>

                <p>Los estudiantes que hacen sus pagos y logran ahorrar dinero adicional pueden adquirir
                  recompensas:
                <p>
                <ul>
                  <li>Pueden ser licitadores el día de la subasta.</li>
                  <li>Se pueden reconocer frente a todos durante la conclusión del año.</li>
                  <li>Lo más tentador es que tienen la oportunidad de comprar sus escritorios por
                    completo y estar libres de tener que pagar alquiler.</li>
                  <li>Pueden invertir el dinero para generar riqueza en el transcurso del año.
                  </li>
                </ul>
                <p>Para apoyar a sus alumnos en una planificación financiera exitosa, anímelos a
                  mantener un presupuesto mensual. Para más información, vea
                  <a href="#budgetday">Día de Presupuestar</a>.
                </p>
              </div>
            </div>

            <div role="tabpanel" class="tab-pane" id="bonuses">
              <div class="english">
                <p>In addition to the salaries students earn from their jobs, they can earn bonus money by performing
                  well academically and participating in extracurricular activities. Students need to earn
                  bonuses to pay bills, save for the auction, and invest or purchase insurance, as detailed
                  in the syllabus. In addition, bonuses can be useful incentives for your own class goals.</p>

                <p>The following bonuses should be included if possible, but you are encouraged to add any other
                  activities you may have offered your students. The bonus amounts listed are simply suggestions.</p>
              </div>
              <div class="spanish hidden">
                <p>Además de los salarios que obtienen los estudiantes atreves de sus trabajos, ellos también
                  pueden ganar dinero de bonos obteniendo buenos resultados académicos y participando en
                  actividades extracurriculares. Los estudiantes deben ganar bonos para pagar facturas,
                  ahorrar para la subasta e invertir o comprar un seguro, como se detalla en el programa
                  de estudios. Además, los bonos pueden ser incentivos útiles para sus propios objetivos
                  de clase.</p>

                <p>Los siguientes bonos deben incluirse, si es posible. Los montos para los bonos son una sugerencia.
                </p>
              </div>

              <table class="table">
                <tbody class="spanish hidden">
                <tr>
                  <th>Actividad</th>
                  <th>Cantidad de Bono</th>
                </tr>
                <tr>
                  <td>Sacar 100% en un examen pequeño o prueba</td>
                  <td>
                    <strong>$50</strong>
                  </td>
                </tr>
                <tr>
                  <td>Sacar 100% en un examen grande</td>
                  <td>
                    <strong>$200</strong>
                  </td>
                </tr>
                <tr>
                  <td>Completar un presupuesto mensual</td>
                  <td>
                    <strong>$150</strong>
                  </td>
                </tr>
                <tr>
                  <td>Sacar 90% a 99% en cualquier tarea</td>
                  <td>
                    <strong>$100</strong>
                  </td>
                </tr>
                <tr>
                  <td>Completar una tarea opcional sobre conceptos financieros</td>
                  <td>
                    <strong>$100</strong>
                  </td>
                </tr>
                <tr>
                  <td>Recibir un reconocimiento de otro maestro</td>
                  <td>
                    <strong>$200</strong>
                  </td>
                </tr>
                <tr>
                  <td>Inscribirse a una actividad extracurricular</td>
                  <td>
                    <strong>$100</strong>
                  </td>
                </tr>

                </tbody>
              </table>
              <div class="english">
                <p>Bonuses are administered by using our
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Bonus-Fine_Tickets_11-12.pdf')">bonus/fine slips</a>.</p>

                <div class="tip">
                  <img src="assets/images/lightbulbIcon.svg">
                  <strong>TIPS:</strong>
                </div>
                <ul>
                  <li>You can offer bonuses for specific behavior you want to encourage, as well as for activities
                    important to your school. For example, you might offer bonuses for coming to school
                    early to get academic help, completing all homework assignments for the week, participating
                    in a math competition or science fair, playing a sport, or joining the school orchestra.
                    You can also offer bonuses to teams of students working together.</li>
                  <li>With bonus money, it's far better to give away too much rather than too little. The more
                    bonus opportunities you provide, the more often students will see their extra efforts
                    rewarded. That means they'll be able to participate more fully in the classroom economy,
                    and they'll enjoy it more.</li>
                </ul>
              </div>
              <div class="spanish hidden">
                <p>Los bonos están administrados al utilizar nuestros
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Bonus-Fine_Tickets_11-12_ES.pdf')">recibos/tiquetes para los bonos y las multas</a>.</p>

                <div class="tip">
                  <img src="assets/images/lightbulbIcon.svg">
                  <strong>Sugerencias:</strong>
                </div>
                <ul>
                  <li>Puede ofrecer bonos por el comportamiento específico que desea promover, como para actividades
                    importantes del colegio. Por ejemplo, puede ofrecer bonos por asistir temprano a
                    la escuela para obtener ayuda académica, completar todas las tareas de la semana,
                    participar en una competencia de matemáticas o de ciencias, jugar un deporte o unirse
                    a la orquesta de la escuela. También puede ofrecer bonos a los equipos de estudiantes
                    que trabajan juntos.</li>
                  <li>Con dinero de bonos, es mucho mejor regalar demasiado que muy poco. Entre más oportunidades
                    de para obtener bonos, los estudiantes verán que sus esfuerzos adicionales serán
                    recompensados. Eso significa que podrán participar más plenamente en la economía
                    de la clase, y la disfrutarán más.</li>
                </ul>
              </div>
            </div>

            <div role="tabpanel" class="tab-pane" id="fines">
              <div class="english">
                <p>Fines are a way of ensuring that every student is earning his or her Exemplary Student paycheck.
                  It is important to position fines as a way of helping students understand costs and consequences—
                  <strong>not as a means of punishment</strong>.</p>

                <p>The list of fines should be short and direct, matching your classroom priorities. Our list
                  is an example.</p>
              </div>
              <div class="spanish hidden">
                <p>Las multas son una manera de asegurar que cada estudiante está ganando su cheque de pago
                  por el trabajo de ser un "estudiante ejemplar". Los estudiantes que no cumplen con los
                  requisitos les siguen pagando sus salarios, pero están sujetos a las multas de la economía
                  de la clase. Es importante que entiendan que las multas son una manera de ayudar a los
                  estudiantes a entender los costos y las consecuencias de sus acciones
                  <strong>no son un medio de castigo</strong>.</p>

                <p>La lista de multas debe ser breve y directa, que coincida con las prioridades de su clase.
                  Nuestra lista es un ejemplo.</p>
              </div>
              <table class="table">
                <tbody class="english">
                <tr>
                  <th>Rule</th>
                  <th>Ticket amount</th>
                </tr>
                <tr>
                  <td>Dishonesty</td>
                  <td>
                    <strong>$500</strong>
                  </td>
                </tr>
                <tr>
                  <td>Rudeness</td>
                  <td>
                    <strong>$100</strong>
                  </td>
                </tr>
                <tr>
                  <td>Not bringing materials to class</td>
                  <td>
                    <strong>$100</strong>
                  </td>
                </tr>
                <tr>
                  <td>Missing work</td>
                  <td>
                    <strong>$50</strong>
                  </td>
                </tr>
                <tr>
                  <td>Off-task behavior, such as talking after lecture</td>
                  <td>
                    <strong>$50</strong>
                  </td>
                </tr>
                <tr>
                  <td>Tardiness</td>
                  <td>
                    <strong>$50</strong>
                  </td>
                </tr>
                </tbody>
                <tbody class="spanish hidden">
                <tr>
                  <th>Regla</th>
                  <th>Costo por la multa</th>
                </tr>
                <tr>
                  <td>Deshonestidad</td>
                  <td>
                    <strong>$500</strong>
                  </td>
                </tr>
                <tr>
                  <td>Grosería</td>
                  <td>
                    <strong>$100</strong>
                  </td>
                </tr>
                <tr>
                  <td>No estar listo para la clase</td>
                  <td>
                    <strong>$100</strong>
                  </td>
                </tr>
                <tr>
                  <td>Faltar tareas</td>
                  <td>
                    <strong>$50</strong>
                  </td>
                </tr>
                <tr>
                  <td>Comportamiento no apropiado (ejemplo: hablar durante una presentación</td>
                  <td>
                    <strong>$50</strong>
                  </td>
                </tr>
                <tr>
                  <td>Impuntualidad</td>
                  <td>
                    <strong>$50</strong>
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="english">
                <p>Fines are delivered at the time of the infraction to help students understand their wrongdoing.
                  Use our
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Bonus-Fine_Tickets_11-12.pdf')">bonus/fine slips</a> to make the process easy.</p>

                <div class="tip">
                  <img src="assets/images/lightbulbIcon.svg">
                  <strong>TIPS:</strong>
                </div>
                <ul>
                  <li>The list of behaviors that elicit fines should reflect your own standards. As an example,
                    our list allots the steepest fine to dishonesty, reflecting a belief that truthfulness
                    is an essential value for students to absorb.</li>
                  <li>Make sure the fines you choose fit the culture of your school. For example, promptness
                    may be difficult to enforce in your classroom if it is not a priority in your school.
                  </li>
                </ul>
              </div>
              <div class="spanish hidden">
                <p>Las multas se entregan en el momento de la infracción para ayudar a los estudiantes a entender
                  sus errores. Utilice nuestros
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Bonus-Fine_Tickets_11-12_ES.pdf')">recibos/tiquetes de bonos y multas </a> para facilitar el proceso.</p>

                <div class="tip">
                  <img src="assets/images/lightbulbIcon.svg">
                  <strong>Sugerencias:</strong>
                </div>
                <ul>
                  <li>La lista de comportamientos que provocan multas debe reflejar sus propios estándares.
                    Por ejemplo, nuestra lista asigna la multa más alta a la deshonestidad, lo que refleja
                    la creencia de que decir la verdad es un valor esencial.</li>
                  <li>Asegúrese de que las multas que elija se ajusten a la cultura de su escuela. Por ejemplo,
                    ser puntual puede ser difícil de imponer en su clase si no es una prioridad en su
                    colegio.
                  </li>
                </ul>
              </div>
            </div>
            <div role="tabpanel" class="tab-pane" id="loans">
              <div class="english">
                <p>Loans are available to students through the Loan Officer. Interest for these loans will be
                  front-end loaded. Therefore, there will be no accruing interest. If a student receives
                  a loan for $100 with 20% interest, he or she will have to pay back $120 over a term.
                  Here are the key concepts:</p>
                <ul>
                  <li>Students have the option to take a loan from the Loan Officer. </li>
                  <li>Students can take out a loan to receive money for things like rent or auction items.</li>
                  <li>Students need to pay interest on the loan, as determined by the Loan Officer. We recommend
                    10% or 20%.</li>
                  <li>Students pay installments to the Loan Officer over 1 to 3 months. The term is agreed
                    to by the Loan Officer and the student.</li>
                  <li>Students who do not meet loan minimum payments should face some loss of privilege within
                    the system.</li>
                </ul>
                <b>Loan Terms</b>
                <ul>
                  <li>Principal &mdash; The amount borrowed on the loan. </li>
                  <li>Interest &mdash; The amount that a lender (loan officer) charges for lending you money.
                  </li>
                  <li>Term &mdash; The length of time that the borrower has to pay back the loan. </li>
                  <li>Fixed interest rate &mdash; The interest rate does not change for the term of the loan.
                  </li>
                  <li>Front load &mdash; The total interest to be paid is applied at the beginning of the loan.
                  </li>
                  <li>Minimum payment &mdash; Payment amount needed to keep the loan in good standing.</li>
                </ul>

                <br/>
                <div class="tip">
                  <img src="assets/images/lightbulbIcon.svg">
                  <strong>TIPS:</strong>
                </div>
                <div style="clear:both;"></div>
                <ul>
                  <li>Front load the loans so that students know what they need to pay when they apply for
                    a loan.</li>
                  <li>Keep the term to no longer than 3 months. </li>
                  <li>Minimum payments should be equal monthly payments to satisfy paying the loan in full.</li>
                  <li>We recommend that failure to make a loan payment on time will result in a student not
                    being able to participate in the next auction. Although you may instead choose other
                    privileges to be forfeited, remember that the program is designed to help students
                    learn financial responsibility&mdash;it is not to punish them.</li>
                  <li>When students miss loan payments, it's important to get them back on track as soon as
                    possible. You can take missed payments as opportunities for discussion about choices,
                    and then encourage students to earn bonuses to help catch up on loan payments and
                    get in on the Auction Day fun.</li>
                </ul>
              </div>
              <div class="spanish hidden">
                <p>Los préstamos están disponibles para los estudiantes a través del Oficial de Préstamos. El
                  interés de estos préstamos se cargará al principio. Por lo tanto, no habrá interés acumulado.
                  Si un estudiante recibe un préstamo por $100 con 20% de interés, tendrá que devolver
                  $120 durante un plazo. Aquí están los conceptos claves:</p>
                <ul>
                  <li>Los estudiantes tienen la opción de tomar un préstamo del Oficial de Préstamo. </li>
                  <li>Los estudiantes pueden sacar un préstamo para recibir dinero por cosas como alquiler
                    o artículos de subasta.</li>
                  <li>Los estudiantes deben pagar intereses sobre el préstamo, según lo determine el Oficial
                    de Préstamo. Recomendamos 10% o 20%.</li>
                  <li>Los estudiantes pagan cuotas al Oficial de Préstamos durante 1 a 3 meses. El término
                    es acordado por el Oficial de Préstamo y el estudiante.</li>
                  <li>Los estudiantes que no cumplan con los pagos mínimos del préstamo deben enfrentar alguna
                    pérdida dentro del sistema.</li>
                </ul>
                <b>Terminos del prestamo</b>
                <ul>
                  <li>Principal &mdash; la cantidad prestada en el préstamo. </li>
                  <li>Interés &mdash; la cantidad que un funcionario de préstamos cobra por prestarle dinero.
                  </li>
                  <li>Plazo &mdash; el tiempo en el que debe pagar el préstamo.</li>
                  <li>Tasa de interés fija &mdash; la tasa de interés no cambia durante el plazo del préstamo.
                  </li>
                  <li>Carga frontal &mdash; el interés total a pagar se aplica al comienzo del préstamo.
                  </li>
                  <li>Pago mínimo &mdash; el pago necesario para mantener el préstamo al día.</li>
                </ul>

                <br/>
                <div class="tip">
                  <img src="assets/images/lightbulbIcon.svg">
                  <strong>Sugerencias:</strong>
                </div>
                <div style="clear:both;"></div>
                <ul>
                  <li>Cargue los préstamos con el interés para que los estudiantes sepan lo que deben pagar
                    cuando solicitan un préstamo.</li>
                  <li>Mantenga el plazo no más de 3 meses. </li>
                  <li>Los pagos mínimos deben ser pagos mensuales iguales para satisfacer el pago total del
                    préstamo.
                  </li>
                  <li>Recomendamos que no hacer el pago del préstamo a tiempo resultará en que el estudiante
                    no pueda participar en la próxima subasta. Aunque en su lugar puede elegir otros
                    privilegios para perder, recuerde que el programa está diseñado para ayudar a los
                    estudiantes a aprender la responsabilidad financiera, no para castigarlos.</li>
                  <li>Cuando los estudiantes falten los pagos de los préstamos, es importante ponerlos al día
                    lo más pronto posible. Puede tomar la falta de los pagos como oportunidades para
                    discutir acerca de diferentes opciones, y luego alentar a los estudiantes a ganar
                    bonos para ayudarlos a ponerse al día con los pagos del préstamo y participar en
                    la diversión del Día de la Subasta.</li>
                </ul>
              </div>
            </div>

            <div role="tabpanel" class="tab-pane" id="taxes">
              <div class="english">
                <p>Students are required to pay annual income taxes, which are equal to one month's salary for
                  each job that they hold. Every student automatically holds the job of Exemplary Student,
                  which pays a salary of $750 a month. Therefore, the minimum each student must pay in
                  income taxes annually is $750. Students who hold an additional job will have to pay income
                  tax on that salary as well—teaching them a rudimentary version of tax brackets.</p>

                <p>Students have the option of withholding (paying) taxes from their monthly paychecks or paying
                  the entire lump sum on Tax Day (on or around April 15). Because students can also earn
                  tax deductions for charitable giving or donating items to the auction, some of them may
                  even receive a tax refund on Tax Day.</p>

                <p>Students are encouraged to keep track of their tax deductions and charitable giving on the
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Tax_Record_11-12.pdf')">Classroom Tax Record and Form</a>, which will also serve as their filing paper on Tax
                  Day.
                </p>
              </div>
              <div class="spanish hidden">
                <p>Se requiere que los estudiantes paguen impuestos anuales de ingreso, que son iguales a un
                  salario de un mes por cada trabajo que ellos tienen. Cada estudiante tiene automáticamente
                  el trabajo de ser un "estudiante ejemplar", el cual paga un salario de $750 al mes. Por
                  lo tanto, el mínimo que cada estudiante debe pagar en impuestos sobre la renta anualmente
                  es de $750. Los estudiantes que tienen un trabajo adicional tendrán que pagar impuesto
                  sobre la renta de ese salario también- esto les enseñara una versión rudimentaria de
                  las categorías de impuestos.</p>

                <p>Los estudiantes tienen la opción de retener (pagar) impuestos de sus cheques mensuales o
                  pagar toda la suma entera en el día de los impuestos (que es el o alrededor del 15 de
                  abril). Debido a que los estudiantes también pueden obtener deducciones de impuestos
                  por donaciones caritativas o por donar artículos a la subasta, algunos de ellos pueden
                  incluso recibir un reembolso de impuestos en el día de impuestos.</p>

                <p>Los estudiantes son animados a tener un registro de sus deducciones de impuestos y donaciones
                  caritativas en el
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Tax_Record_11-12_ES.pdf')">registro oficial de impuestos </a>, de la clase, que también servirá como el documento
                  para el día de Impuestos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- INVESTING -->
      <div id="investing" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Your Economic System: Investing</h3>
        <h3 class="nineten spanish hidden">Su Sistema Económico: Como Invertir</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Giving students the opportunity to invest their money is an advanced topic in the classroom economy.
              We highly recommend that you include this option in your classroom if possible. It can serve
              as a springboard for many important discussions including the time value of money, connections
              to historic events, and risks versus rewards.</p>

            <p>Students can open a simulated investment account through the Investment Banker. The Banker then manages
              the students' accounts through our investment simulator—a program designed to apply historical
              returns from randomly selected years.</p>

            <b>Using the investment simulator</b>
            <p>To integrate the simulator into your classroom, you must first create an account to manage your students'
              investment portfolios. Launch the
              <strong>Investment Simulator</strong> to get started. You'll need to create a user name and password
              and enter your class list. Later, either you or the Investment Banker will enter the asset mix
              each student chooses for himself or herself, and the simulator will generate data and charts
              showing returns.</p>

            <p>On or before the first Bill Day, discuss some key concepts of investing with your students. You'll
              find a primer in the
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Investment_Teachers_Guide_11-12.pdf')">
                <strong>Investment Simulator Teacher's Guide</strong>
              </a>. The
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Investment_Journal_11-12.pdf')">
                <strong>Investment Simulator Student Journal</strong>
              </a> is a tool to help your students reflect upon what they're learning about investing throughout
              the year. The Teacher's Guide also provides suggestions for classroom discussions you can base
              upon the journal entries.</p>
            <span class="simulator">
                            <a href="https://banking.myclassroomeconomy.org/investing/login" target="_blank">Investment Simulator</a>
                        </span>

            <b>How the investing process works</b>
            <p>Beginning on the first Bill Day, each student can open an account by selecting an asset mix of stocks
              and bonds and investing an initial amount in that mix. Information about the historical ranges
              of returns for each of these mixes is included in the syllabus.</p>

            <p>The portfolio options included in the simulator are:</p>
            <ul>
              <li>100% bonds, 0% stocks.</li>
              <li>70% bonds, 30% stocks.</li>
              <li>50% bonds, 50% stocks.</li>
              <li>30% bonds, 70% stocks.</li>
              <li>0% bonds, 100% stocks.</li>
            </ul>
            <p>To keep the investing process simple, we suggest that you set these parameters:</p>
            <ul>
              <li>Students can only open an account or make additional contributions on Bill Days.</li>
              <li>Investments must be in $100 increments.</li>
            </ul>

            <p>You might consider offering a special incentive to open an account on the first Bill Day. For example,
              any student opening an account with at least $200 could receive $200 bonus money to add to the
              investment. To make it even more enticing, you could offer a one-time "employer match" bonus
              of up to $1,000.</p>

            <p>Starting with the second Bill Day, the Investment Banker will "advance" the timeline of the simulation,
              and the students' investments will begin to grow or decline. The simulator randomly selects four
              years between 1926 and 2011 and applies the historical returns of the broad stock and bond markets
              in those years to the students' accounts. The results are displayed by the simulator at the student
              level and at the classroom level. The students review what happened to their accounts and answer
              questions in their journals or have a class discussion. They then have the opportunity to contribute
              more money to their accounts for the next month.</p>

            <p>On each succeeding Bill Day, the same process takes place with four more randomly selected years
              of historical returns and another opportunity to contribute to the account.</p>

            <p>In many school districts, the calendar comfortably allows for the students to experience eight Bill
              Days during a school year. Thus the simulation will yield 32 years of returns. This timespan
              will enable you to engage your students in robust discussions of such concepts as compounding
              and planning for long-term financial goals.</p>

            <p>Additionally, because the years are selected at random, some of the simulation results may be extreme,
              on either the positive or the negative side. To put such results in context, the students' portfolio
              graphs also show the average returns for their asset mix over the span of 1926 to 2011. That
              helps them understand that, although the returns on stocks and bonds can fluctuate dramatically
              from year to year, over longer spans returns historically have remained close to these averages.
            </p>
          </div>

          <!-- Spanish Investing -->
          <div class="spanish hidden">
            <p>Darles a los estudiantes la oportunidad de invertir su dinero es un tema avanzado en la economía
              de la clase. Recomendamos que incluya esta opción en su clase si es posible. Puede servir como
              una manera de tener discusiones importantes, incluido el valor del dinero y el tiempo, las conexiones
              con eventos históricos y los riesgos contra las recompensas.</p>

            <p>Los estudiantes pueden abrir una cuenta de inversión simulada a través del banquero de inversión.
              El banquero entonces maneja las cuentas de los estudiantes a través de nuestro simulador de inversión
              — un programa diseñado para aplicar retornos históricos de años seleccionados.</p>

            <b>Utilizando el Simulador de Inversiónes</b>
            <p>Para integrar el simulador en su clase, primero debe crear una cuenta para administrar las carteras
              de inversión de sus estudiantes. Inicie el
              <strong>simulador de inversiones</strong> para comenzar. Tendrá que crear un nombre usuario y contraseña
              e ingresar su lista de clase. Más tarde, usted o el banquero de inversiones ingresarán en la
              combinación de activos que cada estudiante elija por sí mismo, y el simulador generará datos
              y gráficos que mostrarán las ganancias.
            </p>

            <p>En o antes del día de la primera factura, discuta algunos conceptos clave de invertir con sus estudiantes.
              Usted encontrará
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Investment_Teachers_Guide_11-12_ES.pdf')">
                <strong>Una Cartilla en la Guía del Profesor</strong>
              </a>del simulador de la inversión. El
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Investment_Journal_11-12_ES.pdf')">
                <strong>Simulador de Inversiones Estudiantiles</strong>
              </a> es una herramienta para ayudar a sus estudiantes a reflexionar sobre lo que están aprendiendo
              sobre la inversión durante todo el año. La guía del profesor también ofrece sugerencias para
              las discusiones en la clase que se puede basar en las entradas de la revista.</p>
            <span class="simulator">
                            <a href="/inv_sim/class_manager.php" target="_blank">Simulador de Inversiones</a>
                        </span>

            <b>Como funciona el proceso de invertir</b>
            <p>Comenzando el primer día de la factura, cada estudiante puede abrir una cuenta seleccionando una
              mezcla de activos de acciones y bonos e invirtiendo una cantidad inicial en esa mezcla. En el
              programa se incluye información sobre los intervalos históricos de rendimiento de cada una de
              estas mezclas.</p>

            <p>Las opciones de portafolios incluidos en el simulador son:</p>
            <ul>
              <li>100% bonos, 0% acciones.</li>
              <li>70% bonos, 30% acciones.</li>
              <li>50% bonos, 50% acciones.</li>
              <li>30% bonos, 70% acciones.</li>
              <li>0% bonos, 100% acciones.</li>
            </ul>
            <p>Para mantener el proceso de invertir simple, le sugerimos que configure estos parámetros:</p>
            <ul>
              <li>Los estudiantes sólo pueden abrir una cuenta o hacer contribuciones adicionales en días de facturación.
              </li>
              <li>Las inversiones deben ser hechas en incrementos de $100.</li>
            </ul>

            <p>Usted podría considerar ofrecer un incentivo especial para abrir una cuenta en el primer día de factura.
              Por ejemplo, cualquier estudiante que abra una cuenta con por lo menos $200 podría recibir $200
              bonificaciones de dinero para agregar a la inversión. Para hacerlo aún más tentador, usted podría
              ofrecer un bono de "Company Match" de una sola vez de hasta $1,000.</p>

            <p>Comenzando con el segundo día de facturas, el banquero de inversiones "avanzará" la línea de tiempo
              de la simulación, y las inversiones de los estudiantes comenzarán a crecer o disminuir. El simulador
              selecciona al azar cuatro años entre 1926 y 2011 y aplica los rendimientos históricos de los
              amplios mercados de acciones y bonos en esos años a las cuentas de los estudiantes. Los resultados
              son mostrados por el simulador a nivel del alumno y a nivel de la clase. Los estudiantes revisan
              lo que sucedió con sus cuentas y responden preguntas en sus diarios o tienen una discusión en
              clase. Luego tienen la oportunidad de aportar más dinero a sus cuentas para el próximo mes.</p>

            <p>En cada día sucesivo del día de las facturas, se lleva a cabo el mismo proceso con cuatro años más
              de retornos históricos seleccionados al azar y otra oportunidad para contribuir a la cuenta.
            </p>

            <p>En muchos distritos escolares, el calendario permite cómodamente que los estudiantes experimenten
              ocho Días de Cuenta durante un año escolar. Por lo tanto, la simulación arrojará 32 años de resultados.
              Este período de tiempo le permitirá involucrar a sus estudiantes en debates sólidos sobre conceptos
              tales como la composición y la planificación de metas financieras a largo plazo.</p>

            <p>Además, debido a que los años se seleccionan al azar, algunos de los resultados de la simulación
              pueden ser extremos, ya sea en el lado positivo o negativo. Para poner estos resultados en contexto,
              los gráficos de cartera de los estudiantes también muestran los retornos promedio de su combinación
              de activos en el período de 1926 a 2011. Eso les ayuda a entender eso, aunque los rendimientos
              de las acciones y los bonos pueden fluctuar drasticamente de un año a otro, durante períodos
              más largos, los rendimientos históricamente se han mantenido cerca de estos promedios.
            </p>
          </div>
        </div>
      </div>

      <!-- INSURANCE -->
      <div id="insurance" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Your Economic System: Insurance</h3>
        <h3 class="nineten spanish hidden">Su Sistema Económico: Seguros</h3>
        <div class="infoBlock">
          <p class="english">Two types of insurance are available: property insurance and auto insurance. You have the option of selecting
            which type of insurance you want to offer, or you can offer both.</p>
          <p class="english">Each type of insurance is structured in the same way.</p>
          <p class="english">Students who buy insurance are protected from the "damage" that could befall the class each week; uninsured
            students will need to pay for repairs.</p>
          <p class="spanish hidden">Hay dos tipos de seguro disponibles: seguro de propiedad y seguro de automóvil. Tiene la opción de seleccionar
            qué tipo de seguro desea ofrecer, o puede ofrecer ambos.</p>
          <p class="spanish hidden">Cada tipo de seguro está estructurado de la misma manera.</p>
          <p class="spanish hidden">Cada tipo de seguro está estructurado de la misma manera. Los estudiantes que compran un seguro están
            protegidos del "daño" que podría sufrir la clase cada semana; los estudiantes no asegurados deberán
            pagar las reparaciones.</p>
          <div>
            <!-- Nav tabs -->
            <ul class="nav nav-tabs nineten" role="tablist">
              <li role="presentation" class="active english">
                <a href="#hiwtab" aria-controls="hiwtab" role="tab" data-toggle="tab">How it works</a>
              </li>
              <li role="presentation" class="english">
                <a href="#questions" aria-controls="questions" role="tab" data-toggle="tab">Questions</a>
              </li>
              <li role="presentation" class="active spanish hidden">
                <a href="#hiwtab" aria-controls="hiwtab" role="tab" data-toggle="tab">Cómo funciona</a>
              </li>
              <li role="presentation" class="spanish hidden">
                <a href="#questions" aria-controls="questions" role="tab" data-toggle="tab">Preguntas</a>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div role="tabpanel" class="tab-pane active" id="hiwtab">
                <p class="english">Students can buy insurance on Bill Day. They can pay for it in one of two ways:</p>
                <p class="spanish hidden">Los estudiantes pueden comprar seguro en el día de las facturas. Pueden pagarlo de una de
                  dos maneras:
                </p>
                <ul class="english">
                  <li>With a one-time yearly purchase fee of $1,200 for one type of insurance (property or
                    auto) or $2,400 for both.</li>
                  <li>With a monthly fee of $200 for one type of insurance or $400 for both.</li>
                </ul>
                <ul class="spanish hidden">
                  <li>Con una cuota anual de $1,200 por un tipo de seguro (propiedad o auto) o $2,400 para
                    ambos.
                  </li>
                  <li>Con una cuota mensual de $200 por un tipo de seguro o $400 para ambos.</li>
                </ul>

                <span class="simulator english" data-toggle="modal" data-target="#autoSimulator">
                                    <a style="text-decoration:none">Auto insurance simulator</a>
                                </span>
                <span class="simulator spanish hidden" data-toggle="modal" data-target="#autoSimulator">
                                    <a style="text-decoration:none">simulador de seguros</a>
                                </span>
                <br>
                <span class="simulator english" data-toggle="modal" data-target="#propertySimulator">
                                    <a style="text-decoration:none">Property simulator</a>
                                </span>
                <span class="simulator spanish hidden" data-toggle="modal" data-target="#propertySimulator">
                                    <a style="text-decoration:none">Simulador de propiedad</a>
                                </span>
                <br>

                <p class="english">Accidents are created (or avoided) by our insurance simulator, which uses random-number generation
                  to determine the outcome. To best illustrate a reasonable trade-off between insurance
                  costs and benefits, we recommend using the simulator 30 times during the school year
                  (approximately once a week after the first month of school).</p>

                <p class="spanish hidden">Para ilustrar una mejor compensación razonable entre gastos de seguros y ventajas, recomendamos
                  usar el simulador 30 veces durante el año escolar (aproximadamente una vez por semana
                  después del primer mes de la escuela).</p>

                <b class="english">How property damage is assessed</b>

                <b class="spanish hidden">Cómo se evalúa el daño a la propiedad</b>

                <p class="english">The insurance simulator randomly selects a number between 1 and 10. The number determines
                  whether there is damage to the desks and, if so, the degree of severity and cost of repair.
                  Students who own insurance are covered for all expenses, but students without insurance
                  will be billed for repairs and the following amounts will be deducted from their accounts:
                </p>

                <p class="spanish hidden">El simulador de seguros al azar selecciona un número entre 1 y 10. El número determina si
                  hay daño a los escritorios y, de ser así, el nivel de seriedad y el costo de la reparación.
                  Los estudiantes que poseen el seguro son cubiertos para todos los gastos, pero los estudiantes
                  sin el seguro se les pasara serán la factura por reparaciones y las cantidades siguientes
                  serán descontadas de sus cuentas:
                </p>

                <ul class="english"> <li>No damage: pay $0.</li>
                <li>Minor damage: pay $100 for repairs.</li>
                <li>Moderate damage: pay $150 for repairs.</li>
                <li>Major damage: pay $200 for repairs.</li>
                </ul>
                <ul class="spanish hidden">
                  <li>Ningún daño: paga $0.</li>
                  <li>Daño mínimo: paga 100$ por reparaciones.</li>
                  <li>Daño moderado: paga 150$ por reparaciones.</li>
                  <li>Daño grave: paga 200$ por reparaciones.</li>
                </ul>

                <b class="english">How to manage insurance sales</b>

                <b class="spanish hidden">Como manejar las ventas de seguros</b>
                <ul>
                  <li class="english">On Bill Day, the Insurance Agent will ask who wants to purchase insurance. Each purchaser
                    will log the payment in his or her bank log, and the Agent will initial the purchase.
                    The Agent will then record the purchaser's name in the
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Insurance_Log_11-12.pdf')">insurance log</a> (see details below).
                  </li>
                  <li class="english">Throughout the month, the Insurance Agent will run the insurance simulator, keep track
                    of the damages, and record them in the insurance log. At the end of the month, the
                    Agent will determine each student's monthly required payment and record it in the
                    log.
                  </li>
                  <li class="spanish hidden">En el día de la factura, el agente de seguros le preguntará quién quiere comprar un seguro.
                    Cada comprador registrará el pago en su registro bancario, y el agente iniciará la
                    compra. A continuación, el agente registrará el nombre del comprador en el
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Insurance_Log_11-12_ES.pdf')">registro del seguro</a> (vea los detalles a continuación).
                  </li>
                  <li class="spanish hidden">A lo largo del mes, el agente de seguros dirigirá el simulador de seguros, hará un seguimiento
                    de los daños y los registrará en el registro del seguro. Al final del mes, el agente
                    determinará el pago mensual requerido por cada estudiante y lo registrará en el registro.
                  </li>
                </ul>

                <b class="english">The insurance log</b>
                <b class="spanish hidden">El registro del seguro</b>
                <p class="english">The insurance log helps the Insurance Agent keep a detailed record of the insurance sales
                  and payments for the class. In the "Insurance purchases" section, the Agent lists every
                  student's name, and then each month records whether he or she has purchased insurance.
                  In the "Simulation records" section, the Agent records the results of each simulation.
                  The Agent records both the type of damage that was displayed by the insurance simulator
                  and the amount of money that must be paid for damages. The Auditors will receive a copy
                  of these logs from which they will easily be able to determine which students owed repair
                  payments each month.
                </p>

                <p class="spanish hidden">El registro de seguros ayuda al agente de seguros mantener un registro detallado de las ventas
                  de seguros y pagos de la clase. Para la porción de compras de seguros, el agente enumera
                  el nombre de cada estudiante, y luego cada mes registra si él o ella ha comprado un seguro.
                  En la sección registros de simulación, el agente registra los resultados de cada simulación.
                  El agente registra tanto el tipo de daño que fue mostrado por el simulador de seguros
                  y la cantidad de dinero que debe ser pagado por daños. Los auditores recibirán una copia
                  de estos registros de los cuales podrán determinar fácilmente qué estudiantes deben pagar
                  los pagos de reparación cada mes.
                </p>

                <b class="english">Additional information</b>
                <b class="spanish hidden">Informacion adicional </b>
                <ul>
                  <li class="english">Students who don't see the value of insurance protection will probably soon find themselves
                    owing money for damage repair. These students must deduct the repair payments from
                    their bank logs. They will do so each week on the honor system, but the payments
                    will later be verified when the Auditor cross-references their bank logs with the
                    insurance log.
                  </li>
                  <li class="english">An uninsured student who changes his or her mind will need to wait until the beginning
                    of the next four-week period to buy insurance. Restricting purchases to four-week
                    intervals (or on the first of each month) not only makes recordkeeping easier but
                    allows the student to experience the potentially costly consequences of skipping
                    insurance coverage.
                  </li>
                  <li class="english">Students who decide to pay for insurance monthly can opt to let their coverage lapse
                    by not paying for it when the next Bill Day comes. The Insurance Agent updates the
                    insurance log and prepares to verify payments are deducted for weekly damages, if
                    any.
                  </li>
                  <li class="english">For those interested in the mathematics of this insurance scheme, here are the details:
                    Each simulation has an expected result of $70 in damages; the calculation is [(0.5)($0)
                    + (0.2)($100) + (0.2)($150) + (0.1)($200) = $70]. Assuming that 30 simulations are
                    executed during the school year, students without insurance can expect to pay, on
                    average, $2,100 for damages. Thus, on average, uninsured students will pay $900 more
                    for damages than they would have paid for insurance—a good illustration of how what
                    seems to save money in the short term can actually cost much more in the long run.
                  </li>
                  <li class="spanish hidden">Los estudiantes que no ven el valor de tener seguros probablemente pronto se encontrarán
                    debiendo dinero por la reparación de los daños. Estos estudiantes deben deducir los
                    pagos de reparación de sus registros bancarios. Lo harán cada semana en el sistema
                    de honor, pero los pagos se verificarán después cuando el auditor haga referencias
                    cruzadas de sus registros bancarios con el registro del seguro.
                  </li>
                  <li class="spanish hidden">Un estudiante sin seguro que cambie de opinión tendrá que esperar hasta el comienzo del
                    siguiente período de cuatro semanas para comprar un seguro. Restringir las compras
                    a periodos de cuatro semanas (o el primero de cada mes) no sólo facilita el mantenimiento
                    de los registros, sino que permite que el estudiante experimente las consecuencias
                    potencialmente costosas de omitir la cobertura del seguro.
                  </li>
                  <li class="spanish hidden">Los estudiantes que deciden pagar el seguro mensualmente pueden optar por dejar su cobertura
                    transcurrir sin pagar cuando llegue el siguiente día de facturas. El Agente de seguros
                    actualiza el registro de seguros y se dispone a verificar que los pagos sean descontados
                    por los daños semanales, si hay alguno.
                  </li>
                  <li class="spanish hidden">Para aquellos interesados en la matemática de este plan de seguro, aquí están los detalles:
                    cada simulación tiene un resultado esperado de $70 en daños; el cálculo es [(0.5)
                    ($ 0) (0.2) ($ 100) (0.2) ($ 150) (0.1) ($ 200) = $70]. Asumiendo que se ejecutan
                    30 simulaciones durante el año escolar, los estudiantes sin seguro pueden esperar
                    pagar, en promedio, $2,100 por daños y perjuicios. Por lo tanto, en promedio, los
                    estudiantes no asegurados pagarán $900 más por daños que los que hubieran cubierto
                    el seguro — una buena ilustración de cómo lo que parece ahorrar dinero en el corto
                    plazo puede realmente costar mucho más a largo plazo.
                  </li>
                </ul>
              </div>

              <div role="tabpanel" class="tab-pane" id="questions">
                <b class="english">How do insurance companies make money?</b>
                <p class="english">Insurance company profits can be reduced to a simple equation:
                  <strong>profit = premium payments minus incurred losses</strong>. Note that this is extremely
                  simplified, in that it does not include the cost of underwriting expenses and investment
                  income.
                </p>
                <p class="english">In the classroom economy, we do not record any payments to the insured students for property
                  damage (these would be the "incurred losses" to an insurance company). We simply assume
                  that these students don't have to pay out of pocket for the repairs.
                </p>
                <p class="english">Another big difference is that the classroom insurance scheme would not be profitable in
                  reality. In a class of 20 students, the Insurance Agent can collect a maximum of $32,000
                  over the school year (if everyone paid the monthly fee of $200 x 8 months). On the other
                  hand, the simulator assumes average damage of $2,100 per student, so the assumed payout
                  totals $42,000—a loss of $10,000 for the insurer, if there were one.
                </p>
                <p class="english">In case a student asks about this math, you can explain that if a real insurance company
                  experienced average claims this high, it would charge much higher fees. Price-setting
                  in the insurance business is often called an "actuarial science." Simply put, the price
                  of insurance coverage (called a premium) is determined by skilled underwriters who identify
                  the risks and decide how much to charge for accepting those risks. In My Classroom Economy,
                  we are keeping the fees low and the probability of damages higher, to better illustrate
                  the cost and consequences of risk-taking.
                </p>

                <!-- spanish how do insurance companies make money table -->
                <b class="spanish hidden">¿Cómo ganan dinero las compañías de seguros?</b>
                <p class="spanish hidden">Las ganancias de las compañías de seguros se puede explicar con una formula simple:
                  <strong>ganancias = pagos prima menos perdidas incurridas</strong>. Tenga en cuenta que esto
                  esta extremadamente simplificado, en el sentido de que no incluye el costo de la suscripción
                  de los gastos y los ingresos de las inversiones.
                </p>
                <p class="spanish hidden">En la economía de la clase, no registramos ningún pago a los estudiantes asegurados por daños
                  a la propiedad (estos serían las "pérdidas incurridas" a una compañía de seguros). Simplemente
                  asumimos que estos estudiantes no tienen que pagar las reparaciones de sus propios bolsillos.
                </p>
                <p class="spanish hidden">Otra diferencia grande es que con el régimen de los seguros de la economía de la clase no
                  habría ganancias en el mundo real. En una clase de 20 estudiantes, el oficial de seguros
                  puede coleccionar un máximo de $32,000 durante el año escolar (si cada uno pagara los
                  honorarios mensuales de $200 x 8 meses). Por otra parte, el simulador asume el daño de
                  $2,100 por estudiante, por tanto los totales de la liquidación asumidos $42,000 — una
                  pérdida de $10,000 para el asegurador, si hubiera uno.
                </p>
                <p class="spanish hidden">En caso de que un estudiante pregunte sobre esta matemática, puede explicarle que si una
                  compañía de seguros real experimentara reclamos promedio tan altos, le cobraría tarifas
                  mucho más altas. La fijación de precios en el negocio de los seguros a menudo se llama
                  una "ciencia actuarial". En pocas palabras, el precio de la cobertura del seguro (llamado
                  prima) lo determinan los suscriptores calificados que identifican los riesgos y deciden
                  cuánto cobrar por aceptar esos riesgos. En My Classroom Economy, mantenemos las tarifas
                  bajas y la probabilidad de posibles daños mayores, para ilustrar mejor el costo y las
                  consecuencias de la tomar riesgos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PREPARING MATERIALS -->
      <div id="prepMat" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Preparing Materials</h3>
        <h3 class="nineten spanish hidden">Preparación de materiales</h3>
        <div class="infoBlock">
          <p class="english">The first step in preparing your materials is to customize the
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Syllabus_11-12.pdf')">syllabus</a> to outline your own version of the program.</p>
          <p class="english">The next step is to gather supplies and compile materials for students. You can download the materials
            or order printed copies for free. Gathering and preparing materials will take approximately 2 to
            3 hours.</p>
          <b class="english">Supplies</b>
          <p class="english">In addition to the materials we provide, you may wish to purchase or gather the following items.
          </p>
          <table class="table english">
            <tbody>
            <tr>
              <td>A
                <strong>shoe box</strong> to hold classroom bonus/fine slips after students record them in their
                logs.
              </td>
              <td>1 per class</td>
            </tr>
            <tr>
              <td>
                <strong>Portfolio folders</strong> with clasps where students can store their bank logs and other
                materials.
              </td>
              <td>1 per student</td>
            </tr>
            <tr>
              <td>
                <strong>Cash box or envelope</strong> to store excess cash. It is important to have an ample
                supply of cash on hand so you can distribute bonus money frequently.</td>
              <td>1 per student</td>
            </tr>
            <tr>
              <td>Items for students to purchase at the store, such as toys, baked goods, or gift certificates.
              </td>
              <td>3 to 7 per auction</td>
            </tr>
            </tbody>
          </table>
          <b class="english">Student folders</b>
          <p class="english">Put the following items into each student folder:</p>
          <table class="table english">
            <tbody>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Syllabus_11-12.pdf')">Syllabus</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Job_Application_11-12.pdf')">Job application</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Rental_Agreement_11-12.pdf')">Rental agreement</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Bill_Pay_Log_11-12.pdf')">Bill payment log</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Bank_Log_11-12.pdf')">Bank log</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Loan_Application_11-12.pdf')">Loan slips</a>
              </td>
              <td>2</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Unpaid_Bill_11-12.pdf')">Unpaid bill notice</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Business_License_11-12.pdf')">Business license application</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Tax_Record_11-12.pdf')">Tax form</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Budget_Log_11-12.pdf')">Budget log</a>
              </td>
              <td>1</td>
            </tr>
            </tbody>
          </table>
          <b class="english">Loan Procedures</b>
          <ul class="english">
            <li>Student determines if he or she would like to borrow money from the bank. </li>
            <li>Student would go to the Loan Officer to fill out a loan slip and agree on the interest rate and term.
            </li>
            <li>Student updates his or her bank log to include the transaction. </li>
            <li>Student goes to the Banker to retrieve the money. </li>
            <li>Once the student is ready to pay off some of the loan or the entire loan they will go to the loan
              office to obtain a signature, and go to the banker to withdraw money out of their account.</li>
          </ul>
          <b class="english">Cash box</b>
          <p class="english">Designate a cash box where students can deposit their bonus/fine slips after updating their bank logs.
            We suggest using a shoe box with a slot cut in the top; this would be easy to use and would store
            the slips safely.
          </p>

          <b class="english">Visual displays</b>
          <p class="english">In addition to the syllabus you give to each student, you may want to create additional visual displays
            for easy reference throughout the year. (If you have Visual Display Artist as one of your classroom
            jobs, the student or students holding this job can create these displays.)
          </p>
          <p class="english">Some suggestions include:</p>
          <table class="table english">
            <tbody>
            <tr>
              <th>Item</th>
            </tr>
            <tr>
              <td>Bulletin board</td>
            </tr>
            <tr>
              <td>Posters (e.g., bonuses, fines)</td>
            </tr>
            <tr>
              <td>Presentation</td>
            </tr>
            </tbody>
          </table>
          <b class="english">Other materials</b>
          <p class="english">For the activities throughout the year, you will need the following materials:</p>
          <table class="table english">
            <tbody>
            <tr>
              <th>Item</th>
              <th>Needed for</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Job_Offer_11-12.pdf')">Job offer letters</a>
              </td>
              <td>Job assignment</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Business_License_11-12.pdf')">Business license application</a>
              </td>
              <td>Throughout the year</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Bank_Log_11-12.pdf')">Bank logs</a>
              </td>
              <td>Throughout the year</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Unpaid_Bill_11-12.pdf')">Unpaid bill notice</a>
              </td>
              <td>Bill Day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Desk_Deed_11-12.pdf')">Deed to desk</a>
              </td>
              <td>Bill Day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Tax_Record_11-12.pdf')">Tax form</a>
              </td>
              <td>Tax Day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Auction_Record_11-12.pdf')">Auction record form</a>
              </td>
              <td>Auction</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Achievement_Cert_11-12.pdf')">Certificate</a>
              </td>
              <td>Year-end wrap-up</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Job_List_11-12.pdf')">Classroom jobs list</a>
              </td>
              <td>Job assignment and Payday</td>
            </tr>
            </tbody>
          </table>
          <!-- spanish preparing materials-->
          <p class="spanish hidden">El primer paso para preparar sus materiales es personalizar el
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Syllabus_11-12_ES.pdf')">plan de estudios</a> para delinear su propia versión del programa.</p>
          <p class="spanish hidden">El siguiente paso es reunir suministros y compilar materiales para los estudiantes Puede descargar los
            materiales u ordenar copias impresas gratis. Reunir y preparar materiales tomará aproximadamente
            de 2 a 3 horas.</p>
          <b class="spanish hidden">Materiales de Clase</b>
          <p class="spanish hidden">Además de los materiales que proporcionamos, es posible que desee comprar o reunir los siguientes artículos.
          </p>
          <table class="table spanish hidden">
            <tbody>
            <tr>
              <td>
                <strong>Cajón o caja de zapatos</strong> para guardar los bonos de la clase / multas finas después
                de que los estudiantes los anoten en sus registros.
              </td>
              <td>1 por clase</td>
            </tr>
            <tr>
              <td>
                <strong>Carpetas de portafolio</strong> con corchetes donde los estudiantes pueden archivar sus
                registros bancarios y otros materiales.
              </td>
              <td>1 por estudiante</td>
            </tr>
            <tr>
              <td>
                <strong>Cajón o caja o sobre de dinero</strong> para guardar dinero en exceso. Es importante
                tener una amplia de dinero en efectivo a mano para que pueda distribuir dinero de bonificación
                con frecuencia.</td>
              <td>1 por estudiante</td>
            </tr>
            <tr>
              <td>Artículos para que los estudiantes compren en subastas, como juguetes, dulces o certificados
                de regalo.</td>
              <td>3 to 7 por subasta</td>
            </tr>
            </tbody>
          </table>
          <b class="spanish hidden">Carpetas de estudiantes</b>
          <p class="spanish hidden">Pon los siguientes artículos en cada carpeta de estudiantes:</p>
          <table class="table spanish hidden">
            <tbody>
            <tr>
              <th>Artículo</th>
              <th>Cantidad</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Syllabus_11-12_ES.pdf')">Plan de estudio</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Job_Application_11-12_ES.pdf')">Aplicación de trabajo</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Rental_Agreement_11-12_ES.pdf')">Contrato de alquiler</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Bill_Pay_Log_11-12_ES.pdf')">Registro de pago de factura</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Bank_Log_11-12_ES.pdf')">Registro de banco</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Loan_Application_11-12_ES.pdf')">Recibos de préstamos</a>
              </td>
              <td>2</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Unpaid_Bill_11-12_ES.pdf')">Noticia de alquiler no renumerado</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Business_License_11-12_ES.pdf')">Aplicación de licencia comercial</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Tax_Record_11-12_ES.pdf')">Formulario de impuestos</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Budget_Log_11-12_ES.pdf')">Registro de presupuesto</a>
              </td>
              <td>1</td>
            </tr>
            </tbody>
          </table>



          <b class="spanish hidden">Procedimientos de prestamo</b>
          <ul class="spanish hidden">
            <li>El estudiante determina si le gustaría pedir prestado dinero del banco.
            </li>
            <li>El estudiante iría al Oficial de Préstamo para completar una hoja de préstamo y acordar la tasa de
              interés y el plazo.
            </li>
            <li>El estudiante actualiza su registro bancario para incluir la transacción.
            </li>
            <li>El estudiante va al banquero para recuperar el dinero.
              < /li>
            <li>Una vez que el estudiante esté listo para pagar parte del préstamo o el préstamo completo,
              irá a la oficina de préstamos para obtener una firma, y acudirá al banco para retirar
              dinero de su cuenta.
            </li>
          </ul>
          <b class="spanish hidden">Caja de dinero</b>
          <p>Cree una caja de dinero donde los estudiantes puedan depositar sus bonos / multas después de que actualicen
            sus registros bancarios. Sugerimos utilizar una caja de zapatos con un corte de ranura en la parte
            superior; esto sería fácil de usar y almacenaría los recibos de manera segura.
          </p>
          <b class="spanish hidden">Visualizaciones</b>
          <p class="spanish hidden">Además del plan de estudios que le da a cada estudiante, es posible que desee crear presentaciones visuales
            adicionales para facilitar la referencia durante todo el año. (Si tiene la artista de visualizaciones
            como uno de los trabajos de su clase, el estudiante o los estudiantes que tengan este trabajo pueden
            crear estas visuales)
          </p>
          <p class="spanish hidden">Algunas sugerencias incluyen:</p>
          <table class="table spanish hidden">
            <tbody>
            <tr>
              <th>Artículo</th>
            </tr>
            <tr>
              <td>Tablón de anuncios</td>
            </tr>
            <tr>
              <td>Pósteres (e.g. bonificaciones, multas)</td>
            </tr>
            <tr>
              <td>Presentación</td>
            </tr>
            </tbody>
          </table>
          <b class="spanish hidden">Otras Materiales</b>
          <p class="spanish hidden">Para las actividades durante todo el año, necesitará los siguientes materiales:</p>
          <table class="table spanish hidden">
            <tbody>
            <tr>
              <th>Artículo</th>
              <th>Propósito</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Job_Offer_11-12_ES.pdf 4_12.pdf')">Carta de oferta de trabajo</a>
              </td>
              <td>Asignación de trabajo</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Business_License_11-12_ES.pdf')">Licencias comerciales</a>
              </td>
              <td>Durante todo el ano</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Bank_Log_11-12_ES.pdf')">Registro bancario</a>
              </td>
              <td>Durante todo el ano</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Unpaid_Bill_11-12_ES.pdf')">Noticia de alquiler no renumerado</a>
              </td>
              <td>Día de Factura</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Desk_Deed_11-12_ES.pdf')">Escritura de escritorio</a>
              </td>
              <td>Día de Factura</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Tax_Record_11-12_ES.pdf')">Formularios de Impuestos </a>
              </td>
              <td>Día de Impuestos</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Auction_Record_11-12_ES.pdf')">La forma por el registro de subasta</a>
              </td>
              <td>La Subasta</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Achievement_Cert_11-12_ES.pdf')">Certificados</a>
              </td>
              <td>Cierre del fin de año </td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Job_List_11-12_ES.pdf')">Lista de Trabajos </a>
              </td>
              <td>Día de Asignación de Trabajo y Entrenamiento</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- REWARDS -->
      <div id="rewards" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Rewards</h3>
        <h3 class="nineten spanish hidden">Recompensas</h3>
        <div class="infoBlock">
          <b class="english">Auction prizes</b>
          <p class="english">Think ahead about items your students might like to purchase at the classroom auctions and involve the
            class Auctioneers early in the year. Encourage them to gather both tangible and intangible rewards.
          </p>
          <p class="english">Examples:</p>
          <table class="table english">
            <tr>
              <th>Tangible</th>
              <th>Intangible</th>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>Fruit.</li>
                  <li>Trophies.</li>
                  <li>CDs/DVDs.</li>
                  <li>Electronic video games.</li>
                  <li>iTunes gift certificates.</li>
                  <li>Coupons or gift certificates to local businesses.</li>
                  <li>Movie tickets.</li>
                  <li>Autographs.</li>
                  <li>Passes to school plays or sports events (such as football or soccer games).</li>
                  <li>Gift certificates from local merchants.</li>
                  <li>Student-donated items (must be approved by the Auctioneer). Can be used as a tax deduction
                    for the donor.</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Skip a class homework assignment (advance teacher approval required).</li>
                  <li>Take a test open book or with notes (advance teacher approval required).</li>
                  <li>Raise one quiz grade (advance teacher approval required).</li>
                  <li>One month's free rent in classroom economy.</li>
                  <li>One hour of tutoring. (A student providing tutoring for the auction can get a percentage
                    of the sale and donate the remainder as a charitable deduction on his or her taxes.)
                  </li>
                  <li>Lunch with the teacher.</li>
                </ul>
              </td>
            </tr>
          </table>
          <div class="tip english">
            <img src="assets/images/lightbulbIcon.svg">
            <strong>TIPS:</strong>
          </div>
          <ul class="english">
            <li>Get the community involved by asking local businesses or vendors to donate products. Use our sample
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Letter_Donation_Request_11-12.docx')">donation request letter.</a>
            </li>
            <li>Request autographs from local celebrities, such as the principal, a coach, the mayor, or business
              leaders. You can have your students write letters to ask for them.</li>
            <li>Have Auctioneers actively involve other students in finding or contributing auction items.</li>
            <li>Hold a fundraising event to raise real-world money to buy items for auction.</li>
          </ul>
          <b class="english">End-of-year rewards</b>
          <p class="english">In the year-end wrap-up, you could allow your students to pool their classroom economy money and purchase
            a class reward, such as:</p>
          <ul class="english">
            <li>Holding a class party.</li>
            <li>Watching a movie in class.</li>
            <li>Holding a class outdoors.</li>
          </ul>

          <!-- spanish rewards -->
          <b class="spanish hidden">Premios de subasta</b>
          <p class="spanish hidden">Piense con antelación acerca de los artículos que sus estudiantes gustarían comprar en las subastas de
            la clase y involucrar a los subastadores de clase a principios del año. Animarlos a reunir recompensas
            tangibles e intangibles.
          </p>
          <p class="spanish hidden">Ejemplos:</p>
          <table class="table spanish hidden">
            <tr>
              <th>Tangible</th>
              <th>Intangible</th>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>Fruta.</li>
                  <li>Trofeos.</li>
                  <li>CDs/DVDs.</li>
                  <li>Videojuegos.</li>
                  <li>Certificados de regalo para iTunes.</li>
                  <li>Certificados de regalo para negocios locales.</li>
                  <li>Billetes de cine.</li>
                  <li>Autógrafos.</li>
                  <li>Pases a juegos escolares o eventos deportivos (como partidos de fútbol o fútbol).
                  </li>
                  <li>Certificados de regalo de comerciantes locales.</li>
                  <li>Artículos donados por los estudiantes (deben ser aprobados por el Subastador). Puede
                    usarse como una deducción fiscal para el donante.</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Omitir una tarea de tarea de clase (se requiere aprobación previa del profesor).</li>
                  <li>Tome un libro abierto de prueba o con notas (se requiere aprobación previa del profesor).
                  </li>
                  <li>Elevar una calificación de prueba (se requiere aprobación previa del profesor).</li>
                  <li>Un mes de alquiler gratuito en My Classroom Economy.</li>
                  <li>Una hora de tutoría. (Un estudiante que proporciona tutoría para la subasta puede obtener
                    un porcentaje de la venta y donar el resto como una deducción caritativa en sus impuestos.)</li>
                  <li>Almuerzo con el profesor.</li>
                </ul>
              </td>
            </tr>
          </table>
          <div class="tip">
            <img src="assets/images/lightbulbIcon.svg">
            <strong>Sugerencia:</strong>
          </div>
          <ul>
            <li>Involucre a la comunidad pidiendo a empresas o proveedores locales que donen productos. Use nuestra
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Letter_Donation_Request_11-12_ES.docx')">carta de solicitud de donación de muestra.</a>
            </li>
            <li>Solicite autógrafos a celebridades locales, como el director, un entrenador, el alcalde o líderes
              empresariales. Puede hacer que sus estudiantes escriban cartas para pedirlas.
            </li>
            <li>Haga que los subastadores involucren activamente a otros estudiantes para encontrar o contribuir
              los artículos de la subasta.
            </li>
            <li>Organice un evento de recaudación de fondos para recaudar dinero en el mundo real para comprar artículos
              para la subasta.
            </li>
          </ul>
          <b>Recompensas de fin de ano escolar</b>
          <p>En la recapitulación de fin de año escolar, puede permitir que sus estudiantes junten su dinero y compren
            una recompensa de clase, como por ejemplo:</p>
          <ul>
            <li>Tener una fiesta de clase.</li>
            <li>Un día de película en clase.</li>
            <li>Tener una clase afuera.</li>
          </ul>
        </div>
      </div>

      <!-- ADVANCED MODULES -->
      <div id="advMod" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Advanced Modules</h3>
        <h3 class="nineten spanish hidden">Módulos Avanzados</h3>
        <div class="infoBlock">
          <p class="english">Teachers may want to include some additional modules and conduct more detailed discussions about some
            classroom economy topics.
          </p>
          <b class="english">Real estate investments</b>
          <p class="english">Taking property ownership a step further, allow students to purchase the deeds to other students' desks.
            In this situation, a student whose desk is purchased pays the rent to a landlord instead of the bank.
            We recommend you insist the rent remain at the bank's original price so that landlords can't force
            students out of their desks by driving the rent too high!
          </p>
          <b class="english">Disaster relief</b>
          <p class="english">Require students to pay a fixed amount (e.g., $100) for disaster relief. This can be especially effective
            if you are studying disasters in science or social studies. For example, if you are studying hurricanes
            in science class, you can pretend there is a hurricane in your classroom and everyone must pay to
            fix the damage.
          </p>
          <b class="english">Emergency funds</b>
          <p class="english">Help students prepare for their future by establishing an emergency fund. Assist the students in determining
            their monthly expenses, and then encourage them to build a nest egg that is greater than or equal
            to that amount. The overall purpose of this module is to teach students the importance of saving
            in a liquid investment such as a bank savings account, so they are prepared for unexpected situations
            that can occur in life. By building an emergency fund, students will be prepared to pay their bills,
            even if they do not receive bonus money or if they lose their job. Keep in mind, anytime students
            use their emergency fund, they will have to devise a plan to replenish it.
          </p>
          <!-- spanish advanced modules     -->
          <p class="spanish hidden">Es posible que los profesores deseen incluir algunos módulos adicionales y realizar discusiones más detalladas
            sobre algunos temas de economía de la clase.</p>
          <b class="spanish hidden">Inversiones de bienes raices</b>
          <p class="spanish hidden">Llevando la propiedad de la propiedad un paso más allá, permita a los estudiantes comprar las escrituras
            en los escritorios de otros estudiantes. En esta situación, un estudiante cuyo escritorio se compra
            ahora paga el alquiler a un propietario en lugar del banco. ¡Recomendamos que insista en que el alquiler
            se mantenga al precio original del banco para que los estudiantes no puedan obligar a los estudiantes
            a salir de sus escritorios aumentando demasiado el alquiler!
          </p>
          <b class="spanish hidden">Alivio de desastres</b>
          <p class="spanish hidden">Los estudiantes tienen que pagar una cantidad fija (por ejemplo, $ 100) para alivio de desastres. Esto
            puede ser especialmente efectivo si está estudiando desastres en ciencias o estudios sociales. Por
            ejemplo, si está estudiando huracanes en la clase de ciencias, puede pretender que hay un huracán
            en su clase y que todos deben pagar para reparar el daño.
          </p>
          <b class="spanish hidden">Fondos de emergencias</b>
          <p class="spanish hidden">Ayude a los estudiantes a prepararse para su futuro estableciendo un fondo de emergencia. Ayude a los
            estudiantes a determinar sus gastos mensuales y luego anímelos a construir una cantidad ahorros que
            sea mayor o igual a esa cantidad. El objetivo general de este módulo es enseñar a los estudiantes
            la importancia de ahorrar en una inversión líquida, como una cuenta de ahorro bancaria, para que
            estén preparados para las situaciones inesperadas que pueden ocurrir en la vida. Al construir un
            fondo de emergencia, los estudiantes estarán preparados para pagar sus facturas, incluso si no reciben
            dinero de bonificación o si pierden su trabajo. Tenga en mente que cada vez que los estudiantes usen
            su fondo de emergencia, deberán diseñar un plan para reponerlo.
          </p>
        </div>
      </div>

      <!-- FIRST DAY -->
      <div id="firstDay" class=" gradesInnerContent hidden">
        <h3 class="nineten english">First day</h3>
        <h3 class="nineten spanish hidden">Primer Día</h3>
        <div class="infoBlock">
          <p class="english">On the first day of the program, you briefly explain the rules and expectations of the classroom economy
            as you would with any classroom management system. You then give the students a syllabus they can
            take home and read as homework.</p>
          <div class="headerTime english">
            <b>Preparation</b>
            <span>60&ndash;90 minutes</span>
          </div>
          <ul>
            <li class="english">Familiarize yourself with the
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Syllabus_11-12.pdf')">syllabus</a> and customize as needed for your classroom.
            </li>
            <li class="english">Create student folders to be handed out at the start of class. (Items to include are listed in
              <a href="#prepMat" class="gradesInnerLink">Preparing Materials</a>.)
            </li>
            <li class="english">Create any customized visual displays of your jobs, bonuses, and fine lists that you desire (posters,
              bulletin boards, etc.). Or plan how students can create these.
            </li>
          </ul>

          <div class="headerTime english">
            <b>In class</b>
            <span>10 minutes</span>
          </div>
          <p class="english">Welcome the students and inform them that they'll be earning and spending money throughout the year.
            Let them know that they will each be paid, in classroom dollars, for holding the job of Exemplary
            Student and that they can apply for additional jobs to make even more money.</p>

          <p class="english">Hand out the folders you created and ask the students to take out the My Classroom Economy Syllabus.
            Assign them a due date (potentially within the next few days) for reading
            <strong>Part One</strong> of the syllabus.</p>
          <div class="tip english">
            <img src="assets/images/lightbulbIcon.svg">
            <strong>TIPS:</strong>
          </div>
          <p class="english">If you like to include your students in creating class rules and policies, feel free to let them suggest
            additional bonus opportunities, fines, and auction items.</p>

          <p class="english">If you have enough time, you can combine the first and second days into one.</p>

          <!-- spanish first day -->
          <p class="spanish hidden">El primer día del programa, usted explica brevemente las reglas y expectativas de la economía de la clasecomo
            lo haría con cualquier sistema de gestión de la clase. A continuación, les proporciona a los estudiantes
            un plan de estudios que pueden llevar a casa y leer como tarea.
          </p>
          <div class="headerTime spanish hidden">
            <b>Preparacion</b>
            <span>60&ndash;90 minutos</span>
          </div>
          <ul>
            <li class="spanish hidden">Familiarícese con el
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Syllabus_11-12_ES.pdf')">programa de economía de la clase</a> clase y personalícelo según sea necesario para su clase.
            </li>
            <li class="spanish hidden">Crea carpetas de estudiantes para repartir al comienzo de la clase. (Los artículos para incluir se
              enumeran en
              <a href="#prepMat" class="gradesInnerLink">Preparación de materiales</a>.)
            </li>
            <li class="spanish hidden">Cree visualizaciones personalizadas de sus trabajos, bonificaciones y listas de multas si lo desea
              (carteles, tableros de anuncios, etc.). O planee cómo los estudiantes pueden crear estos.
            </li>
          </ul>
          <div class="headerTime spanish hidden">
            <b>En clase</b>
            <span>10 minutos</span>
          </div>
          <p class="spanish hidden">Dé la bienvenida a los estudiantes e infórmeles de que ganarán y gastarán dinero durante el año. Hágales
            saber que a cada uno se le pagará, en dólares de clase, por mantener el puesto de "Estudiante Ejemplar"
            y que puede solicitar trabajos adicionales o una licencia comercial para aumentar su potencial de
            ingresos.
          </p>
          <p class="spanish hidden">Distribuya las carpetas que creó y solicite a los estudiantes que saquen el plan de estudios. Asignarles
            una fecha de vencimiento (potencialmente dentro de los próximos días) para leer
            <strong>la Parte Uno</strong> del plan de estudios.
          </p>
          <div class="tip spanish hidden">
            <img src="assets/images/lightbulbIcon.svg">
            <strong>SUGERENCIA:</strong>
          </div>
          <p class="spanish hidden">Si desea incluir a sus estudiantes en la creación de reglas y políticas de la clase, puede permitirles
            sugerir oportunidades de bonificación adicionales, multas y artículos de la subasta.
          </p>
          <p class="spanish hidden">Si tiene tiempo suficiente, puede combinar el primero y el segundo día en uno.
          </p>
        </div>
      </div>

      <!-- SECOND DAY -->
      <div id="secondDay" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Second day</h3>
        <h3 class="nineten spanish hidden">Segundo Día</h3>
        <div class="infoBlock">
          <p class="english">On the first day of the program, you explained the rules and expectations of the classroom economy and
            asked the students to read the classroom economy syllabus as a homework assignment. On day two, you
            go into a little more detail and answer any questions the students might have.
          </p>
          <div class="headerTime">
            <b>Preparation</b>
            <span>10 minutes</span>
          </div>
          <p class="english">Review the syllabus and be ready to answer student questions.</p>
          <div class="headerTime english">
            <b>In class</b>
            <span>20 minutes</span>
          </div>
          <p class="english">Ask students to take the syllabus from their folders. Answer student questions and ensure they understand
            the following key concepts:</p>
          <ul>
            <li class="english">Money is earned in up to four ways:</li>
            <ul>
              <li class="english">As a salary for holding the job of Exemplary Student (applies to all students).</li>
              <li class="english">In bonus rewards for good achievements.</li>
              <li class="english">As a salary for holding an additional classroom job (optional).</li>
              <li class="english">By creating your own business (optional).</li>
              <li class="english">Note: Money can also be obtained through loans but they must be paid back with interest (optional).
              </li>
            </ul>
            <li class="english">At this point, money can be spent in four ways (two others, insurance and investing, will be discussed
              later):
            </li>
            <ul>
              <li class="english">Paying the monthly rent for the student's desk. The rent is $800 per month, but desks can be
                purchased for a one-time payment of $2,400.</li>
              <li class="english">Paying an electricity bill of $150 a month, which can be reduced through the energy-saving efforts
                of the class.</li>
              <li class="english">Buying items or privileges at auctions, which are held approximately monthly.</li>
              <li class="english">Paying fines for not following classroom rules.</li>
            </ul>
            <li class="english">The rental agreement they'll find in their student folders symbolizes their entry into the program.
              Collect the signed forms, sign them yourself when you have time, and return them to the students
              to hold in their folders as a binding contract.</li>
            <li class="english">Students interested in applying for a job in addition to the Exemplary Student role may do so.
            </li>
            <li class="english">Students interested in starting their own businesses may apply for a business license.</li>
          </ul>
          <div class="tip english">
            <img src="assets/images/lightbulbIcon.svg">
            <strong>TIPS:</strong>
          </div>
          <p class="english">If you like to include your students in creating class rules and policies, feel free to let them suggest
            additional bonus opportunities, fines, and auction items.</p>
          <p class="english">The second day can be combined with the first day if you have time.</p>

          <!-- spanish second day -->
          <p class="spanish hidden">El primer día del programa, usted explicó las reglas y expectativas de la economía de la clase y le pidió
            a los estudiantes que leyeran el programa de economía de la clase como una tarea. El segundo día,
            entra un poco más de detalle y responde cualquier pregunta que los estudiantes puedan tener.
          </p>
          <div class="headerTime">
            <b>Preparacion </b>
            <span>10 minutos</span>
          </div>
          <p class="spanish hidden">Revise el plan de estudios y esté listo para responder las preguntas de los estudiantes.</p>
          <div class="headerTime spanish hidden">
            <b>En clase</b>
            <span>20 minutos</span>
          </div>
          <p class="spanish hidden">Pídales a los estudiantes que tomen el plan de estudios de sus carpetas. Contesta las preguntas de los
            estudiantes y asegúrese de que comprendan los siguientes conceptos importantes:</p>
          <ul>
            <li class="spanish hidden">El dinero se gana en hasta cuatro formas:</li>
            <ul>
              <li class="spanish hidden">Como un salario por mantener el trabajo de "Estudiante Ejemplar" (se aplica a todos los estudiantes).
              </li>
              <li class="spanish hidden">En recompensas de bonificación por buenos logros.</li>
              <li class="spanish hidden">Como un salario por tener un trabajo adicional en la clase(opcional).</li>
              <li class="spanish hidden">Creando su propio negocio (opcional).</li>
              <li class="spanish hidden">Nota: El dinero también se puede obtener a través de préstamos, pero deben devolverse con intereses
                (opcional).
              </li>
            </ul>
            <li class="spanish hidden">En este punto, el dinero se puede gastar de cuatro maneras (otros dos, seguro y certificados de depósito,
              se discutirán más adelante):
            </li>
            <ul>
              <li class="spanish hidden">Pagar el alquiler mensual del escritorio del estudiante. El alquiler es de $ 800 por mes, pero
                se pueden comprar escritorios por un pago único de $ 2,400.</li>
              <li class="spanish hidden">Pagar una factura de electricidad de $ 150 por mes, que se puede reducir a través de los esfuerzos
                de ahorro de energía de la clase.</li>
              <li class="spanish hidden">Comprar artículos o privilegios en subastas, que se llevan a cabo aproximadamente mensualmente.
              </li>
              <li class="spanish hidden">Pagar multas por no seguir las reglas de la clase.</li>
            </ul>
            <li class="spanish hidden">El contrato de alquiler que encontrarán en sus carpetas de estudiantes simboliza su ingreso al programa.
              Reúna los formularios firmados, fírmelos usted mismo cuando tenga tiempo y devuélvalos a los
              estudiantes para que los guarden en sus carpetas como un contrato vinculante.
            </li>
            <li class="spanish hidden">Los estudiantes interesados en solicitar un trabajo además del papel de “estudiante ejemplar” pueden
              hacerlo.
            </li>
            <li class="spanish hidden">Los estudiantes interesados en comenzar sus propios negocios pueden solicitar una licencia comercial.
            </li>
          </ul>
          <div class="tip spanish hidden">
            <img src="assets/images/lightbulbIcon.svg">
            <strong>SUGERENCIA:</strong>
          </div>
          <p class="spanish hidden">Si desea incluir a sus estudiantes en la creación de reglas y políticas de clase, no dude en dejarles
            sugerir oportunidades de bonificaciones adicionales, multas y artículos de subasta.
          </p>
          <p class="spanish hidden">El segundo día se puede combinar con el primer día si tiene tiempo.</p>
        </div>
      </div>


      <!-- EVERY DAY -->
      <div id="everyDay" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Every day</h3>
        <h3 class="nineten spanish hidden">Cada Día</h3>
        <div class="infoBlock">
          <p class="english">A critical component of the classroom economy is the combination of bonuses students can earn and fines
            they can incur for their actions. To maximize the effect of the system, it is best to reward or fine
            a student at the time of the action. To do so, simply hand a student a
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Bonus-Fine_Tickets_11-12.pdf')">bonus/fine slip</a>.</p>

          <p class="english">Depending on the nature of the action, you may want to fill out the bonus/fine slip yourself and hand
            it to the student discreetly. On the other hand, when a student does a good job, you may want to
            publicly praise him or her. For example, you might say something like, "Great job, Tina. I love how
            you helped Dave with that problem. Thanks for your help! Please fill out this slip for a $100 bonus
            for quietly helping another student."</p>

          <p class="english">The complete process of handing a student a bonus/fine slip is outlined below:</p>

          <b class="english">Bonus/fine procedures</b>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_9-12_BonusFineSlip_Every.png"></div>-->
            <li class="english">1. Teacher hands the student a bonus/fine slip.</li>
            <li class="english">2. Student fills out the slip at the teacher's direction, if necessary.</li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_BankLog1_Every.png"></div>-->
            <li class="english">3. During an appropriate time in the class period, the student updates his or her bank log to include
              the amount of the bonus or fine.</li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_9-10_BonusFineLog_Every.png"></div>-->
            <li class="english">4. At the end of the period, the student places the slip in the bank box.</li>
            <li class="english">5. Approximately once a week, the Bonus/Fine Administrator empties the bank box, records each slip
              on the bonus/fine log, and stores the slips in a folder.</li>
          </div>

          <p class="english">
            <b>Note:</b> If you have entrepreneurs in your class who have obtained their own business licenses,
            you will need a way to manage student-to-student purchases. Here is our recommendation:</p>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_BankLog2_Every.png"></div>-->
            <li class="english">1. The buyer and seller meet, along with an Auditor, to appropriately debit or credit their bank
              logs.
            </li>
            <li class="english">2. The Auditor witnesses the transaction and initials both logs as verification.</li>
          </div>

          <!-- spanish every day -->
          <p class="spanish hidden">Un componente crítico de la economía de clase es la combinación de bonificaciones que los estudiantes
            pueden ganar y las multas en que pueden incurrir por sus acciones. Para maximizar el efecto del sistema,
            lo mejor es recompensar o multar a un estudiante en el momento de la acción. Para hacerlo, simplemente
            dele a un estudiante
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Bonus-Fine_Tickets_11-12_ES.pdf')">un recibo de bonificación / multa</a>.</p>
          <p class="spanish hidden">Dependiendo de la naturaleza de la acción, es posible que desee completar el comprobante de bonificación
            / multa usted mismo y entregárselo discretamente al estudiante. Por otro lado, cuando un estudiante
            hace un buen trabajo, es posible que desee elogiarlo públicamente. Por ejemplo, podría decir algo
            como: "Buen trabajo, Tina. Me encanta cómo ayudó a David con ese problema. Gracias por su ayuda.
            Llene este recibo con un bono de $ 100 por ayudar a otro estudiante discretamente".
          </p>
          <p class="spanish hidden">El proceso completo de entrega a un estudiante de una bonificación/ multa se describe a continuación:</p>
          <b class="spanish hidden">Procedimientos de bonificaciones/multas</b>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_9-12_BonusFineSlip_Every.png"></div>-->
            <li class="spanish hidden">1. El profesor le entrega al estudiante un recibo de bonificación / multa.</li>
            <li class="spanish hidden">2. El estudiante llena el deslizamiento en la dirección del profesor, si es necesario.</li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_BankLog1_Every.png"></div>-->
            <li class="spanish hidden">3. Durante un tiempo apropiado en el período de clase, el estudiante actualiza su registro bancario
              para incluir el monto de la bonificación o multa.</li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_9-10_BonusFineLog_Every.png"></div>-->
            <li class="spanish hidden">4. Al final del período, el estudiante coloca el deslizamiento en la caja del banco.</li>
            <li class="spanish hidden">5. Aproximadamente una vez a la semana, el administrador de bonificación / multa vacía la caja del
              banco, registra cada recibo en el registro de bonificación / multa y almacena las listas en una
              carpeta.
            </li>
          </div>
          <p class="spanish hidden">
            <b>Nota:</b> si tiene empresarios en su clase que han obtenido sus propias licencias comerciales, necesitará
            una forma de administrar las compras de estudiante a estudiante. Aquí está nuestra recomendación:
          </p>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_BankLog2_Every.png"></div>-->
            <li class="spanish hidden">1. Los estudiantes se reúnen, junto con un auditor, para debitar o acreditar adecuadamente sus registros
              bancarios.
            </li>
            <li class="spanish hidden">2. El Auditor presencia la transacción e inicia ambos registros como verificación.</li>
          </div>
        </div>
      </div>


      <!-- JOB ASSIGNMENT -->
      <div id="jobAss" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Job Assignment and Training Day</h3>
        <h3 class="nineten spanish hidden">Día de Asignación de Trabajo y Entrenamiento</h3>
        <div class="infoBlock">
          <div class="headerTime english">
            <b>Preparation</b>
            <span>30 minutes</span>
          </div>
          <p class="english">Once you collect all the students' job applications, business license requests, and any needed recommendations,
            take a few days to plan the job assignments. It's best to make the assignments in class about a week
            after the students apply.</p>

          <p class="english">Because the Auditors hold so much responsibility, it is important to choose carefully among the applicants
            for those positions. Use the recommendations to help guide your decisions.</p>

          <p class="english">Once the Auditors are chosen, you can move down the list of your other jobs, perhaps starting with those
            you consider most important in your classroom management.</p>

          <p class="english">Prepare a simple job offer letter for each student. (Our template has blanks for the job title and the
            student's name.)
          </p>

          <p class="english">Finally, plan an assignment that students can work on quietly at their seats. While the class works,
            you can train small groups of students on their job responsibilities.</p>

          <div class="headerTime english">
            <b>In class</b>
            <span>30 minutes</span>
          </div>
          <p class="english">Inform the students that those who have applied for specific classroom jobs will be receiving offer letters.
          </p>

          <p class="english">Deliver the job offer letters. Each student who receives one should sign the letter and place it in his
            or her folder.
          </p>

          <p class="english">Next, train the students for their jobs. Help the entire class to begin the assignment you prepared,
            then pull individual students aside for training according to their roles. More detailed training
            is spelled out below for Auditors and Bonus/Fine Administrators.</p>

          <p class="english">Note: After you review the business license requests, we recommend meeting with the students individually
            to briefly discuss your expectations about how the businesses will be conducted in your classroom.
            You can do this at any convenient time over the next few weeks.</p>


          <div>
            <!-- Nav tabs -->
            <ul class="nav nav-tabs nineten" role="tablist">
              <li role="presentation" class="active english">
                <a href="#auditor" aria-controls="auditor" role="tab" data-toggle="tab">Auditor</a>
              </li>
              <li role="presentation" class="english">
                <a href="#bonfine" aria-controls="bonfine" role="tab" data-toggle="tab">Bonus/Fine Administrator</a>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div role="tabpanel" class="tab-pane active" id="auditor">
                <p class="english">The Auditor's job is to carefully analyze a copy of a student's bank log and verify that
                  every transaction was included and that all transactions are valid.
                </p>

                <p class="english">The list below shows the types of transactions that are listed on the bank log, and how the
                  Auditor can verify the amounts are accurate. To help the Auditors, please give each a
                  copy of the
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Auditor_Checklist_11-12.pdf')">auditor checklist</a>.</p>
                <p class="english">
                  <b>Salary:</b> Every student receives $750 each month for being an Exemplary Student. Some
                  students also have additional jobs, and will therefore have two salaries. The Auditor
                  can verify who holds additional jobs on the
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Job_List_11-12.pdf')">classroom job list</a>.</p>
                <p class="english">
                  <b>Rent:</b> Every student must pay $800 each month for rent. If a student has purchased
                  his or her desk, the Auditor can verify it by asking the student for the
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Desk_Deed_11-12.pdf')">deed to desk</a>.</p>
                <p class="english">
                  <b>Electricity:</b> Every student must pay a monthly electricity bill. The amount withdrawn
                  on each student's bank log must equal the total shown for that month on the
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Energy_Log_11-12.pdf')">classroom energy log</a>.</p>
                <p class="english">
                  <b>Bonuses/Fines:</b> The Auditor must verify that the dates and amounts of all bonuses
                  and fines match the
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Budget_Log_11-12.pdf')">bonus/fine log</a>. If there is a discrepancy, the Auditor can ask the Bonus/Fine Administrator
                  to retrieve the actual bonus/fine slip from his or her files.</p>
                <p class="english">
                  <b>Auctions:</b> The Auditor must verify that any purchased auction items, as indicated
                  on the
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Auction_Record_11-12.pdf')">auction record form</a>, are paid for on the bank log.</p>
                <p class="english">
                  <b>Investments:</b> The Auditor verifies investments from a copy of the
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Investment_Log_11-12.pdf')">classroom investment log</a>. Investments may show up as an expense (at time of purchase)
                  or as income (at time of redemption).</p>
                <p class="english">
                  <b>Insurance:</b> There are two different situations the Auditor must be aware of in terms
                  of insurance: either the student purchased insurance, or he or she did not. In the case
                  of purchased insurance, the Auditor must verify the student's name on the
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Insurance_Log_11-12.pdf')">insurance log</a>. If the student did not purchase insurance, then the Auditor must verify
                  that the student paid the full damages that were owed that month by comparing his or
                  her bank log with the amounts shown in the simulation records on the insurance log.
                </p>
                <p class="english">
                  <b>Student-to-student purchases:</b> Students are allowed to run their own business as long
                  as they submit an application and you approve it. All student-to-student transactions
                  are completed side by side, with one student withdrawing the funds and the other student
                  crediting them—a practice that must be done in front of an Auditor so the Auditor can
                  approve the transaction by initialing both students' bank logs. Auditing these transactions
                  will be straightforward because they have already been approved.
                </p>
                <p class="english">
                  <b>Loans:</b>The Auditor must verify that any loan taken from the Loan Officer is paid for
                  on the bank log.</p>


                <p class="english">If the Auditor finds an error on a student's bank log, he or she issues a
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Bonus-Fine_Tickets_11-12.pdf')">bonus/fine slip</a> to that student. The fine should be equal to the amount of the error
                  minus an additional $50 as a penalty. In other words, if the student's bank log shows
                  that he or she has $1,200, but the correct amount is really $1,100, then the Auditor
                  has found a $100 error. The Auditor issues a fine for $150 ($100 error + $50 penalty).
                  After filling out all the bonus/fine slips, the Auditor hands them to you. Use these
                  as a progress indicator—if a student makes several errors on his or her bank log, you
                  may need to provide some extra help.
                </p>
              </div>

              <div role="tabpanel" class="tab-pane" id="bonfine">
                <p class="english">We recommend this procedure: The Bonus/Fine Administrator goes to the cash box once per week
                  and removes all of the bonus/fine slips. He or she then records the amount and date of
                  each slip in the bonus/fine log. After all slips have been recorded in the log, the Administrator
                  files the slips in a folder—sorted and marked by month. In case of a discrepancy on the
                  bonus/fine log, the Bonus/Fine Administrator can retrieve the original document.</p>
              </div>
            </div>
          </div>

          <!-- spanish job assignment -->
          <div class="headerTime spanish hidden">
            <b>Preparacion</b>
            <span>30 minutos</span>
          </div>
          <p class="spanish hidden">Una vez que reúna todas las solicitudes de trabajo de los estudiantes, las solicitudes de licencia comercial
            y cualquier recomendación necesaria, tómese unos días para planificar las asignaciones de trabajo.
            Lo mejor es hacer las tareas en clase aproximadamente una semana después de la presentación de los
            estudiantes.
          </p>
          <p class="spanish hidden">Debido a que los auditores tienen tanta responsabilidad, es importante elegir cuidadosamente entre los
            solicitantes para esos puestos. Use las recomendaciones para ayudar a guiar sus decisiones.
          </p>

          <p class="spanish hidden">Una vez que se eligen los auditores, puede avanzar por la lista de sus otros trabajos, tal vez comenzando
            con aquellos que considera más importantes en la gestión de su clase.</p>

          <p class="spanish hidden">Prepare una simple carta de oferta de trabajo para cada estudiante. (Nuestra plantilla tiene espacios
            en blanco para el título del trabajo y el nombre del estudiante)</p>

          <p class="spanish hidden">Finalmente, planifique una tarea en la que los estudiantes puedan trabajar tranquilamente en sus asientos.
            Mientras la clase funciona, puede entrenar a pequeños grupos de estudiantes en sus responsabilidades
            laborales.
          </p>

          <div class="headerTime spanish hidden">
            <b>En clase</b>
            <span>30 minutos</span>
          </div>
          <p class="spanish hidden">Informe a los estudiantes que aquellos que han solicitado trabajos específicos en la claserecibirán cartas
            de oferta.
          </p>

          <p class="spanish hidden">Entregar las cartas de oferta de trabajo. Cada estudiante que recibe uno debe firmar la carta y colocarla
            en su carpeta.</p>

          <p class="spanish hidden">Luego, entrene a los estudiantes para sus trabajos. Ayude a toda la clase a comenzar la tarea que usted
            preparó, luego retire a los estudiantes individuales para que se capaciten de acuerdo con sus papeles.
            Para los Auditores y los Administradores de bonificaciones / multas, se encuentra una capacitación
            más detallada.</p>

          <p class="spanish hidden">Nota: Después de revisar las solicitudes de licencia comercial, recomendamos reunirse con los estudiantes
            individualmente para discutir brevemente sus expectativas sobre cómo se llevarán a cabo los negocios
            en su clase. Puede hacerlo en cualquier momento conveniente en las próximas semanas.
          </p>


          <div>
            <!-- Nav tabs -->
            <ul class="nav nav-tabs nineten spanish hidden" role="tablist">
              <li role="presentation" class="active">
                <a href="#auditor" aria-controls="auditor" role="tab" data-toggle="tab">Auditor</a>
              </li>
              <li role="presentation" class="spanish hidden">
                <a href="#bonfine" aria-controls="bonfine" role="tab" data-toggle="tab">Bonificacion/Multa Administrador</a>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div role="tabpanel" class="tab-pane active" id="auditor">
                <p class="spanish hidden">El trabajo del Auditor es analizar cuidadosamente una copia del registro bancario del estudiante
                  y verificar que cada transacción se haya incluido y que todas las transacciones sean
                  válidas.
                </p>

                <p class="spanish hidden">La siguiente lista muestra los tipos de transacciones que se enumeran en el registro bancario
                  y cómo el Auditor puede verificar que los montos sean exactos. Para ayudar a los auditores,
                  entregue a cada uno una copia de la lista de
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Auditor_Checklist_11-12_ES.pdf')">verificación del auditor</a>.</p>
                <p class="spanish hidden">
                  <b>Salario:</b> cada estudiante recibe $750 por mes por ser un "Estudiante ejemplar". Algunos
                  estudiantes también tienen trabajos adicionales y, por lo tanto, tendrán dos salarios.
                  El Auditor puede verificar quién tiene trabajos adicionales en la
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Job_List_11-12_ES.pdf')">lista de trabajos</a>.</p>
                <p class="spanish hidden">
                  <b>Alquiler:</b> cada estudiante debe pagar $ 800 por mes en alquiler. Si un estudiante
                  ha comprado su escritorio, el Auditor puede verificarlo pidiéndole al estudiante la
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Desk_Deed_11-12_ES.pdf')">escritura del escritorio</a>.</p>
                <p class="spanish hidden">
                  <b>Electricidad:</b> cada estudiante debe pagar una factura mensual de electricidad. El
                  monto retirado en el registro bancario de cada estudiante debe ser igual al total que
                  se muestra para ese mes en
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Energy_Log_11-12_ES.pdf')">el registro de energía de la clase</a>.</p>
                <p class="spanish hidden">
                  <b>Bonificaciones/multas:</b> el Auditor debe verificar que las fechas y los montos de todos
                  las bonificaciones y multas coincidan con el
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Budget_Log_11-12_ES.pdf')">registro de bonificación / multa</a>. Si hay una discrepancia, el Auditor puede solicitarle
                  al Administrador de Bonificaciones/ Multas que recupere la bonificación / resbalón real
                  de sus archivos.</p>
                <p class="spanish hidden">
                  <b>Subastas:</b> el Auditor debe verificar que los artículos de la subasta comprados, como
                  se indica en el formulario de
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Auction_Record_11-12_ES.pdf')">registro de la subasta</a>, se paguen en el registro bancario.</p>
                <p class="spanish hidden">
                  <b>Inversiones:</b> el Auditor verifica las inversiones a partir de una copia del
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Investment_Log_11-12_ES.pdf')">registro de inversiones de la clase</a>. Las inversiones pueden aparecer como un gasto
                  (en el momento de la compra) o como un ingreso (en el momento de la redención).
                </p>
                <p class="spanish hidden">
                  <b>Seguro:</b> hay dos situaciones diferentes que el Auditor debe conocer en términos de
                  seguro: o el estudiante compró un seguro o no lo hizo. En el caso de un seguro comprado,
                  el Auditor debe verificar el nombre del estudiante en el
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Insurance_Log_11-12_ES.pdf')">registro del seguro</a>. Si el estudiante no compró un seguro, entonces el Auditor debe
                  verificar que el estudiante pagó los daños totales que se debían ese mes al comparar
                  su registro bancario con las cantidades que se muestran en los registros.</p>
                <p class="spanish hidden">
                  <b>Compras de estudiante a estudiante:</b> a los estudiantes se les permite tener su propio
                  negocio siempre que presenten una solicitud y usted la aprueba. Todas las transacciones
                  de estudiante a estudiante se completan una al lado de la otra, un estudiante retira
                  los fondos y el otro estudiante los acredita, una práctica que debe realizarse frente
                  a un auditor para que el auditor pueda aprobar la transacción iniciando el banco de ambos
                  estudiantes registros Por lo tanto, auditar estas transacciones será sencillo porque
                  ya han sido aprobadas.
                </p>
                <p class="spanish hidden">
                  <b>Prestamos:</b>El Auditor debe verificar que cualquier préstamo tomado del Oficial de
                  préstamo se pague en el registro bancario.</p>

                <p class="spanish hidden">Si el Auditor encuentra un error en el registro bancario del estudiante, él o ella le emitirá
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Bonus-Fine_Tickets_11-12_ES.pdf')">una bonificacion/ multa</a> a ese estudiante. La multa debe ser igual al monto del error
                  menos $ 50 adicionales como penalización. En otras palabras, si el registro bancario
                  del estudiante muestra que tiene $ 1,200, pero el monto correcto es de $ 1,100, entonces
                  el Auditor ha encontrado un error de $ 100. El auditor emite una multa de $ 150 ($ 100
                  de error + $ 50 de penalización). Después de completar todas las bonificaciones / multas,
                  el Auditor se las entrega. Úselos como un indicador de progreso: si un estudiante comete
                  varios errores en su registro bancario, es posible que deba proporcionar ayuda adicional.
                </p>
              </div>

              <div role="tabpanel" class="tab-pane" id="bonfine">
                <p class="spanish hidden">Recomendamos este procedimiento: el Administrador de Bonificación/ Multa va a la caja de
                  efectivo una vez por semana y elimina todas las bonificaciones / multas. Él o ella registra
                  el monto y la fecha de cada deslizamiento en el registro de bonos / multa. Después de
                  que se hayan registrado todos los resguardos en el registro, el Administrador archiva
                  los formularios en una carpeta ordenada y marcada por mes. En caso de discrepancia en
                  el registro de bonificaciones / multas, el administrador puede recuperar el documento
                  original.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Syllabus -->
      <div id="syllabus" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Syllabus day</h3>
        <h3 class="nineten spanish hidden">Día del plan de estudios</h3>
        <div class="infoBlock">
          <p class="english">Between the first Payday and the first Bill Day, we suggest you revisit the
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Syllabus_11-12.pdf')">syllabus</a> to discuss the advanced topics with your students.</p>

          <div class="headerTime english">
            <b>Preparation</b>
            <span>30 minutes</span>
          </div>
          <ul>
            <li class="english">Assign Part Two of the syllabus as a reading assignment for students the night before.</li>
            <li class="english">Prepare to discuss investing with the students.</li>
            <li class="english">Show the insurance simulator (found on Your Economic System: Insurance) to the students.</li>
          </ul>

          <div class="headerTime english">
            <b>In class</b>
            <span>20 minutes</span>
          </div>
          <p class="english">Ask students to take out their copies of the syllabus.</p>
          <p class="english">Answer any questions and make sure that they understand the following key concepts about Bill Day.
          </p>
          <ul>
            <li class="english">All students are required to pay two monthly bills:</li>
            <ul>
              <li class="english">Rent, at $800 per desk.</li>
              <li class="english">Electricity, which is $150 (unless adjusted by the Electrician to reflect energy conservation).
              </li>
            </ul>
            <li class="english">Each student can open an investment account and contribute to it throughout the year:</li>
            <ul>
              <li class="english">The student will select an asset mix of stocks and bonds upon opening the account. It is the
                student's responsibility to learn the differences between these asset classes by using Part
                Two of the syllabus.
              </li>
              <li class="english">Investments must be made in $100 increments.</li>
              <li class="english">Accounts can be opened and contributions made only on Bill Day.</li>
              <li class="english">If applicable, explain the one-time bonus you will offer on the first Bill Day.</li>
            </ul>
            <li class="english">Insurance policies are available for purchase:</li>
            <ul>
              <li class="english">Students have three options: to buy homeowners or auto insurance for a month at a time, to buy
                a full-year policy, or to skip buying insurance altogether that month.
              </li>
              <li class="english">Policies are available only on Bill Day.</li>
              <li class="english">The insurance simulator will be operated about four times a month to determine whether the class
                has suffered property damage, and if so, how much. When damage occurs, students who do not
                have insurance must deduct payment for repairs from their savings accounts. Students with
                insurance will not have to pay for repairs.</li>
            </ul>
          </ul>
          <p class="english">Remind the students to work hard to earn bonus money before the first Bill Day so they can participate
            in the auction or take advantage of some of the other advanced offerings.
          </p>
          <!-- spanish syllabus -->
          <p class="spanish hidden">Entre el primer Día de pago y el primer Día de facturas, le sugerimos que revise el
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Syllabus_11-12_ES.pdf')">plan de estudios </a>para discutir los temas avanzados con sus estudiantes.</p>

          <div class="headerTime spanish hidden">
            <b>Preparacion</b>
            <span>30 minutos</span>
          </div>
          <ul>
            <li class="spanish hidden">Asignar la Parte Dos del plan de estudios como una tarea de lectura para los estudiantes la noche
              anterior.
            </li>
            <li class="spanish hidden">Prepárese para discutir el tema de inversiones con sus estudiantes.</li>
            <li class="spanish hidden">Mostrar el simulador de seguro (en Su Sistema Economía: Seguro) a los estudiantes.</li>
          </ul>

          <div class="headerTime spanish hidden">
            <b>En clase</b>
            <span>20 minutos</span>
          </div>
          <p class="spanish hidden">Pida a los estudiantes que saquen sus copias del plan de estudios.</p>
          <p class="spanish hidden">Responda cualquier pregunta y asegúrese de que comprenda los siguientes conceptos clave sobre Día de
            Facturas (el primero del mes).</p>
          <ul>
            <li class="spanish hidden">Los estudiantes deben pagar dos facturas mensuales:</li>
            <ul>
              <li class="spanish hidden">Alquiler por $800.</li>
              <li class="spanish hidden">Electricidad por $ 150 (a menos que el electricista lo ajuste para la conservación de energía).
              </li>
            </ul>
            <li class="spanish hidden">Cada estudiante puede abrir una cuenta de inversión y contribuir a ella durante todo el año:</li>
            <ul>
              <li class="spanish hidden">⦁ El estudiante seleccionará una combinación de activos de acciones y bonos al abrir la cuenta.
                Es responsabilidad del estudiante aprender las diferencias entre estas clases de activos
                utilizando la Parte Dos del plan de estudios.</li>
              <li class="spanish hidden">Las inversiones se deben hacer en $100 incrementos.</li>
              <li class="spanish hidden">Se pueden abrir cuentas y hacer contribuciones solo en Día de factura.</li>
              <li class="spanish hidden">Si corresponde, explique los bonos por única vez que ofrecerá en el primer día de factura.
              </li>
            </ul>
            <li class="spanish hidden">Las pólizas de seguro están disponibles para la compra:</li>
            <ul>
              <li class="spanish hidden">Los estudiantes pueden elegir entre pagos mensuales y una política anual, o saltarse el seguro
                de compras por completo.</li>
              <li class="spanish hidden">Las políticas están disponibles solo en Día de factura.</li>
              <li class="spanish hidden">El simulador de seguros se operará aproximadamente cuatro veces al mes para determinar si la
                clase ha sufrido daños a la propiedad y, de ser así, cuánto. Cuando se produce un daño, los
                estudiantes que no tienen seguro deben deducir el pago de las reparaciones de sus cuentas
                de ahorro. Los estudiantes con seguro no tendrán que pagar por las reparaciones.
              </li>
            </ul>
          </ul>
          <p class="spanish hidden">Recuérdeles a los estudiantes que deben trabajar arduamente para ganar dinero de bonificación antes del
            primer día de factura para que puedan aprovechar algunas de estas ofertas avanzadas.
          </p>
        </div>
      </div>


      <!-- PAYDAY AND AUCTION -->
      <div id="payAndAuc" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Payday and the Auction</h3>
        <h3 class="nineten spanish hidden">El día de pago y la subasta</h3>
        <div class="infoBlock">
          <p class="english">Payday should take place around the 15th of each month. We recommend that, starting with the second Payday,
            you hold an auction after the students are paid so they can spend their hard-earned cash—and so they
            can instantly see the rewards of good budgeting. The auction serves as a powerful learning component.
            Students must be careful to budget adequately for the bills they will need to pay at the end of the
            month.
          </p>
          <p class="english">
            <b>Note:</b> On the first Payday, the students probably won't have worked long enough to earn their
            entire paychecks, but they should receive the full amount anyway because they will need to pay their
            bills soon.</p>

          <div class="headerTime english">
            <b>Preparation</b>
            <span>5&ndash;10 minutes</span>
          </div>
          <p class="english">Make sure the Auctioneers have gathered the items for sale and are prepared to put them up for bidding.
          </p>

          <div class="headerTime english">
            <b>In class</b>
            <span>30 minutes</span>
          </div>
          <p class="english">On the first Payday, you'll need to explain how the process works. The steps are below.</p>

          <p class="english">
            <b>Note:</b> On the final Payday of the year, be sure to let the students withdraw money from their
            investment accounts before the auction. This allows the students who saved and invested well throughout
            the year to reap the benefits by bidding vigorously for popular items.</p>

          <b class="english">Payday procedures</b>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_BankLog_Payday.png"></div>-->
            <li class="english">1. Announce to the class that it is officially Payday. Instruct them to credit their accounts with
              $750 for being Exemplary Students. Students with additional jobs should add those salaries as
              well.
            </li>
            <li class="english">2. Allow students to analyze their updated bank logs and determine their spending limit for the auction.
              Remind them of the upcoming bills they will have to pay.</li>
          </div>

          <b class="english">Auction procedures</b>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_Auction_Auction.png"></div>-->
            <li class="english">1. One of the Auctioneers displays the first item, explains what it is, and opens the bidding at
              the assigned price. Example: "We have an Ultimate Frisbee disc. The starting price is $500. Do
              I have any takers?"
            </li>
            <li class="english">2. Students who want the item raise their hands.
            <li class="english">3. Once a hand is up, the Auctioneer acknowledges the bid and asks if anyone will go for a higher
              price. Example: "[Student name] for $500. Do I hear $600?"
            <li class="english">4. The Auctioneer repeats this process until bidding stops. The last bidder wins the item.
              Example: "[Student Name] for $1,500. Any more bidders? ...Going once, going twice, sold!"
            <li class="english">5. One of the other Auctioneers enters the sale on the auction record form.
            <li class="english">6. The Auctioneer then moves on to the next item.</li>
          </div>
          <b class="english">Collecting payments</b>
          <p class="english">After the auction ends, some students may decide they bid too much and no longer want the item they won.
            It is very important that you require them to purchase it anyway. The auction is a vital tool in
            teaching the lesson of buyer's remorse.</p>
          <p class="english">To make the payment, each student should:</p>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_BankLog2_Payday.png"></div>-->
            <li class="english">1. Write the auction amount in his or her bank log.</li>
            <li class="english">2. Have an Auditor initial the log to attest that the sale price was correctly deducted.</li>
            <li class="english">3. Collect the item from the Auctioneer.</li>
          </div>

          <div class="headerTime english">
            <b>Optional follow-up discussion</b>
            <span>10&ndash;15 minutes</span>
          </div>
          <p class="english">After an auction, some students will feel overwhelmed because they got caught up in the action and spent
            most or all of their money. This can be a tremendous learning opportunity.</p>
          <p class="english">You could use some of the following questions to guide a discussion:</p>
          <ul>
            <li class="english">What made you keep bidding even when the price was getting so high?</li>
            <li class="english">How do you feel now about the item you bought? Was it worth it?</li>
            <li class="english">What concerns do you have about the amount of money you spent?</li>
            <li class="english">How will you go about rebuilding your savings?</li>
            <li class="english">Will you be able to make rent the next time it is due?</li>
            <li class="english">Will you change the way you bid at the next auction?</li>
          </ul>

          <!-- spanish payday and auction -->
          <p class="spanish hidden">Recomendamos que el día de pago sea cerca del 15 de cada mes, y que realicen la primera subasta comenzando
            con el segundo día de pago. Es necesario que los estudiantes utilicen un presupuesto para planear
            para las cuentas que son debidas a finales del mes. Por eso, si pueden participar en la subasta,
            pueden experimentar los frutos de haber presupuestado bien.
          </p>
          <p class="spanish hidden">
            <b>Nota:</b> Es probable que en el primer día de pago los estudiantes no hayan trabajado lo suficientemente
            para ganar el cheque completo. Sin embargo deben recibir el monto entero porque pronto necesitarán
            pagar las cuentas.</p>

          <div class="headerTime spanish hidden">
            <b>Preparación</b>
            <span>5&ndash;10 minutos</span>
          </div>
          <p class="spanish hidden">El subastador debe tener todas las cosas que se van a vender listas para la subasta.</p>

          <div class="headerTime spanish hidden">
            <b>En clase</b>
            <span>30 minutos</span>
          </div>
          <p class="spanish hidden">En el primer día de pago les explica el siguiente proceso a los estudiantes.</p>

          <p class="spanish hidden">
            <b>Nota:</b> En el día de pago final permite a los estudiantes que saquen dinero de sus cuentas de inversión
            antes de la subasta. Así los estudiantes que ahorraron e invirtieron bien durante el año pueden disfrutar
            los beneficios de su trabajo.</p>

          <b class="spanish hidden">Proceso del día de pago</b>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_BankLog_Payday.png"></div>-->
            <li class="spanish hidden">1. En el día de pago, instruye a los estudiantes que agreguen $750 en sus cuentas por ser “estudiantes
              ejemplares.” Los estudiantes con trabajo adicional agregarán estos salarios a sus cuentas también.
            </li>
            <li class="spanish hidden">2. Cada estudiante analiza su registro bancario para determinar el límite de cuánto puede gastar
              en la subasta. Recuérdales de las cuentas que pronto tendrán que pagar.</li>
          </div>

          <b>Proceso de la subasta</b>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_Auction_Auction.png"></div>-->
            <li class="spanish hidden">1. El subastador muestra el primer artículo en oferta y empieza la licitación al precio asignado.
              Por ejemplo: “La primera cosa en oferta es un disco de frisbee. La subasta empieza a $500?”
            </li>
            <li class="spanish hidden">2. Estudiantes que quieren comprar la cosa en oferta levantarán las manos.
            <li class="spanish hidden">3. Cuando una paleta se levanta, el subastador toma nota de la oferta y pregunta a la clase si
              alguien pagaría más. Por ejemplo: “[Nombre] ha ofrecido $500. ¿Alguien quiere ofrecer $600?”"
            <li class="spanish hidden">4. Este proceso se repite hasta la licitación termine. El estudiante con la oferta más grande
              gana. Por ejemplo: “[Nombre] con $1,500. ¿Alguien más? A la una… a las dos… ¡vendido!”
            <li class="spanish hidden">5. Algún otro subastador registra la venta en el formulario de registro de subasta.
            <li class="spanish hidden">6. El subastador muestra la siguiente cosa en oferta a la clase.</li>
          </div>
          <b class="spanish hidden">Cobro de pagos</b>
          <p class="spanish hidden">Es posible que algunos ganadores decidían que gastaron demasiado y ya no quieren la cosa que compraron.
            Es importante que les obligues a comprarlo. La subasta es un buen instrumento para enseñarles sobre
            el remordimiento del comprador.
          </p>
          <p class="spanish hidden">Para hacer pago, cada estudiante debe:</p>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_BankLog2_Payday.png"></div>-->
            <li class="spanish hidden">1. Escribir la cantidad que licitaron en su registro bancario.</li>
            <li class="spanish hidden">2. Pedir las iniciales de un auditor para verificar que dedujo el precio correcto.</li>
            <li class="spanish hidden">3. Recibir lo que compró del subastador.</li>
          </div>

          <div class="headerTime spanish hidden">
            <b>Discurso de seguimiento (opcional)</b>
            <span>10&ndash;15 minutos</span>
          </div>
          <p class="spanish hidden">Después de la subasta, es posible que algunos estudiantes se sientan abrumados porque gastaron todo,
            o la mayoría de su dinero. Esto puede ser una oportunidad para aprender.</p>
          <p class="spanish hidden">Utiliza las preguntas siguientes para guiar el discurso:</p>
          <ul>
            <li class="spanish hidden">¿Por qué continuaron a licitar aunque el precio fue tan alto?</li>
            <li class="spanish hidden">¿Valió la pena comprar lo que compraste?</li>
            <li class="spanish hidden">¿Tienes inquietudes sobre el monto que gastaste para comprarlo?</li>
            <li class="spanish hidden">¿Cómo vas a reconstruir tus ahorros?</li>
            <li class="spanish hidden">¿Vas a poder pagar el alquiler cuando se vence?</li>
            <li class="spanish hidden">¿Vas a utilizar una estrategia diferente para la próxima subasta?</li>
          </ul>
        </div>
      </div>

      <!-- BILL DAY -->
      <div id="billday" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Bill Day</h3>
        <h3 class="nineten spanish hidden">Día de cuentas</h3>
        <div class="infoBlock">
          <div class="headerTime english">
            <b>Preparation</b>
            <span>30 minutes</span>
          </div>
          <ul>
            <li class="english">A rental agreement and a bill payment log should already be in each student's folder.</li>
            <li class="english">Have unpaid bill notices on hand in case anyone cannot pay his or her bills.</li>
            <li class="english">Have deeds available in case someone is ready to purchase a desk.</li>
            <li class="english">For the first Bill Day, prepare to explain the process to the class.</li>
            <li class="english">If you are using the investment simulator, be prepared to use it and to explain its use in the class
              (see
              <a href="#investing" class="gradesInnerLink">Your Economic System: Investing</a>). A few items to do ahead of Bill Day are:</li>
            <ul>
              <li class="english">Register your class in the simulator.</li>
              <li class="english">Enter class and student names (or your Investment Banker can do this for you).</li>
              <li class="english">Determine your expectations for how the class will use the Student Journal.</li>
            </ul>
          </ul>

          <div class="headerTime english">
            <b>In class</b>
            <span>30 minutes</span>
          </div>
          <p class="english">On the first Bill Day, explain to the students that paying bills is one of their most important financial
            responsibilities. It's so important that, unless they pay their bills, they won't be allowed to participate
            in the next auction on Payday.</p>

          <p class="english">On each subsequent Bill Day, typically around the beginning of each month, ask the students to raise
            their hands if they completed their monthly budget. Allow Auditors to quickly circulate the room,
            verify their budgets, and initial the $150 bonus on their bank logs. For more information about budgets,
            see
            <a href="#budgetDay" class="gradesInnerLink">Budget Day</a>.</p>

          <p class="english">Whether the students completed a budget or not, emphasize the importance of planning and watching their
            spending so they'll always have the rent money ready when it's due.</p>

          <p class="english">Remind the students that they can escape rent payments altogether if they can save $2,400 to buy the
            deed to their desk. Strongly encourage them to do this.</p>

          <p class="english">Ask if anyone can purchase his or her desk that month. If a student can do so, celebrate him or her and
            make a show of handing over the deed.</p>

          <p class="english">A student who acquires the deed to a desk should write "paid in full" in his or her bank log each month
            after making the purchase.</p>

          <p class="english">Allow the Electrician to report on electricity usage and provide suggestions about how to reduce next
            month's bill.
          </p>

          <p class="english">Also, remind the students that they will need to pay taxes in April and should consider saving some money
            for this purpose. You can also encourage them to earn tax deductions by making charitable gifts.
            Suggest that they review the tax document you placed in their folders at the beginning of the year
            and keep track of their contributions throughout the year.</p>


          <div class="headerTime english">
            <b>The investment simulator</b>
          </div>
          <p class="english">On the first Bill Day, show the class the investment simulator and explain how it will be used in the
            class. Answer any questions about Part Two of the syllabus. Once the class is comfortable with the
            concept, allow the Investment Banker to circulate the room and record deposits from the students
            interested in opening accounts, Make sure the Investment Banker enters this information on the class
            investment log and the students mark the withdrawals on their bank logs.
          </p>

          <p class="english">Consider offering a one-time bonus to students willing to open an account. For example, any student opening
            an account with at least $200 could receive $200 bonus money to add to the investment. To make it
            even more enticing, you could offer a one-time "employer match" bonus of up to $1,000. We recommend
            offering some type of incentive to encourage the students to participate in the investment simulator.</p>

          <p class="english">Once the Investment Banker has collected all of the deposits—including any bonuses you are offering—have
            him or her enter the information into the simulator.</p>

          <p class="english">On subsequent Bill Days, have the Investment Banker run the investment simulator, then discuss the new
            results with the students. How much time you spend on the returns is up to you. If time permits,
            you may wish to discuss some of the questions from the Student Journal (the Teacher's Guide offers
            tips). You could also consider offering bonus money or extra credit to students who are willing to
            research and explain the historical context of the returns. (Within the simulator, you can hover
            over a data point to see both the specific return and a bit of historical information about that
            particular year.)</p>

          <b class="english">Bill Day procedures</b>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_7-12_Energy_Bills.png"></div>-->
            <li class="english">1. The Auditors verify completed budgets and initial the bonus money credit for those clients.
            </li>
            <li class="english">2. The Electrician announces the usage bill.</li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_BankLog_Bills.png"></div>-->
            <li class="english">3. Each student updates his or her bank log to show the withdrawal of rent and electricity money.
            </li>
            <li class="english">4. Students decide the amount they wish to withhold for future taxes and update their bank logs accordingly.
            </li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_11_12_Insure_Bills.png"></div>-->
            <li class="english">5. The Insurance Agent circulates the room offering insurance policies. A student may purchase a
              policy by deducting the cost from his or her bank log and getting the Insurance Agent to initial
              it. The Insurance Agent records this transaction in his or her log.</li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_11-12_Investment_Bills.png"></div>-->
            <li class="english">6. While you are discussing the students' results from the investment simulator, the Investment Banker
              circulates the room offering them the opportunity to invest more money in their accounts. The
              Banker keeps a record of all investments in the classroom investment log. After entering new
              deposits in the log, he or she can return to the computer and input them into each student's
              account.
            </li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_9-12_Unpaid_Bills.png"></div>-->
            <li class="english">7. If a student cannot pay the month's bills, he or she fills out an unpaid bill notice and gives
              it to you (thereby letting you know the student needs help). The student still deducts the bill
              from his or her bank log and therefore has a negative balance. Students cannot participate in
              the auction until they have cleared all debt.</li>
          </div>

          <b class="english">Closing the books</b>
          <p class="english">Investment accounts in the classroom economy reach maturity near the end of the school year. Shortly
            before the last Auction Day, students can withdraw the money from their portfolios. They can spend
            their cash at the final auction.</p>

          <div class="headerTime english">
            <b>Follow-up discussion</b>
          </div>
          <p class="english">Paying bills is a strong indicator that students are succeeding in the classroom economy program. If
            a student can't pay bills, it is important to get him or her back on track as soon as possible. The
            unpaid bill notice is a mechanism to keep you informed about students who fall behind so you can
            reach out to them. You can encourage these students to participate in more activities so they'll
            earn enough bonus money to catch up on bills and bid in the class auctions.
          </p>

          <!-- spanish bill day -->
          <div class="headerTime spanish hidden">
            <b>Preparación</b>
            <span>30 minutos</span>
          </div>
          <ul>
            <li class="spanish hidden">La carpeta de cada estudiante debe incluir un contrato de alquiler, y un registro de pago de cuentas.
            </li>
            <li class="spanish hidden">Debes tener noticias de cuentas impagadas disponibles si algunos estudiantes no pueden pagar las
              cuentas.
            </li>
            <li class="spanish hidden">Debes tener títulos disponibles en caso de que alguien esté listo para comprar su escritorio.</li>
            <li class="spanish hidden">Para el primer Día de Alquiler, explícales el siguiente proceso a la clase.</li>
            <li class="spanish hidden">Si utilizarás el simulador de inversión, hay que prepararte para poder usarlo y explicar su uso a
              la clase (refiere a
              <a href="#investing" class="gradesInnerLink">Your Economic System: Investing</a>). En preparación, debes:</li>
            <ul>
              <li class="spanish hidden">Registrar tu clase con el simulador.</li>
              <li class="spanish hidden">Introducir la clase y los nombres de los estudiantes (el banquero de inversión puede hacer esto).
              </li>
              <li class="spanish hidden">Determinar cuáles son tus expectativas para el uso de la jornada.</li>
            </ul>
          </ul>

          <div class="headerTime spanish hidden">
            <b>En clase</b>
            <span>30 minutos</span>
          </div>
          <p class="spanish hidden">En el primer Día de cuentas explícales a los estudiantes que pagar las cuentas es una de sus responsabilidades
            financieras más importantes. Si no se pagan, no se permitirá que participen en el la próxima subasta
            en el día de pago.</p>

          <p class="spanish hidden">Cada día de cuentas que sigue, típicamente durante los primeros días del mes, pide que los estudiantes
            que han cumplido su presupuesto mensual levanten las manos. Los auditores pueden entonces confirmar
            los presupuestos y firmar sus registros bancarios con sus iniciales para indicar que merecen el bono
            de $150. Para más información sobre presupuestos, vea
            <a href="#budgetDay" class="gradesInnerLink">Día de Presupuestos</a>.</p>

          <p class="spanish hidden">Si los estudiantes cumplieron un presupuesto o no, hay que reforzar la importancia de planear y controlar
            los gastos para que siempre tengan suficientes fondos para pagar el alquiler cuando sea debido .
          </p>

          <p class="spanish hidden">Recuérdales a los estudiantes que pueden evitar pagos de alquiler si pueden ahorrar $2,400 para comprar
            el título de sus escritorios. Anímalos fuertemente que hagan esto.</p>

          <p class="spanish hidden">Pregúntales a los estudiantes si alguien puede comprar su escritorio. Si uno quiere hacerlo, celebra
            la transacción.</p>

          <p class="spanish hidden">Estudiantes que han comprado los títulos de sus escritorios deben escribir “pagado en totalidad” en su
            registro bancario cada mes siguiente.</p>

          <p class="spanish hidden">Permite que el electricista informe a la clase sobre el consumo eléctrico y sugiera métodos de reducir
            la cuenta del mes que viene.</p>

          <p class="spanish hidden">También, hay que recordarles que tendrán que pagar los impuestos en abril, y deben considerar reservar
            dinero por esto motivo. Anímalos a hacer donaciones benéficas para recibir deducciones fiscales.
            Sugiere que revisen el documento de impuestos que tienen en sus carpetas, y que controlen sus donaciones
            benéficas durante el año.
          </p>


          <div class="headerTime spanish hidden">
            <b>Simulador de inversión</b>
          </div>
          <p class="spanish hidden">En el primer Día de Cuentas, muéstrales a los estudiantes el simulador de inversión, y explícales cómo
            se utilizará en la clase. Contesta cualquier pregunta sobre parte dos del sílabo. Cuando los estudiantes
            entienden el concepto, el banquero de inversión anotará depósitos de los estudiantes que quieren
            abrir cuentas de inversión. Asegúrate que el banquero de inversión anote esta información en el registro
            de inversiones de la clase y que los estudiantes anoten los retiros en sus registros bancarios.
          </p>

          <p class="spanish hidden">Puedes ofrecer un bono a los estudiantes que abren cuentas de inversión. Por ejemplo, un estudiante que
            abre una cuenta con por lo menos $200 recibirá un bono de $200. Para más incentivo, podrías ofrecer
            una “contribución de empleador” de hasta $1,000. Recomendamos que ofrezca algún incentivo para participar
            en el simulador de inversión.
          </p>

          <p class="spanish hidden">Cuando el banquero de inversión ha acumulado todos los depósitos – incluyendo cualquier bono – él debe
            introducir toda la información en el simulador.</p>

          <p class="spanish hidden">Cada siguiente Día de Cuentas, pide que el banquero de inversiones ejecute la simulación y discuta los
            resultados con la clase. Puedes gastar el tiempo que quieras en hacer esto. Puedes también discutir
            las preguntas de la jornada del estudiante (la guía para maestros ofrece sugerencias). También les
            puedes ofrecer bonos o crédito extra a los estudiantes que quieren investigar y explicar el contexto
            histórico de los rendimientos. (En el simulador, puedes pasar el puntero del ratón sobre un dato
            para ver información histórica sobre este año particular)</p>

          <b class="spanish hidden">Proceso del día de cuentas</b>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_7-12_Energy_Bills.png"></div>-->
            <li class="spanish hidden">1. Auditores verifican presupuestos cumplidos y firman con sus iniciales para indicar que el cliente
              merece un bono.</li>
            <li class="spanish hidden">2. El electricista anuncia la cuenta del consumo eléctrico.</li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_BankLog_Bills.png"></div>-->
            <li class="spanish hidden">3. Cada estudiante actualiza su registro bancario para mostrar el retiro de dinero para pagar el
              alquiler y electricidad.</li>
            <li class="spanish hidden">4. Estudiantes eligen el monto de impuestos que quieren retener, y actualizan sus registros bancarios.
            </li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_11_12_Insure_Bills.png"></div>-->
            <li class="spanish hidden">5. El agente de seguros ofrece pólizas de seguros a la clase. Estudiantes pueden comprar una póliza
              para deducir el costo de su registro bancario con las iniciales del agente de seguros. El agente
              anota esta transacción en su registro.</li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_11-12_Investment_Bills.png"></div>-->
            <li class="spanish hidden">6. Mientras estás revisando los resultados del simulador de inversión con los estudiantes, el banquero
              de inversión ofrece la oportunidad de invertir más. El banquero mantiene un registro de todas
              las inversiones de la clase en el registro de inversión. Después de actualizar el registro con
              inversiones nuevas, las introduce en la computadora.
            </li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_9-12_Unpaid_Bills.png"></div>-->
            <li class="spanish hidden">7. Si el estudiante no puede pagar las cuentas, tendrá que llenar una noticia de cuenta impagada
              y lo entrega al maestro. El estudiante deduce la cuenta de su registro bancario y anota su balance
              negativo. Estudiantes no pueden participar en la subasta hasta que hayan pagado sus deudas.
            </li>
          </div>

          <b class="spanish hidden">Cerrar las cuentas</b>
          <p class="spanish hidden">En la economía de la clase, cuentas de inversión maduran cerca del final del año escolar. Antes del último
            día de subasta, estudiantes pueden retirar dinero de sus portafolios y gastarlo en la subasta final.</p>

          <div class="headerTime spanish hidden">
            <b>Discurso de seguimiento</b>
          </div>
          <p class="spanish hidden">Poder pagar las cuentas es una buena indicación del éxito de los estudiantes en el programa del Classroom
            Economy. Si alguien no puede pagar las cuentas, es importante que corrijan sus finanzas lo antes
            posible. Puedes informarte de los estudiantes que están retrasados en sus pagos con la noticia de
            cuentas impagadas. Anímalos a participar en más actividades para ganar más.
          </p>
        </div>
      </div>


      <!-- TAX DAY -->
      <div id="taxDay" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Tax Day</h3>
        <h3 class="nineten spanish hidden">Día de impuestos</h3>
        <div class="infoBlock">
          <p class="english">Students are required to pay annual income taxes on or around April 15. On this day, students complete
            their tax returns and determine if they owe additional money, have paid their taxes in full, or have
            earned a refund.</p>
          <p class="english">Throughout the year, students had the option of withholding (paying) taxes from their monthly paychecks.
            They also had the opportunity to earn tax credits by donating items to the auction or performing
            community service. Each action garners a $100 tax reduction.</p>

          <div class="headerTime english">
            <b>In class</b>
            <span>30 minutes</span>
          </div>
          <b class="english">Introducing taxes</b>

          <p class="english">Explain to students that federal income taxes are collected by the U.S. government to help pay for national
            programs such as the court system, the military, interstate highways, and all the hundreds of other
            services that Americans use. Similarly, in the classroom economy, taxes are collected to pay for
            students' salaries.</p>

          <b class="english">Tax Day procedures</b>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_9-12_Tax_Tax_p1.png"></div>-->
            <li class="english">1. Throughout the year, each student keeps track of his or her tax withholding payments and tax credits.
            </li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_9-12_Tax_Tax_p2.png"></div>-->
            <li class="english">2. By Tax Day, each student finishes the form to determine the amount of taxes he or she still owes
              or will receive as a credit.</li>
            <li class="english">3. The Auditor verifies the tax amount and makes sure the bank logs match—initialing when they're
              correct.
            </li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_BankLog_Tax.png"></div>-->
            <li class="english">4. The student enters that amount as a withdrawal or credit in his or her bank log.</li>
            <li class="english">5. The Auditor verifies the tax amount and makes sure the bank logs match—initialing when they're
              correct.
            </li>
          </div>

          <!-- spanish tax day -->
          <p class="spanish hidden">Es necesario que los estudiantes paguen impuestos sobre la renta en (o antes de) el 15 de abril. En este
            día estudiantes cumplen sus declaraciones de impuestos para determinar si deben algo, si merecen
            una devolución, o si han pagado los impuestos en totalidad.</p>
          <p class="spanish hidden">Durante el año, estudiantes pueden retener (pagar) los impuestos de sus cheques mensuales. También tienen
            la oportunidad de ganar créditos ($100) por donar cosas para vender en la subasta o por hacer servicio
            comunitario.
          </p>

          <div class="headerTime spanish hidden">
            <b>En clase</b>
            <span>30 minutos</span>
          </div>
          <b class="spanish hidden">Introducir impuestos</b>

          <p class="spanish hidden">Explícales a los estudiantes que el gobierno estadounidense utiliza los impuestos federales para financiar
            programas nacionales como el sistema de cortes, las fuerzas armadas, las carreteras interestatales,
            y muchos otros servicios que usa la gente estadounidense. En la economía de la clase, los impuestos
            financian los salarios de los estudiantes.</p>

          <b class="spanish hidden">Proceso del día de impuestos</b>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_9-12_Tax_Tax_p1.png"></div>-->
            <li class="spanish hidden">1. Durante el año, cada estudiante mantiene un registro de sus retenciones y créditos.</li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_9-12_Tax_Tax_p2.png"></div>-->
            <li class="spanish hidden">2. Antes del día de impuestos, cada estudiante llena su formulario para determinar cuánto debe o
              recibirá como crédito.</li>
            <li class="spanish hidden">3. El Auditor verifica el monto del impuesto y se asegura de que el banco registre la inicialización
              de los partidos cuando están correcto.
            </li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_9_12_BankLog_Tax.png"></div>-->
            <li class="spanish hidden">4. El estudiante ingresa ese monto como retiro o crédito en su registro bancario.</li>
            <li class="spanish hidden">5. El auditor verifica que los números estén correctos y firma con sus iniciales.
            </li>
          </div>
        </div>
      </div>



      <!-- BUDGET DAY -->
      <div id="budgetDay" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Budget Day</h3>
        <h3 class="nineten spanish hidden">Día de presupuestos</h3>
        <div class="infoBlock">
          <div class="headerTime english">
            <b>Preparation</b>
            <span>30 minutes</span>
          </div>
          <ul>
            <li class="english">A
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Budget_Log_11-12.pdf')">budget log</a> should already be in each student's folder.</li>
            <li>For the first Budget Day, prepare to explain the process to the class.</li>
          </ul>

          <div class="headerTime english">
            <b>In class</b>
            <span>30 minutes</span>
          </div>
          <p class="english">On the first Budget Day, tell the students about the importance of keeping a budget—it can ensure that
            you have enough money to pay all your bills and, if you work it right, have enough left over to buy
            your desk, get insured, invest, partake in other benefits of the classroom economy, and even have
            some fun.</p>
          <p class="english">Budgeting is optional for the students, but it does have a concrete benefit for them in the classroom
            economy: Those who complete a monthly budget earn a bonus of $150.</p>

          <b class="english">How it works</b>
          <p class="english">After a full month of receiving salaries and paying bills, the students are now ready to begin budgeting.
          </p>
          <p class="english">Shortly after Bill Day, the students take out the budget log and record their actual expenses for the
            previous month. Each student compares the actual expenses with the estimated expenses listed at the
            start of that month. He or she then creates a new expense estimate for the coming month, making any
            adjustments necessary based on the new results.</p>
          <p class="english">Students who complete a budget must have it verified by their Auditor in order to receive the $150 bonus.
            The Auditor initials both the budget and the bonus entry in each student's bank log.
          </p>

          <b class="english">Budget Day procedures</b>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_11_12_Budget_Budget.png"></div>-->
            <li class="english">1. Student completes the "actual expenses" column in the budget log for the previous month.
            </li>
            <li class="english">2. Student completes the "estimated expenses" column for the coming month.</li>
            <li class="english">3. Student shows the budget log to his or her Auditor, and the Auditor initials both columns.
            </li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_11-12_BankLog_Budget.png"></div>-->
            <li class="english">4. Student adds $150 to his or her bank log as a "Budget Bonus," which is also initialed by the Auditor.
            </li>
          </div>

          <!-- spanish budget day -->
          <div class="headerTime spanish hidden">
            <b>Preparación</b>
            <span>30 minutos</span>
          </div>
          <ul>
            <li class="spanish hidden">La carpeta de cada estudiante debe incluir un
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Budget_Log_11-12_ES.pdf')">registro de presupuestos.</a>
            </li>
            <li class="spanish hidden">Explica el proceso que sigue antes del primer día de presupuestos.</li>
          </ul>

          <div class="headerTime spanish hidden">
            <b>En clase</b>
            <span>30 minutos</span>
          </div>
          <p class="spanish hidden">En el primer día de presupuestos, explícales a los estudiantes la importancia de hacer un presupuesto.
            Con un presupuesto efectivo, un estudiante puede garantizar que tendrá suficientes fondos para pagar
            las cuentas, comprar el escritorio, policías de seguros, invertir, y divertirse.</p>
          <p class="spanish hidden">Hacer un presupuesto no es mandatorio, pero los estudiantes que hacen un presupuesto mensual recibirán
            un bono de $150.</p>

          <b class="spanish hidden">Cómo funciona</b>
          <p class="spanish hidden">La clase estará lista para empezar hacer presupuestos después de un mes entero de recibir un salario
            y pagar cuentas.
          </p>
          <p class="spanish hidden">Después del día de cuentas, estudiantes utilizarán el registro de presupuestos para anotar sus gastos
            actuales del mes anterior, con las estimaciones que hicieron en el principio del mes. Con este análisis
            harán estimaciones para el mes que viene.</p>
          <p class="spanish hidden">Cuando un estudiante completa su presupuesto, y el auditor lo verifica, el estudiante recibirá un bono
            de $150. El auditor firma el presupuesto y el registro bancario con sus iniciales.</p>

          <b class="spanish hidden">Proceso del día de presupuestos</b>
          <div class="olist">
            <!--<div class="imgHolder"><img src="assets/images/Ex_11_12_Budget_Budget.png"></div>-->
            <li class="spanish hidden">1. El estudiante llena el registro de presupuestos con los gastos actuales del mes anterior.</li>
            <li class="spanish hidden">2. El estudiante determina y anota los gastos estimados para el próximo mes.</li>
            <li class="spanish hidden">3. El estudiante entrega el registro de presupuestos al auditor, quien firma los dos números con
              sus iniciales.
            </li>
            <!--<div class="imgHolder"><img src="assets/images/Ex_11-12_BankLog_Budget.png"></div>-->
            <li class="spanish hidden">4. El estudiante actualiza su registro bancario con la “bonificación de presupuesto” de $150. El
              auditor firma esto con sus iniciales también.</li>
          </div>
        </div>
      </div>



      <!-- CREDIT DAY -->
      <div id="creditDay" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Credit Card Day</h3>
        <h3 class="nineten spanish hidden">Día de tarjeta de crédito</h3>
        <div class="infoBlock">
          <p class="english">Understanding credit cards is an important part of financial literacy. This optional—but recommended—module
            teaches students about the advantages, risks, and responsibilities that come with owning a credit
            card. The project is flexible and can be adapted to suit the specific class.</p>

          <div class="headerTime english">
            <b>Preparation</b>
            <span>15 minutes</span>
          </div>
          <ul>
            <li class="english">Customize and print a copy of the
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Credit_Card_Research_11-12.pdf')">Credit Card Research Project</a> handout for each student.</li>
            <li class="english">Review the assignment details and be ready to answer questions.</li>
            <li class="english">Decide on the following points about how you will offer this project to the class:</li>

            <ul>
              <li class="english">Will the students work on the project individually, in groups, or either way depending on their
                preferences?
              </li>
              <li class="english">Is the project optional or required?</li>
              <li class="english">What will be the due date?</li>
              <li class="english">What will you offer in bonus money for completing the project? Here is a suggestion for bonuses
                based on quality:</li>

              <ul>
                <li class="english">Most creative presentation (up to $300).</li>
                <li class="english">Best additional information beyond the eight topics (up to $300).</li>
                <li class="english">Completeness of information (up to $200).</li>
                <li class="english">Accuracy of information (up to $200).</li>
              </ul>
            </ul>
          </ul>

          <div class="headerTime english">
            <b>In class</b>
            <span>15 minutes</span>
          </div>
          <ul>
            <li class="english">Give each student the Credit Card Research Project handout.</li>
            <li class="english">Explain the procedural details you decided upon in the preparation phase.</li>
            <li class="english">Discuss the components of the project. (The class should cover all topics, but if there are multiple
              groups, you might ask each group to cover only two topics.) Most notably, the students are to
              create 10- to 15-minute presentations that will do the following:</li>
            <ul>
              <li class="english">Define credit cards.</li>
              <li class="english">Explain how they are used.</li>
              <li class="english">Identify the advantages of credit cards.</li>
              <li class="english">Describe how a person acquires a credit card.</li>
              <li class="english">Define interest rates and how they work with credit cards.</li>
              <li class="english">Describe credit card fees.</li>
              <li class="english">Review the risks associated with credit cards, including what happens when only the minimum amount
                is paid each month.</li>
              <li class="english">Introduce credit scores and how credit cards influence these scores.</li>
              <li class="english">Include any additional information that students think is important. For example, they might:
              </li>
              <ul>
                <li class="english">List best practices for using credit cards and common ways they are misused.</li>
                <li class="english">Explore the differences between a debit card and a credit card.</li>
              </ul>
            </ul>
          </ul>

          <div class="headerTime english">
            <b>In class (presentations)</b>
            <span>40 minutes (varies depending on number of participants)</span>
          </div>
          <ul>
            <li class="english">Select which topics are to be presented and help get the first student or team get started. Evaluate
              the presentations as they are given and make comments when appropriate (see the suggestions below).
            </li>
            <li class="english">If there is time afterward, have the students discuss such questions as:</li>
            <ul>
              <li class="english">What new information have they learned about the risks of owning a credit card?</li>
              <li class="english">How many students think they are ready to have a credit card?</li>
              <li class="english">Why do they think they are ready, and why not?</li>
            </ul>
            <li class="english">Collect materials from all of the groups.</li>
            <li class="english">Award bonus money based on your evaluation of the presentations and materials.</li>
          </ul>

          <p class="english">As you listen to the presentations, be sure to look for the following points:</p>

          <b class="english">Define credit cards.</b>
          <ul>
            <li class="english">Plastic cards that allow you to buy goods or services on loan from a bank or store.</li>
          </ul>

          <b class="english">Explain how they are used.</b>
          <ul>
            <li class="english">To purchase items in stores.</li>
            <li class="english">For online purchases.</li>
            <li class="english">To get points toward "rewards" such as discounted airline fares.</li>
            <li class="english">For cash advances.</li>
            <li class="english">For checks that can be written against the credit card account.</li>
          </ul>

          <b class="english">Credit card facts.</b>
          <ul>
            <li class="english">The lender puts a ceiling on the amount you can borrow. The ceilings vary widely and can be raised
              if you are a good payer.</li>
            <li class="english">Payments are due monthly against the total balance (unpaid balance from previous month + interest
              on unpaid balance + new purchases).</li>
          </ul>

          <b class="english">Identify the advantages of credit cards.</b>
          <ul>
            <li class="english">Allow you to avoid carrying a lot of cash.</li>
            <li class="english">Let you borrow money to pay for items that you currently cannot afford.</li>
          </ul>

          <b class="english">Describe how a person acquires a credit card.</b>
          <ul>
            <li class="english">What do you have to prove to the lender?</li>
            <li class="english">How does the lender check on your information?</li>
          </ul>

          <b class="english">Review the risks associated with credit cards.</b>
          <ul>
            <li class="english">Each time the bill is not paid in full, an interest charge is added to the balance. Over time the
              bill can grow to become very large, especially if new purchases are added.</li>
            <li class="english">When you buy an item on credit, you are actually paying more than the price tag unless you pay the
              full bill each month. Even then you are likely paying a yearly fee for the credit card.
            </li>
            <li class="english">Interest rates on credit cards range dramatically. Also, many credit cards offer "teaser" rates,
              which are low rates that last only for 6 or 12 months. Sometimes people don't notice when the
              teaser rates expire and their interest charges jump.</li>
            <li class="english">Cards can be lost or stolen, leading in the worst case to identity theft.</li>
            <li class="english">If you cannot pay your monthly bill, your credit rating may be damaged.</li>
          </ul>

          <b class="english">Define interest rates and how they work with credit cards.</b>
          <ul>
            <li class="english">Very generally, no interest is charged on a purchase until the next billing cycle begins. This "grace
              period" means that, if you pay your full balance each month, you will not be charged any interest.
              However, exceptions exist.
            </li>
            <li class="english">If you don't pay your full balance, the interest is added to what you owe. If you keep paying only
              a minimum amount each month, over time the interest charges will make your balance grow even
              if you don't use the card at all.</li>
            <li class="english">The Credit Card Act of 2009 requires that "teaser" rates stay in effect at least 6 months.</li>
          </ul>

          <b class="english">Describe credit card fees.</b>
          <ul>
            <li class="english">How do you learn what your card's fee is?</li>
            <li class="english">No-fee "teasers"—does a fee kick in later?</li>
          </ul>

          <b class="english">Introduce credit scores and how credit cards influence these scores.</b>
          <ul>
            <li class="english">What is a credit score?</li>
            <li class="english">How do credit cards affect credit scores positively and negatively?</li>
          </ul>

          <b class="english">Topics for further discussion and possible bonus awards</b>
          <ul>
            <li class="english">Students may wish to create a simulation showing how interest rates and fees cause an unpaid balance
              to swell in comparison with the total cost for the same purchases if the bill is paid off monthly.</li>
            <li class="english">List best practices for using a credit card, compared to common pitfalls.</li>
            <li class="english">Explain the cost to a business that accepts credit cards.</li>
            <ul>
              <li class="english">Transaction fees.</li>
              <li class="english">Why would a business set a minimum purchase requirement for accepting credit cards?</li>
              <li class="english">Why do some businesses refuse to accept credit cards, and why do some accept only certain types
                of cards?
              </li>
            </ul>
            <li class="english">Discuss whether it is necessary to have multiple credit cards.</li>
          </ul>

          <!-- spanish cred card day -->
          <p class="spanish hidden">Entender tarjetas de crédito es una parte importante de conocimiento financiero. Esta parte del programa
            es opcional, pero recomendamos que la utilices para enseñarle a la clase sobre las ventajas, riesgos,
            y responsabilidades de tener una tarjeta de crédito. El proyecto es flexible y se puede ser adaptado
            a tu clase.</p>

          <div class="headerTime spanish hidden">
            <b>Preparación</b>
            <span>15 minutos</span>
          </div>
          <ul>
            <li class="spanish hidden">Imprime copias personalizadas del folleto de
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Credit_Card_Research_11-12_ES.pdf')">proyecto de investigación de tarjetas de crédito </a>para cada estudiante.</li>
            <li class="spanish hidden">Revisa los detalles del proyecto para poder contestar preguntas de la clase.</li>
            <li class="spanish hidden">Con respecto a cómo planificarás el proyecto, hay que decidir lo siguiente:</li>

            <ul>
              <li class="spanish hidden">¿Trabajarán los estudiantes individualmente, juntos, o dependiente en sus preferencias?
              </li>
              <li class="spanish hidden">¿Será opcional u obligado?</li>
              <li class="spanish hidden">¿Cuándo se vencerá?</li>
              <li class="spanish hidden">¿Ofrecerás algun bono por cumplir el proyecto? Podrías ofrecer bonos de las siguientes cantidades:
              </li>

              <ul>
                <li class="spanish hidden">Presentación más creativa (hasta $300).</li>
                <li class="spanish hidden">Mejor información además de los temas básicos (hasta $300).</li>
                <li class="spanish hidden">Rigor de investigación (hasta $200).</li>
                <li class="spanish hidden">Precisión de información (hasta $200).</li>
              </ul>
            </ul>
          </ul>

          <div class="headerTime spanish hidden">
            <b>En clase</b>
            <span>15 minutos</span>
          </div>
          <ul>
            <li class="spanish hidden">Distribuye el folleto del Proyecto de investigación de tarjetas de crédito a la clase.</li>
            <li class="spanish hidden">Explica las decisiones que has hecho sobre los detalles procesales (ve las preguntas de arriba) en
              la fase de preparación.</li>
            <li class="spanish hidden">Discute el proyecto con la clase (debes de incluir cada tema, pero si quieres puedes pedir que la
              clase se divida en grupos y que cada grupo investigue solo dos temas). Los estudiantes tendrán
              que crear presentaciones de 10 a 15 minutos sobre los siguientes temas:
            </li>
            <ul>
              <li class="spanish hidden">Definir tarjetas de crédito.</li>
              <li class="spanish hidden">Explicar cómo se usan.</li>
              <li class="spanish hidden">Explicar las ventajas de las tarjetas de crédito.</li>
              <li class="spanish hidden">Describir cómo uno adquiere una tarjeta de crédito.</li>
              <li class="spanish hidden">Definir las tasas de interés y cómo se aplican a las tarjetas de crédito.</li>
              <li class="spanish hidden">Describir los cargos de tarjetas de crédito.</li>
              <li class="spanish hidden">Explicar los riesgos del uso de tarjetas de crédito, incluyendo qué pasa cuando solamente se
                paga el saldo mínimo cada mes.</li>
              <li class="spanish hidden">Introducir las calificaciones de crédito y cómo son influidas por el uso de tarjetas de crédito.</li>
              <li class="spanish hidden">Presentar cualquier otro tema que sea importantes según los estudiantes. Por ejemplo:</li>
              <ul>
                <li class="spanish hidden">Una lista de prácticas recomendadas y los errores comunes del uso de tarjetas de crédito.
                </li>
                <li class="spanish hidden">Diferencias entre una tarjetas de débito y crédito.</li>
              </ul>
            </ul>
          </ul>

          <div class="headerTime spanish hidden">
            <b>En clase (presentaciones)</b>
            <span>40 minutos (dependiente en el número de participantes)</span>
          </div>
          <ul>
            <li class="spanish hidden">Determina cuales temas tendrán que ser presentados, y ayúdale al primer estudiante o grupo a empezar.
              Mientras están presentando sus temas, evalúa su trabajo, dando comentario si es necesario (ve
              las sugerencias abajo).
            </li>
            <li class="spanish hidden">Después de concluir la presentación, si hay suficiente tiempo, guía un discurso con la clase sobre
              lo siguiente:
            </li>
            <ul>
              <li class="spanish hidden">¿Qué información nueva han aprendido sobre los riesgos del uso de tarjetas de crédito?</li>
              <li class="spanish hidden">¿Quién en la clase cree que esté listo para usar una tarjeta de crédito?</li>
              <li class="spanish hidden">¿Por qué? o ¿por qué no?</li>
            </ul>
            <li class="spanish hidden">La clase entrega sus materiales al maestro.</li>
            <li class="spanish hidden">Da bonificaciones según tus evaluaciones de las presentaciones y materiales.</li>
          </ul>

          <p class="spanish hidden">Durante las presentaciones, asegura que los estudiantes averigüen lo siguiente:</p>

          <b class="spanish hidden">Definir tarjetas de crédito.</b>
          <ul>
            <li class="spanish hidden">Tarjetas plásticas con que se puede comprar bienes y servicios en préstamo de un banco o una tienda.
            </li>
          </ul>

          <b class="spanish hidden">Explicar cómo se usa tarjetas de crédito.</b>
          <ul>
            <li class="spanish hidden">Para comprar cosas en tiendas.</li>
            <li class="spanish hidden">Para comprar cosas por internet.</li>
            <li class="spanish hidden">Para recibir puntos para “premios”, como billetes de avión descontados.</li>
            <li class="spanish hidden">Para adelantados en efectivo.</li>
            <li class="spanish hidden">Para cheques que se puede girar sobre su cuenta.</li>
          </ul>

          <b class="spanish hidden">Hechos sobre tarjetas de crédito.</b>
          <ul>
            <li class="spanish hidden">El prestador limite la cantidad que puedes tomar. Los límites varían mucho, pero pueden ser elevados
              si pagas tu balance a tiempo.</li>
            <li class="spanish hidden">Hay que hacer pagos mensualmente para reducir el balance total (balance no pagado del mes anterior
              + interés del balance no pagado + compras nuevas).</li>
          </ul>

          <b class="spanish hidden">Identifica las ventajas de tarjetas de crédito.</b>
          <ul>
            <li class="spanish hidden">Con tarjetas de crédito no hay que llevar tanto en efectivo.</li>
            <li class="spanish hidden">Puedes utilizar dinero prestado para comprar cosas cuando no te lo puedes permitir.</li>
          </ul>

          <b class="spanish hidden">Cómo adquerir una Tarjeta de crédito.</b>
          <ul>
            <li class="spanish hidden">¿Qué tienes que probar al prestador?</li>
            <li class="spanish hidden">¿Cómo verifica tus datos el prestador?</li>
          </ul>

          <b class="spanish hidden">Discute los riesgos de usar tarjetas de crédito.</b>
          <ul>
            <li class="spanish hidden">Cuando el balance no se paga en totalidad, se aumenta con intereses. A lo largo de tiempo, el balance
              puede crecer mucho, especialmente si haces nuevas compras.</li>
            <li class="spanish hidden">Con intereses y cargos anuales, si no pagas el balance en totalidad cada mes pagarás más que el precio
              original.
            </li>
            <li class="spanish hidden">Tasas de interés pueden variar mucho. También, muchas tarjetas (“teaser”) ofrecen tasas que son muy
              bajas durante los primeros 6 o 12 meses, pero que suben después de este período. Mucha gente
              no se da cuenta cuando esto ocurre.</li>
            <li class="spanish hidden">Tarjetas de crédito pueden ser robados o perdidos, lo cual puede causar robo de identidad.</li>
            <li class="spanish hidden">No pagar la cuenta mensual puede bajar la calificación crediticia.</li>
          </ul>

          <b class="spanish hidden">Define tasas de interés y como aplican a tarjetas de crédito.</b>
          <ul>
            <li class="spanish hidden">Típicamente, no se carga interés por una compra hasta el próximo período de la cuenta. Por eso, si
              pagas el balance completo cada mes nunca te cargan intereses (con algunas excepciones.
            </li>
            <li class="spanish hidden">Si no pagas el balance completo se añade los intereses al saldo que debes. Si solamente pagas el
              mínimo cada mes, a lo largo de tiempo el balance puede crecer aunque no has comprado ninguna
              otra cosa.</li>
            <li class="spanish hidden">El Credit Card Act of 2009 requiere que tasas “teaser” no expiren en por lo menos 6 meses.</li>
          </ul>

          <b class="spanish hidden">Describe cargos de tarjetas de crédito.</b>
          <ul>
            <li class="spanish hidden">¿Cómo puedes saber el recargo de tu tarjeta de crédito?</li>
            <li class="spanish hidden">¿Tienes un recargo “teaser”? O sea, ¿hay recargos que se aplicarán en el futuro?</li>
          </ul>

          <b class="spanish hidden">Introducir las calificacionesde credito y las influye el uso de tarjetas de crédito.</b>
          <ul>
            <li class="spanish hidden">¿Qué es una calificación crediticia?</li>
            <li class="spanish hidden">¿Cómo influyen las tarjetas las calificaciones, positivamente y negativamente?</li>
          </ul>

          <b class="spanish hidden">Temas para discurso adicional y premios</b>
          <ul>
            <li class="spanish hidden">Estudiantes pueden crear una simulación que compara los efectos que tienen las tasas de interés y
              los recargos en el balance de una tarjeta de crédito con el costo total de las mismas compras
              si se paga la cuenta en totalidad mensualmente.</li>
            <li class="spanish hidden">Compara las mejores prácticas para el uso de tarjetas de crédito con errores comunes.</li>
            <li class="spanish hidden">Explica los costos que tienen que pagar los negocias que aceptan tarjetas de crédito.</li>
            <ul>
              <li class="spanish hidden">Comisiones por transacción.</li>
              <li class="spanish hidden">¿Por qué tendría un negocio una compra mínima para aceptar pago con tarjetas de crédito?</li>
              <li class="spanish hidden">¿Por qué hay algunos negocios que no aceptan pago con tarjetas y otros que solamente aceptan
                ciertos tipos de tarjetas?</li>
            </ul>
            <li class="spanish hidden">Pregúntale a la clase si es necesario tener más de una tarjeta de crédito.</li>
          </ul>
        </div>
      </div>


      <!-- INFLATION DAY -->
      <div id="inflationDay" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Inflation Day</h3>
        <h3 class="nineten spanish hidden">Inflation Day</h3>
        <div class="infoBlock">
          <p class="english">Midway through the year, perhaps after the winter break, introduce the students to the concept of inflation
            by raising some of the costs in the classroom economy. Inflation Day is a one-time event that will
            affect the students' rent and bill payments for the remainder of the year. You should also raise
            their salaries, but by an amount slightly lower than the overall cost increase for rent and bills.</p>
          <div class="headerTime english">
            <b>Preparation</b>
            <span>30 minutes</span>
          </div>
          <ul>
            <li class="english">Depending on your students' math skills, you can make inflation a flat amount (e.g., $50) or a percentage
              of all costs (e.g., 3%). Some suggestions:</li>
            <ul>
              <li class="english">Increase salary by $75 per month.</li>
              <li class="english">Increase rent by $100 per month.</li>
              <li class="english">Increase bills by $25 per cycle.</li>
            </ul>
            <li class="english">To prepare for your classroom discussion, consider the talking points listed below.</li>
          </ul>

          <div class="headerTime english">
            <b>In class</b>
            <span>20 minutes</span>
          </div>
          <p class="english">Explain the concept of inflation using these talking points:</p>
          <ul>
            <li class="english">Inflation is a general, continuous increase in the prices of goods and services.</li>
            <li class="english">Inflation reduces the "purchasing power" of money because, with prices higher, you need more dollars
              to buy the same things. In other words, each dollar buys less than it used to.</li>
            <li class="english">This means you can't afford as much as you did before, unless your income rises just as much as the
              inflation rate.
            </li>
          </ul>

          <p class="english">Some specific causes of rising prices:</p>
          <ul>
            <li class="english">The costs of production increase, so suppliers have to raise the prices of the items they sell.
            </li>
            <li class="english">The cost of raw materials increases because of higher demand, supply disruptions, or other causes.
            </li>
            <li class="english">Labor costs rise: Maybe people will not work at a certain job unless they are paid more.</li>
            <li class="english">Supply and demand: If a lot of people want something, such as gold or even a particular brand of
              sneaker, they may bid up the price.</li>
          </ul>

          <p class="english">Inform the students that inflation has hit the clasroom economy, so their monthly rent has increased
            and their bills will be higher from now on.</p>
          <p class="english">Encourage them to use resources to learn more about inflation and how to follow and interpret the Consumer
            Price Index.
          </p>

          <!-- spanish inflation day MISSING?-->
          <p class="spanish hidden">A mediados del año, tal vez después de las vacaciones de invierno, presente a los estudiantes el concepto
            de inflación aumentando algunos de los costos en la economía del aula. El día de la inflación es
            un evento único que afectará el alquiler de los estudiantes y los pagos de facturas durante el resto
            del año. También debe aumentar sus salarios, pero en una cantidad ligeramente inferior al aumento
            general de los costos de alquiler y facturas.</p>
          <div class="headerTime spanish hidden">
            <b>Preparación</b>
            <span>30 minutos</span>
          </div>
          <ul>
            <li class="spanish hidden">Dependiendo de las habilidades matemáticas de sus estudiantes, puede hacer que la inflación sea una
              cantidad fija (por ejemplo, $ 50) o un porcentaje de todos los costos (p. ej., 3%). Algunas sugerencias:
            </li>
            <ul>
              <li class="spanish hidden">Aumentar el salario en $ 75 por mes.</li>
              <li class="spanish hidden"> Aumentar el alquiler en $ 100 por mes.</li>
              <li class="spanish hidden">Aumenta las facturas por $ 25 por ciclo.</li>
            </ul>
            <li class="spanish hidden">Para prepararse para la discusión de su clase, considere los puntos de conversación que se enumeran
              a continuación.
            </li>
          </ul>

          <div class="headerTime spanish hidden">
            <b>En clase</b>
            <span>20 minutos</span>
          </div>
          <p class="spanish hidden">Explique el concepto de inflación usando estos puntos de discusión:</p>
          <ul>
            <li class="spanish hidden">La inflación es un aumento general y continuo de los precios de los bienes y servicios.</li>
            <li class="spanish hidden">La inflación reduce el "poder adquisitivo" del dinero porque, con precios más altos, necesita más
              dólares para comprar las mismas cosas. En otras palabras, cada dólar compra menos de lo que solía.</li>
            <li class="spanish hidden">Esto significa que no puede pagar tanto como antes, a menos que sus ingresos aumenten tanto como
              la tasa de inflación.</li>
          </ul>

          <p class="spanish hidden">Algunas causas específicas del aumento de los precios:</p>
          <ul>
            <li class="spanish hidden">Los costos de producción aumentan, por lo que los proveedores deben aumentar los precios de los artículos
              que venden.</li>
            <li class="spanish hidden">El costo de las materias primas aumenta debido a una mayor demanda, interrupciones en el suministro
              u otras causas.
            </li>
            <li class="spanish hidden">Aumentan los costos laborales: quizás las personas no trabajen en un cierto trabajo a menos que se
              les pague más.
            </li>
            <li class="spanish hidden">Oferta y demanda: si mucha gente quiere algo, como oro o incluso una marca de zapatillas en particular,
              pueden aumentar el precio.</li>
          </ul>

          <p class="spanish hidden">Informe a los estudiantes que la inflación ha afectado a la economía de clase, por lo que su alquiler
            mensual ha aumentado y sus facturas serán más altas a partir de ahora.</p>
          <p class="spanish hidden">Aliéntelos a usar recursos para aprender más sobre la inflación y cómo seguir e interpretar el índice
            de precios al consumidor.
          </p>
        </div>
      </div>


      <!-- YEAR END WRAP UP-->
      <div id="yearEnd" class=" gradesInnerContent hidden">
        <h3 class="nineten english">Year-end wrap-up</h3>
        <h3 class="nineten spanish hidden">Conclusión del año</h3>
        <div class="infoBlock">
          <p class="english">Plan a time near the end of the year for students to reflect on what they have learned in the program
            and for you to tie everything together.</p>
          <p class="english">It is also a time to celebrate the students' successes and reward them for their hard work.</p>

          <div class="headerTime english">
            <b>Certificates</b>
          </div>
          <p class="english">We recommend you give each student a certificate for participating in the program. The certificate will
            reinforce their achievement and could be a talking point for them in a future college application
            or job interview.
          </p>
          <p class="english">Feel free to use our
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/11-12/Achievement_Cert_11-12.pdf')">certificate template</a>.</p>

          <div class="headerTime english">
            <b>Class rewards</b>
          </div>
          <p class="english">Allow students to pool their classroom money and purchase a class reward, such as:</p>
          <ul>
            <li class="english">Holding a class party.</li>
            <li class="english">Watching a movie in class.</li>
            <li class="english">Holding a class outdoors.</li>
          </ul>

          <div class="headerTime english">
            <b>Debriefing session</b>
          </div>
          <p class="english">In addition to rewarding the students, the year-end wrap-up is an excellent opportunity for students
            to discuss what they have learned from their experiences. Conducting a debriefing session is a great
            way to accomplish that goal—and also helps reinforce what they have learned.
          </p>
          <p class="english">Here are some questions you can ask to guide the discussion:</p>
          <ul>
            <li class="english">What did you learn from the classroom economy this year?</li>
            <li class="english">Why do you think those lessons were important?</li>
            <li class="english">What would you do differently if you could?</li>
            <li class="english">What was your favorite part of the classroom economy?</li>
          </ul>

          <div class="headerTime english">
            <b>Investment accounts</b>
          </div>
          <p class="english">Before the final Auction Day, allow the students to make a withdrawal from their investment accounts.
            You can also take this opportunity to offer a class reward to celebrate any investment successes
            your students have had.</p>
          <p class="english">However, you should point out that because the investment simulator randomly selects years, it is possible
            that some students experienced extraordinary gains throughout the year while others experienced losses.
            To illustrate the effect extreme market conditions can have on an investment, consider the following
            options:
          </p>
          <ul>
            <li class="english">Compare the students' actual results to historical averages.</li>
            <li class="english">Rerun an entire simulation (or several) in front of the class, by advancing the timeline.</li>
          </ul>

          <!-- spanish end of year wrap up -->
          <p class="spanish hidden">A finales del año, planea una conclusión del programa para que los estudiantes puedan reflexionar sobre
            lo que aprendieron.</p>
          <p class="spanish hidden">Ésta es una oportunidad de celebrar los éxitos de los estudiantes y de premiarlos por su trabajo.
          </p>

          <div class="headerTime spanish hidden">
            <b>Certificados</b>
          </div>
          <p class="spanish hidden">Recomendamos que le des un certificado a cada estudiante por participar en el programa. Esto reforzará
            sus logros, y podrían mencionarlo en el futuro en aplicaciones para la universidad o trabajos.</p>
          <p class="spanish hidden">No dudes en utilizar nuestro
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/11-12/Achievement_Cert_11-12_ES.pdf')">modelo de certificado</a>.</p>

          <div class="headerTime spanish hidden">
            <b>Premios para la clase</b>
          </div>
          <p class="spanish hidden">Puedes permitir que los estudiantes agreguen su dinero para comprar un premio para la clase, por ejemplo:
          </p>
          <ul>
            <li class="spanish hidden">Una fiesta.</li>
            <li class="spanish hidden">Día de cine.</li>
            <li class="spanish hidden">Dar clase afuera.</li>
          </ul>

          <div class="headerTime spanish hidden">
            <b>Debate de seguimiento</b>
          </div>
          <p class="spanish hidden">Además de premiar los estudiantes, el debate de seguimiento es una buena oportunidad para discutir sobre
            sus experiencias y reforzará lo que aprendieron durante el año.
          </p>
          <p class="spanish hidden">Puedes preguntarles lo siguiente para guiar la discusión:</p>
          <ul>
            <li class="spanish hidden">¿Qué aprendiste sobre el dinero este año?</li>
            <li class="spanish hidden">¿Qué harás de manera diferente el año que viene? (por ejemplo, ¿qué puedes hacer para ahorrar a nivel
              supremo?)
            </li>
            <li class="spanish hidden">¿Por qué crees que es importante aprender sobre la economía?</li>
            <li class="spanish hidden">¿Cuál fue la parte del classroom economy que te gustó más?</li>
          </ul>

          <div class="headerTime spanish hidden">
            <b>Cuentas de inversión</b>
          </div>
          <p class="spanish hidden">Antes del último día de subasta, permite que los estudiantes saquen dinero de sus cuentas de inversión.
            También puedes celebrar los éxitos de inversión que tuvieron.</p>
          <p class="spanish hidden">Sin embargo, debes mencionar que el simulador de inversiones es aleatorio y por eso es posible que algunos
            estudiantes tuvieran ganancias extraordinarios mientas otros tuvieron pérdidas. Para ilustrar como
            condiciones extremas del mercado pueden afectar a inversiones, considera las siguientes opciones:
          </p>
          <ul>
            <li class="spanish hidden">Compara las ganancias/perdidas de los estudiantes con medias históricas.</li>
            <li class="spanish hidden">Repite simulaciones con la clase, para avanzar el calendario.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<grades-footer></grades-footer>