import { external } from './environment.common'
import {
  OTEL_EXPORTER_OTLP_TRACES_ENDPOINT,
  production,
} from './environment.production'

export const environment = {
  ...production,
  ...external,
  OTEL_EXPORTER_OTLP_TRACES_ENDPOINT,
}
