import { Component } from "@angular/core";

@Component({
    selector: 'app-webinar-sign-up',
    templateUrl: './webinar-sign-up.component.html',
    styleUrls: ['./webinar-sign-up.component.scss']
})
export class WebinarSignUpComponent {
    sessions = [
        {
            date: "Monday, 10/14 6pm ET",
            link: "https://events.teams.microsoft.com/event/74f77262-e79a-40d1-9c24-6f74ceb2b870@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Wenesday, 10/23 6pm ET",
            link: "https://events.teams.microsoft.com/event/0af26724-90c7-4b96-8981-7c081449f37c@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Tuesday, 10/29 8pm ET",
            link: "https://events.teams.microsoft.com/event/6300b88f-e167-4c87-9f62-691ceedaadc6@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        }
    ];

    constructor() {}

}
