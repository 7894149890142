import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { BannerVariant } from '@vg-constellation/angular-15/banner';
import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-custom-toastr',
  templateUrl: './custom-toastr.component.html',
  styleUrls: ['./custom-toastr.component.scss'],
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 1,
        })
      ),
      transition(
        'inactive <=> active',
        animate(
          '500ms ease-out',
          keyframes([
            style({
              transform: 'translateX(340px)',
              offset: 0,
              opacity: 0,
            }),
            style({
              offset: 0.7,
              opacity: 1,
              transform: 'translateX(-20px)',
            }),
            style({
              offset: 1,
              transform: 'translateX(0)',
            }),
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '500ms ease-in',
          keyframes([
            style({
              transform: 'translateX(-20px)',
              opacity: 1,
              offset: 0.2,
            }),
            style({
              opacity: 0,
              transform: 'translateX(340px)',
              offset: 1,
            }),
          ])
        )
      ),
    ]),
  ],
  preserveWhitespaces: false,
})
export class CustomToastrComponent extends Toast {
  constructor(
    public override toastrService: ToastrService,
    public override toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }
  erroType: BannerVariant;

  ngOnInit() {
    this.erroType = this.options.toastClass as BannerVariant;
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
