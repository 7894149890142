import { Component, ViewEncapsulation } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'grades9-10',
  templateUrl: './grades9-10.component.html',
  styleUrls: ['./grades9-10.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Grades910Component {
  constructor(
    public modalService: ModalService
  ) { }
}
