import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { 
    emailRegex, 
    maxFullNameLength, 
    maxEmailLength, 
    maxMessageLength } from '../shared/utilities/validators.utility';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    contactUsForm: FormGroup;
    isSubmitted = false;
    isSending = false;
    emailSent = false;
    reasons: string[] = [
        "Digital Experience",
        "Paper Experience",
        "General"
    ];
    subjectsList = [
        {
            labelText: "Current experience",
            id: 0
        },
        {
            labelText: "Technical issues / Troubleshooting",
            id: 1
        },
        {
            labelText: "Ideas for future enhancement",
            id: 2
        },
        {
            labelText: "General",
            id: 3
        }
    ];

    messageCharCount = 0;
    readonly maxFullNameLength = maxFullNameLength;
    readonly maxEmailLength = maxEmailLength;
    readonly maxMessageLength = maxMessageLength;

    constructor(
        private readonly fb: FormBuilder,
        private readonly http: HttpClient,
        private readonly toast: ToastrService,
    ) {}

    ngOnInit() {
      this.initForm();

      this.contactUsForm.get('message').valueChanges.subscribe((value: string) => {
        this.messageCharCount = value.length;
      });
    }

    initForm() {
        this.contactUsForm = this.fb.group(
            {
                name: [
                    '',
                    Validators.compose([
                        Validators.required
                    ])
                ],
                email: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.pattern(emailRegex)
                    ]),
                ],
                reason: [
                    '',
                    Validators.compose([
                        Validators.required
                    ])
                ],
                subject: [
                    '',
                    Validators.compose([
                        Validators.required
                    ])
                ],
                message: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(this.maxMessageLength)
                    ])
                ],
                captchaResponse: [
                    '',
                    Validators.compose([
                        Validators.required
                    ])
                ]
            }
        );
    }

    public showToastrMsg(
        header: string,
        description: string,
        type: string
    ) {
        return this.toast.show(description, header, { toastClass: type });
    }

    submitContactUs(name: string, email: string, reason: string, subject: string, body: string, captchaResponse: string) {
        return this.http.post<any>(
            '/submitContactUs',
            {
                name,
                email,
                reason,
                subject,
                body,
                captchaResponse
            });
    }
  
    onSubmit() {
        this.isSubmitted = this.contactUsForm.invalid;
        if (!this.contactUsForm.invalid) {
            this.isSending = true;
            this.submitContactUs(
                this.contactUsForm.get('name').value,
                this.contactUsForm.get('email').value, 
                this.reasons[this.contactUsForm.get('reason').value],
                this.subjectsList[this.contactUsForm.get('subject').value].labelText, 
                this.contactUsForm.get('message').value,
                this.contactUsForm.get('captchaResponse').value)
                .subscribe({
                    next: () => {
                        this.emailSent = true;
                        this.isSending = false;
                    },
                    error: error => {
                        console.log(error)
                        this.showToastrMsg('Request Failed', 'Please try again later. If the issue persists, please contact us at support@myclassroomeconomy.org', 'error');
                        this.isSending = false;
                    }
                });
        }
    }
}
