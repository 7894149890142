import { Component } from '@angular/core';

@Component({
  selector: 'grades-footer',
  templateUrl: './grades-footer.component.html',
  styleUrls: ['./grades-footer.component.scss']
})
export class GradesFooterComponent {

}
