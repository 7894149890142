import { Component, ViewEncapsulation } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'grades45',
  templateUrl: './grades4-5.component.html',
  styleUrls: ['./grades4-5.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Grades45Component {
  constructor(
    public modalService: ModalService
  ) { }
}
