<!--
STYLING NEEDED FOR SIMULATOR DROPDOWN MENU

<style>
  .simulator{background-color: #31B5D3;display: block;position: relative;width:50%;margin: 0 auto;height: 50px;vertical-align: middle;padding:12px 0; line-height:16px;}
  #order.simulator{width:25%; margin:24px auto;}
  .simulator:hover{background-color: #61797b; cursor:pointer;}
  .simulator a{color:#fff;text-align: center;position: relative;margin: 0 auto; display:block;}
  .orderConfirm {margin-left: 375px; margin-top: 50px; width:50%;}
</style>
-->

<!-- Carousel Section -->
<div class="container carousel_container">
    <div class="col-sm-3 callout_container english">
        <h1>Bring financial lessons to life for students of any age &mdash; for free.</h1>
        <div>
            <!-- <a href="/overview">
                <span class="getStartedLink">Get started &#10095;</span>
            </a> -->
        </div>
    </div>
    <div class="col-sm-3 callout_container spanish hidden">
        <h1>Da vida a lecciones financieras para estudiantes de cualquier edad &mdash; y de forma gratuita.</h1>
        <div>
            <a href="/overview">
                <span class="getStartedLink">Empezamos &#10095;</span>
            </a>
        </div>
    </div>
    <div class="col-sm-9" style="display: flex; flex-direction: column">

        <div id="myCarousel" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">

                <div class="item active">
                    <div class="container homeslide">
                        <a href="https://digital.myclassroomeconomy.org/register">
                            <img src="/assets/images/slide0-2023.png">
                        </a>
                    </div>
                </div>

                <div class="item">
                    <div class="container homeslide">
                        <a href="/webinar" target="_self">
                            <img src="assets/images/webinar_banner.png">
                        </a>
                    </div>
                </div>

                <div class="item">
                    <div class="container homeslide">
                        <a href="/one-million">
                            <img src="assets/images/1_million_Banner.png">
                        </a>
                    </div>
                </div>

                <div class="item">
                    <div class="container homeslide">
                        <a href="https://www.facebook.com/groups/myclassroomeconomy/" target="_blank">
                            <div class="slide1 english">
                                <h1>Now on Facebook!</h1>
                                <h5>Connect with other people using the program.</h5>
                                <h5>Join the conversation today <strong>></strong></h5>
                            </div>
                            <div class="slide1 spanish hidden">
                                <h1>Ahora en Facebook!</h1>
                                <h5>Conéctese con otras personas que usen el programa.</h5>
                                <h5>Unase a la conversación de hoy <strong>></strong></h5>
                            </div>
                            <img src="assets/images/slide1_2019.png">
                        </a>
                    </div>
                </div>


                <div class="item">
                    <div class="container homeslide">
                        <a href="/testimonials">
                            <div class="slide2 english">
                                <h1>University of Wisconsin study proves effectiveness of MCE.</h1>
                                <h5>See the results <strong>></strong></h5>
                            </div>
                            <div class="slide2 spanish hidden">
                                <h1>El estudio de la Universidad de Wisconsin prueba la eficiencia de MCE.</h1>
                                <h5>Mira los resultados <strong>></strong></h5>
                            </div>
                            <img src="assets/images/slide2_2019.jpg">
                        </a>
                    </div>
                </div>


                <div class="item">
                    <div class="container homeslide">
                        <div class="slide3 english">
                            <h1>Materials are clear, easy to use, and free.</h1>
                        </div>
                        <div class="slide3 spanish hidden">
                            <h1>Los materiales son claros, fáciles de usar y gratuitos.</h1>
                        </div>
                        <img src="assets/images/slide3_2019.jpg">
                    </div>
                </div>

                <!-- <div class="item">
          <div class="container homeslide">
            <div class="slide4 english">
              <h1>Any teacher, in any subject can use it.</h1>
            </div>
            <div class="slide4 spanish hidden">
              <h1>Cualquier maestro, en cualquier materia puede usarlo.</h1>
            </div>
            <img src="assets/images/slide4_2019.jpg">
          </div>
        </div> -->
                <div class="item">
                    <div class="container homeslide">
                        <a href="https://home.myclassroomeconomy.org/">
                            <div class="slide4 english" style="color:black;">
                                <h1>Make education at home fun with My Home Economy!</h1>
                                <h5>Start your home economy today <strong>></strong></h5>
                            </div>
                            <div class="slide4 spanish hidden" style="color:black;">
                                <h1>Cualquier maestro, en cualquier materia puede usarlo.</h1>
                            </div>
                            <img src="assets/images/MyCE_MyHE_Promo.jpg">
                        </a>
                    </div>
                </div>
                <a class="carousel-control left" href="#myCarousel" data-slide="prev">
                    <div style="height: 100%; padding-top: 100%; margin: 0 auto; text-align: center; position: relative; vertical-align: middle; width: 15%; font-size: 40px;" class="carousel-next-pointer">&lsaquo;</div>
                </a>
                <a class="carousel-control right" href="#myCarousel" data-slide="next">
                    <div style="height: 100%; padding-top: 100%; margin: 0 auto; text-align: center; position: relative; vertical-align: middle; width: 15%; font-size: 40px;" class="carousel-next-pointer">&rsaquo;</div>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End of carousel -->


<!--Welcome Section -->
<div class="container marketing main">
    <div class="col-md-11 col-sm-12 col-xs-12 welcomeCopy">
        <h1 class="welcome_header english"><strong>What is My Classroom Economy?</strong></h1>
        <h1 class="welcome_header spanish hidden"><strong>Que es My Classroom Economy?</strong></h1>
        <!--<h2 class="welcome_header">Healthy financial habits can begin at any age and last a lifetime.</h2>-->
        <div class="“welcome_message english">
            <p>My Classroom Economy is a program that enables any educator to teach children financial responsibility through fun, experiential learning.</p>
            <p>It's a simple classroom economic system based on the idea that students need to earn school "dollars" so that they can rent their own desks. By bringing real-world scenarios into the classroom, students see the impact of their decisions to
                save, spend, and budget.</p>
            <p>Everything you need to build a classroom economy is available on this site &mdash; for free. Thanks for visiting, and enjoy the program.</p>
        </div>
        <div class="“welcome_message spanish hidden">
            <p>My Classroom Economy es un programa que le ofrece a cualquier profesor la posibilidad de enseñar la responsabilidad financiera a los niños a través del aprendizaje realístico y divertido.</p>
            <p>Es un sistema simple sobre la economía de la clase basándose en la idea que los estudiantes necesitan ganar “dólares” de la clase para que puedan alquilar sus propios escritorios. Al llevar los escenarios del mundo real a la clase, los estudiantes
                ven el impacto de sus decisiones en ahorrar, gastar, y presupuestar.</p>
            <p>Todo lo que necesita para construir la economía de la clase está disponible en esta página de web, de forma gratuita. Gracias por visitar, disfruten del programa.</p>
        </div>
    </div>
    <!--<div class="col-md-3">
  	<img src="assets/images/ruler.jpg"  alt="Rulers" style="margin-top:30px;" class="ruler img-responsive">
  </div>-->

    <hr>

    <div class="col-md-12 col-sm-12 col-xs-12" style="margin:0 auto;">

        <div class="col-md-4 col-sm-12 col-xs-12 homepageCol">
            <div class="col-md-12">
                <div class="homepageImg"><img src="assets/images/coinsIcon.svg" alt="Glasses icon" class="hidden-xs"></div>
            </div>
            <div class="col-md-12">
                <h2 class="caption_headings english">What does financial responsibility look like?</h2>
                <h2 class="caption_headings spanish hidden">Que aspecto tiene la responsabilidad financiera?</h2>
            </div>
            <div class="col-md-12 english">
                <p>It’s the pride of earning a paycheck, the satisfaction of sticking to a budget, and the ability to save for a distant goal. And it can start as early as kindergarten.<br><a href="/overview" class="learnMore"><span
              class="learnMoreLink">Start at the beginning &#10095;</span></a></p>
            </div>
            <div class="col-md-12 spanish hidden">
                <p>Es el orgullo de ganar un sueldo, la satisfacción de seguir con un presupuesto, y la habilidad de ahorrar para una meta en el futuro. Esta responsabilidad se puede comenzar tan temprano como en el kínder.<br>
                    <a href="/overview" class="learnMore"><span class="learnMoreLink">Comience al principio
              &#10095;</span></a>
                </p>
            </div>
        </div>


        <div class="col-md-4 col-sm-12 col-xs-12 homepageCol">
            <div class="col-md-12">
                <div class="homepageImg"><img src="assets/images/papersIcongreen.svg" alt="Papers icon" class="hidden-xs"></div>
            </div>
            <div class="col-md-12">
                <h2 class="caption_headings english">How do I get started?</h2>
                <h2 class="caption_headings spanish hidden">Cómo puedo empezar?</h2>
            </div>
            <div class="col-md-12 english">
                <p class="overflow_text">My Classroom Economy can be incorporated into any curriculum, in any subject, and at any grade level. The program is designed to overlay&mdash;not interrupt&mdash;a classroom curriculum.<br>
                    <a href="/materials" class="learnMore"><span class="learnMoreLink"> Get materials &#10095;</span></a>
                </p>
            </div>
            <div class="col-md-12 spanish hidden">
                <p class="overflow_text">My Classroom Economy puede ser incorporado en cualquier currículo, en cualquier materia, y en cualquier nivel de grado. El programa está diseñado para ser incorporado -no interrumpir- en el currículo de clase.<br><a href="/materials"
                        class="learnMore"><span class="learnMoreLink"> Obtenga los
              materiales &#10095;</span></a></p>
            </div>
        </div>


        <div class="col-md-4 col-sm-12 col-xs-12 homepageCol">
            <div class="col-md-12">
                <div class="homepageImg"><img src="assets/images/talkbubblesIcon.svg" alt="Coffee icon" class="hidden-xs"></div>
            </div>
            <div class="col-md-12">
                <h2 class="caption_headings english">Is My Classroom Economy right for my students?</h2>
                <h2 class="caption_headings spanish hidden">Sera My Classroom Economy adecuado para mis estudiantes?</h2>
            </div>
            <div class="col-md-12 english">
                <p>My Classroom Economy is a program that enables any educator to teach children financial responsibility through fun, experiential learning.</p>
                <p>My Classroom Economy was created by teachers for teachers and is meant to be adapted to your students’ needs. See what other teachers have to say about My Classroom Economy.<br><a href="/testimonials" class="learnMore"><span class="learnMoreLink"> Read testimonials &#10095;</span></a></p>
            </div>
            <div class="col-md-12 spanish hidden">
                <p>My Classroom Economy es un programa que permite a cualquier educador enseñar la responsabilidad financiera a estudiantes a través del divertido aprendizaje experiencial.</p>
                <p>My Classroom Economy fue creado por profesores para profesores y está diseñado para adaptarse a las necesidades de sus estudiantes. Vea lo que otros profesores tienen que decir acerca de My Classroom Economy.
                    <br><a href="/testimonials" class="learnMore"><span class="learnMoreLink"> Lea los testimonios
              &#10095;</span></a></p>
            </div>
        </div>

    </div>




    <!-- End of welcome section -->

    <div style="clear:both;"></div>
</div>
<grades-footer></grades-footer>