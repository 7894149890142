import { Component } from '@angular/core';

@Component({
  selector: 'global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss']
})
export class GlobalHeaderComponent {

}
