import { Component, ViewEncapsulation } from '@angular/core'
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'gradesk-1',
  templateUrl: './grades-k-1.component.html',
  styleUrls: ['./grades-k-1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GradesK1Component {
  constructor(
    public modalService: ModalService
  ) { }
}
