import { Component } from '@angular/core';

@Component({
  selector: 'one-million',
  templateUrl: './one-million.component.html',
  styleUrls: ['./one-million.component.scss']
})
export class OneMillionComponent {

}
