<div class="container">
  <div class="grades_container">
    <hr>
    <div style="clear:both;">
      <!--              TODO: refactor grades section out so only have to change in one spot-->
      <div class="school_container">
        <h3 class="school_text english">Elementary School</h3>
        <h3 class="school_text spanish hidden">Escuela Primaria</h3>
        <a href="/gradesk-1">
          <div class="circle green"><p>K - 1</p></div>
        </a>
        <a href="/grades2-3">
          <div class="circle green"><p>2 - 3</p></div>
        </a>
        <a href="/grades4-5">
          <div class="circle green"><p>4 - 5</p></div>
        </a>
      </div>

      <div class="school_container">
        <h3 class="school_text english">Middle School</h3>
        <h3 class="school_text spanish hidden">Secundaria</h3>
        <a href="/grade6">
          <div class="circle orange"><p>6</p></div>
        </a>
        <a href="/grades7-8">
          <div class="circle orange"><p>7 - 8</p></div>
        </a>
      </div>

      <div class="school_container">
        <h3 class="school_text english">High School</h3>
        <h3 class="school_text spanish hidden">Bachillerato</h3>
        <a href="/grades9-10">
          <div class="circle blue"><p>9 - 10</p></div>
        </a>
        <a href="/grades11-12">
          <div class="circle blue"><p>11 - 12</p></div>
        </a>
      </div>
    </div>
  </div>
</div>