import { Injectable } from '@angular/core'

export const ADOBE_SUBPAGE_VIEWS = {
  modal_download_material_open: 'Download Materials Form: Open',
  modal_download_material_complete: 'Download Materials Form: Complete',
  modal_download_material_cancel: 'Download Materials Form: Cancel'
};

export enum Analytics {
  PAGE_TEMPLATE_LGN = 'login',
}

interface AdobeLaunch {
  initializePage(page: any, environment: string): void
}

interface DataLayerEvent {
  [key: string]: any
  event: string
}

declare global {
  interface Window {
    adobeLaunch: AdobeLaunch
    dataLayer: DataLayerEvent[]
  }
}

export enum AdobeLaunchEvent {
  SUBPAGE_VIEWED = 'subPageViewed',
  CONTENT_DOWNLOADED = 'contentDownloaded'
};

@Injectable({
  providedIn: 'root',
})
export class AdobeLaunchService {

  constructor(
  ) {}

  /**
   * Used to reset the data layer by removing all previous events
   */
  public resetDataLayer() {
    if (window.dataLayer) {
      window.dataLayer.length = 0
    }
  }

  private subPageViewed(subPageViewedDetails: {
    subPageID: string
    spClicked: string
  }) {
    //Used to signify that a subPage was activated/viewed
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: AdobeLaunchEvent.SUBPAGE_VIEWED,//e166
      subPage: {
        subPageID: subPageViewedDetails.subPageID, //v105
        spClicked: subPageViewedDetails.spClicked  
      }
    });
  }

  public contentDownloaded(fileDetails: {
    fileName: string
    fileType: string
    fileURL: string
  }) {
      //Use to track the download of content
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: AdobeLaunchEvent.CONTENT_DOWNLOADED, //e24
        linkInfo: {
          fileName: fileDetails.fileName, //v70
          fileType: fileDetails.fileType,
          fileURL: fileDetails.fileURL, //v63
      }
    });
  }

  public trackSubPage(pageIndex: string) {
    let subPageID: string ='';
    let spClicked: string ='true';

    switch(pageIndex) {
      //Download Materials Form: Open
      case 'modal_download_material_open':
        subPageID = ADOBE_SUBPAGE_VIEWS.modal_download_material_open;
        break;

      //Download Materials Form: Complete
      case 'modal_download_material_complete':
        subPageID = ADOBE_SUBPAGE_VIEWS.modal_download_material_complete;
        break;

      //Download Materials Form: Cancel
      case 'modal_download_material_cancel':
        subPageID = ADOBE_SUBPAGE_VIEWS.modal_download_material_cancel;
        break;
    }

    this.subPageViewed({
      subPageID: subPageID,
      spClicked: spClicked
    })

  }


}
