<div class="parallax">
    
    <div class="gradesHeaderImg container">
      <div class="heroContent english">
        <h1>My Classroom Economy overview</h1>
      </div>
      <div class="heroContent spanish hidden">
        <h1>Descripción de My Classroom Economy</h1>
      </div>
    </div>
    <section class="backgroundImages" style="top: 0px;">
      <div class="centeredDiv zoom-images">
      <div class="heroOverlay" data-start="background:rgba(0, 0, 0, 0)" data-600-start="background:rgba(0, 0, 0, 0.5)" anchor-target="body"></div>
        <div id="overviewHeader" class="backgroundImage" data-start="transform: scale(1)" data-600-start="transform: scale(1.3)" anchor-target="body"></div>
      </div>
    </section>
      
  <div class="container main" id="skrollr-body">
    <div class="col-md-11 english">
      <h2 class="caption_headings">My Classroom Economy can work for any grade level.</h2>
      <p>Tailored to the learning ability of your students, the program has the same overall objectives for
        all grades—to instill basic financial responsibility and teach the value of delayed gratification.</p>
      <p><strong>The core principle is simple:</strong> Students earn and spend money in a simulated microeconomy. As the
        grade levels increase, we incorporate additional financial concepts. For example, students in all
        grades pay rent for their desks, but students in middle school also pay an electricity bill as well
        as taxes. For high school students, we include experiential learning activities targeted at
        teaching the foundations of investing and purchasing insurance.</p>
      <p>The program is carefully designed to overlay—not interrupt—your curriculum. Therefore, it can
        be implemented by any teacher, in any subject matter. And don't forget, materials are free and
        customized by grade level to allow broad access to the program.</p>
    </div>
    <div class="col-md-11 spanish hidden">
        <h2 class="caption_headings">My Classroom Economy puede funcionar para cualquier nivel de grado.</h2>
        <p>Adaptado a la capacidad de aprendizaje de sus alumnos, el programa tiene los mismos objetivos generales para todos los grados: inculcar la responsabilidad financiera básica y enseñar el valor de la gratificación.</p>
        <p><strong>El objetivo central es simple:</strong> Los estudiantes ganan y gastan dinero en una microeconomía simulada. A medida que aumentan los niveles de grado, incorporamos conceptos financieros adicionales. Por ejemplo, los estudiantes de todos los grados pagan el alquiler de sus escritorios, pero los estudiantes de la escuela intermedia también pagan una factura de electricidad e impuestos. Para los estudiantes de secundaria, incluimos actividades de aprendizaje experimental dirigidas a enseñar los fundamentos de la inversión y la compra de seguros.</p>
        <p>El programa está cuidadosamente diseñado para complementar, no interrumpir, su plan de estudios. Por lo tanto, puede ser implementado por cualquier profesor, en cualquier materia. Y no lo olvide, los materiales son gratuitos y personalizados por nivel de grado para permitir un amplio acceso al programa.</p>
      </div>
    <div class="col-md-12">
      <div class="tabs vid_player">
        <ul class="nav nav-tabs english">
          <li class="active"><a href="#tab_a" data-toggle="tab">Intro</a></li>
          <li><a href="#tab_b" data-toggle="tab">How it works</a></li>
          <li><a href="#tab_c" data-toggle="tab">Bonuses</a></li>
          <li><a href="#tab_d" data-toggle="tab">Rent and fines</a></li>
          <li><a href="#tab_e" data-toggle="tab">Bills and taxes</a></li>
          <li><a href="#tab_f" data-toggle="tab">Auctions</a></li>
          <li><a href="#tab_g" data-toggle="tab">Insurance</a></li>
          <li><a href="#tab_h" data-toggle="tab">Investing</a></li>
        </ul>
        <ul class="nav nav-tabs spanish hidden">
            <li class="active"><a href="#tab_a" data-toggle="tab">Introducción</a></li>
            <li><a href="#tab_b" data-toggle="tab">Como funciona</a></li>
            <li><a href="#tab_c" data-toggle="tab">Bonos</a></li>
            <li><a href="#tab_d" data-toggle="tab">Renta y Multas</a></li>
            <li><a href="#tab_e" data-toggle="tab">Facturas e impuestos</a></li>
            <li><a href="#tab_f" data-toggle="tab">Subastas</a></li>
            <li><a href="#tab_g" data-toggle="tab">Seguros</a></li>
            <li><a href="#tab_h" data-toggle="tab">Inversiones</a></li>
          </ul>
        <div class="tab-content">
          <div class="tab-pane active" id="tab_a">
            <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_MainVideo.mp4" type='video/mp4'></video>
          </div>
          <div class="tab-pane" id="tab_b">
            <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_HowItWorks.mp4" id="tab2"></video>
          </div>
          <div class="tab-pane" id="tab_c">
            <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Bonuses.mp4" id="tab3"></video>
          </div>
          <div class="tab-pane" id="tab_d">
            <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_RentFines.mp4" id="tab4"></video>
          </div>
          <div class="tab-pane" id="tab_e">
            <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_BillsTaxes.mp4" id="tab4"></video>
          </div>
          <div class="tab-pane" id="tab_f">
            <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Auctions.mp4" id="tab4"></video>
          </div>
          <div class="tab-pane" id="tab_g">
            <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Insurance.mp4" id="tab4"></video>
          </div>
          <div class="tab-pane" id="tab_h">
            <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Investing.mp4" id="tab4"></video>
          </div>
        </div>
      </div>
    </div>
    
    <div class="small_vid_player">
    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
    <div class="panel panel-default">
      <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          <div class="panel-heading english" role="tab" id="headingOne">
                <h4 class="panel-title">Introduction</h4>
        </div>
        <div class="panel-heading spanish hidden" role="tab" id="headingOne">
                <h4 class="panel-title">Introducción</h4>
          </div>
      </a>
      <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
        <div class="panel-body">
               <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_MainVideo.mp4" type='video/mp4'></video>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          <div class="panel-heading english" role="tab" id="headingTwo">
                <h4 class="panel-title">How it works</h4>
        </div>
        <div class="panel-heading spanish hidden" role="tab" id="headingTwo">
                <h4 class="panel-title">Como funciona</h4>
          </div>
      </a>
      <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
        <div class="panel-body">
          <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_HowItWorks.mp4" type='video/mp4'></video>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          <div class="panel-heading english" role="tab" id="headingThree">
            <h4 class="panel-title">Bonuses</h4>
          </div>
          <div class="panel-heading spanish hidden" role="tab" id="headingThree">
              <h4 class="panel-title">Bonos</h4>
          </div>
      </a>
      <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
        <div class="panel-body">
              <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Bonuses.mp4" type='video/mp4'></video>
        </div>
      </div>
    </div>
     <div class="panel panel-default">
     <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          <div class="panel-heading english" role="tab" id="headingFive">
            <h4 class="panel-title">Rent and fines</h4>
          </div>
          <div class="panel-heading spanish hidden" role="tab" id="headingFive">
              <h4 class="panel-title">Renta y Multas</h4>
          </div>
      </a>
      <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
        <div class="panel-body">
          <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_RentFines.mp4" type='video/mp4'></video>
        </div>
      </div>
    </div>
     <div class="panel panel-default">
     <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          <div class="panel-heading english" role="tab" id="headingFive">
            <h4 class="panel-title">Bills and taxes</h4>
          </div>
          <div class="panel-heading spanish hidden" role="tab" id="headingFive">
              <h4 class="panel-title">Facturas e impuestos</h4>
          </div>
      </a>
      <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
        <div class="panel-body">
          <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_BillsTaxes.mp4" type='video/mp4'></video>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
          <div class="panel-heading english" role="tab" id="headingSix">
            <h4 class="panel-title">Auctions</h4>
          </div>
          <div class="panel-heading spanish hidden" role="tab" id="headingSix">
              <h4 class="panel-title">Subastas</h4>
          </div>
      </a>
      <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
        <div class="panel-body">
          <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Auctions.mp4" type='video/mp4'></video>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
      <div class="panel-heading english" role="tab" id="headingSeven">
        <h4 class="panel-title">Insurance</h4>
      </div>
      <div class="panel-heading spanish hidden" role="tab" id="headingSeven">
        <h4 class="panel-title">Seguros</h4>
      </div>
     </a>
      <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
        <div class="panel-body">
          <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Investing.mp4" type='video/mp4'></video>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
      <div class="panel-heading english" role="tab" id="headingEight">
        <h4 class="panel-title">Investing</h4>
      </div>
      <div class="panel-heading spanish hidden" role="tab" id="headingEight">
          <h4 class="panel-title">Inversiones</h4>
      </div>
    </a>
      <div id="collapseEight" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
        <div class="panel-body">
          <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Insurance.mp4" type='video/mp4'></video>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
  </div>
<grades-footer></grades-footer>