import { Transform } from 'class-transformer';
import { IsNotEmpty, Matches, MaxLength } from 'class-validator';
import sanitizeHtml from 'sanitize-html';

export const emailRegex = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$');
export const nameRegex = new RegExp('^[a-zA-Z0-9 \'-.&_,]+$');

export const zipCanadaRegex = '^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]\\s?[0-9][A-Z][0-9]$';

export const maxFirstNameLength = 20;
export const maxLastNameLength = 35;
export const maxEmailLength = 254;
export const maxSchoolNameLength = 100;
export const maxZipCodeLength = 12;

export const maxFullNameLength = 50;
export const maxReasonLength = 20;
export const maxSubjectLength = 50;
export const maxMessageLength = 500;

function sanitizeInput(value: string): string {
  return sanitizeHtml(value, {
    allowedTags: [],
    allowedAttributes: {},
  }).replaceAll('\'', '&#39;').replaceAll('\"', '&#34;').trim();
}

export class ContactUsDto {
  @IsNotEmpty()
  @MaxLength(maxFullNameLength)
  @Transform(({ value }) => sanitizeInput(value))
  name: string;

  @IsNotEmpty()
  @MaxLength(maxEmailLength)
  @Matches(emailRegex, { message: 'Invalid email address' })
  @Transform(({ value }) => sanitizeInput(value))
  email: string;

  @IsNotEmpty()
  @MaxLength(maxReasonLength)
  @Transform(({ value }) => sanitizeInput(value))
  reason: string;

  @IsNotEmpty()
  @MaxLength(maxSubjectLength)
  @Transform(({ value }) => sanitizeInput(value))
  subject: string;

  @IsNotEmpty()
  @MaxLength(maxMessageLength)
  @Transform(({ value }) => sanitizeInput(value))
  body: string;

  @IsNotEmpty()
  @Transform(({ value }) => sanitizeInput(value))
  captchaResponse: string;
}
