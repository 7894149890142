<div class="container main" id="skrollr-body">
  <div class="col-md-12 col-sm-12 col-xs-12 ">
    <h2 class="about">Notice of Privacy Practices</h2>
    <h3><strong>Introduction and Notice scope</strong></h3>
    <p>The Vanguard Group (“Vanguard”), sponsor of the My Classroom Economy program, is committed to maintaining the
      privacy of your personal information.</p>
    <p>My Classroom Economy, <a href="/home">https://myclassroomeconomy.org</a>
      (the “Site”), is a website owned and operated by Vanguard. This Privacy Notice (“Notice”) solely governs the
      collection, use, disclosure, and transfer of personal information that we collect at the Site. This Privacy Notice
      does not apply to any other website operated by Vanguard.</p>
    <p>By visiting this Site and providing information to Vanguard through this Site, you acknowledge the practices
      described in the Notice. Please also refer to the terms and conditions, which are incorporated herein by
      reference, for additional information related to your use of this Site.</p>

    <h3><strong>Information collection</strong></h3>
    <h4><strong><strong>What personal information do we collect from you directly?</strong></strong></h4>
    <p>Vanguard collects information directly from you when you request order kit materials, register on the site for
      My Classroom Economy updates, or enroll in the My Classroom Economy Digital experience. This information can
      include:</p>
    <ul>
      <li>Your first and last name;</li>
      <li>Your school or personal email address;</li>
      <li>Your school or personal mailing address;</li>
      <li>School name;</li>
      <li>The grade level you teach;</li>
      <li>Your classroom name and grade level;</li>
      <li>Personal details such as race, gender, the type of school you teach at, the length of your career, and your
        level of financial literacy details;
      </li>
      <li>User name and encrypted password; and</li>
      <li>Student first or preferred name (for the My Classroom Economy Digital Experience).</li>
    </ul>

    <h4><strong>What personal information do we collect about Site usage?</strong></h4>
    <p>We also collect information about you automatically as you navigate the Site using cookies, and similar
      mechanisms, as described further below. This information helps us measure traffic to the site and improve
      functionality (so-called website analytics; for further information, see below). The
      information collected can include:</p>
    <ul>
      <li>The date and time you access the Site;</li>
      <li>Site activities such as the pages that you visit or the types and numbers of kits you’ve downloaded;</li>
      <li>If you linked to this Site from another website and the address of that site;</li>
      <li>The type of web browser, computer and operating system used to access this Site; and</li>
      <li>The internet protocol (IP) address from which you access this Site.</li>
    </ul>

    <p><strong>Website Analytics:</strong> We use <a href="https://www.adobe.com/privacy/experience-cloud.html">Adobe
      Analytics</a> to help collect and
      analyze certain information for the purposes discussed above. The information generated about your use of the Site
      may be transmitted to and stored by Adobe Analytics on servers outside the United States. You may opt out of the
      use of cookies by Adobe Analytics <a href="https://www.adobe.com/privacy/opt-out.html">here</a>.</p>

    <p><strong>Cookies:</strong> A cookie is a small file of letters and numbers that is stored on your computer,
      tablet, mobile phone, or other
      device when you visit a website. Cookies contain information that is transferred to or read on your device and
      allow websites to recognize devices and store certain information, such as user preferences. Cookies are used to
      distinguish you from other users of the Site. This helps us to provide you with a useful experience when you
      browse the Site and allows us to improve the Site. We and our third-party service providers use session cookies,
      which link your actions during a particular browser session and expire at the end of that session, as well as
      persistent cookies, which remain on your device and allow us to remember your actions or preferences across
      multiple browser sessions.</p>

    <p><strong>How to refuse and delete cookies:</strong> If you are concerned about having cookies on your computer,
      you can set your browser to refuse all cookies or to
      indicate when a cookie is being set, allowing you to decide whether to accept it. You can also delete cookies from
      your computer. The help feature on most web browsers will tell you how to prevent your browser from accepting new
      cookies, how to receive notice when a new cookie is set, and how to disable cookies altogether. However, if you
      choose to block or delete cookies, certain features of the Site may not operate correctly.</p>
    <p>The cookies used on our Site are categorized as follows:</p>
    <ul>
      <li>Strictly Necessary. Strictly Necessary cookies let you enter and move around the Site and use essential
        features. Without these cookies, services and information you have asked for cannot be provided. We use these
        Strictly Necessary cookies primarily to identify you as having confirmed your eligibility to log in and use the
        Site and to make sure you connect to the right service on the Site when we make any changes to the way the Site
        works. Accepting these cookies is a condition of using the Site, so if you prevent these cookies, we can’t
        guarantee your use of the Site or how the security on the Site will perform during your visit.
      </li>
      <li>Performance Cookies. Performance cookies collect information about how you use our Site, such as which pages
        you visit and any errors you experience. These cookies do not collect any information that could identify you
        and are only used to help us improve how our Site works and understand what interests our users. We use these
        performance cookies primarily for analytics, to provide statistics on how our Site is used.
      </li>
    </ul>

    <h3><strong>Use and disclosure</strong></h3>
    <h4><strong>How do we use personal information?</strong></h4>

    <p>We use personal information that we collect for the purposes described in this Notice, for the purposes for which
      it was provided to us, and for our business purposes, including:</p>
    <ul>
      <li>To provide the information, product, or service you request, or as you may reasonably expect, given the context
        in which we collect the personal information (such as providing My Classroom Economy Digital experience,
        personalization, and preference management; providing updated product and service information; and dispute
        resolution);
      </li>
      <li>For identity and credential management, including identity verification and authentication and system and
        technology administration;
      </li>
      <li>To protect the security and integrity of our systems, networks, applications, and data, including detecting,
        analyzing, and resolving security threats, and collaborating with cybersecurity centers, consortia, and law
        enforcement about imminent threats;
      </li>
      <li>For legal and regulatory compliance, including all uses and disclosures of personal information required by
        law or reasonably needed for compliance with our policies and procedures, such as security and incident response
        programs and intellectual property protection programs;
      </li>
      <li>For corporate audit, analysis, and reporting;</li>
      <li>To enforce our contracts and to protect against injury, theft, legal liability, fraud, or abuse, and to
        protect people or property, including physical security programs;
      </li>
      <li>To deidentify personal information or create aggregated datasets, such as for consolidating reporting,
        research, or analytics;
      </li>
      <li>To keep you informed of new My Classroom Economy offerings;</li>
      <li>To count and recognize visitors to the Site and various Site features and better understand how users access
        and use our Site and Vanguard products and services; and
      </li>
      <li>To support Site performance, perform Site analytics, enhance Site navigation, and improve our web design and
        functionality.
      </li>
    </ul>

    <h4><strong>To whom do we disclose personal information?</strong></h4>
    <p><i>Third Party Service Providers:</i> We disclose personal data about you to our service providers to process
      your kit order, maintain your contact information and send you updates on new My Classroom Economy offerings. Our
      service providers are selected partly on the basis of their information security capabilities, and we put in place
      contract terms to protect the confidentiality of your personal information.</p>
    <p><i>Legal purposes:</i> We may use and disclose information about you as we believe reasonably necessary to
      courts, parties to litigation, government authorities, law enforcement agencies, and legal and professional
      advisers to protect the rights and property of Vanguard and Vanguard Affiliates, including to establish legal
      claims or defenses, to obtain legal advice, to defend our legal rights, to protect our rights or property and
      those of Vanguard Affiliates, to protect other users of our services and our Sites, and to protect the life, body,
      or property of others (individuals or entities). We also may disclose information about you in order to comply
      with the law, judicial proceeding, court order, or other legal process, such as in response to a court order or
      direction or in other legally limited circumstances.</p>

    <h4><strong>How long do we retain personal information?</strong></h4>
    <p>We keep the categories of Personal Information described above for as long as necessary or permitted for the
      purposes described in this Notice or otherwise authorized by law. This generally means holding the information for
      as long as one of the following apply:</p>
    <ul>
      <li>Your personal information is reasonably necessary to manage our operations, to manage your relationship with
        us, or to satisfy another purpose for which we collected the information;
      </li>
      <li>Your personal information is reasonably necessary to carry out a disclosed purpose that is reasonably
        compatible with the context in which the personal information was collected;
      </li>
      <li>The personal information is reasonably necessary to protect or defend our rights or property (which will
        generally relate to applicable laws that limit actions in a particular case); or
      </li>
      <li>We are otherwise required or permitted to keep your personal information by applicable laws or regulations.
      </li>
    </ul>
    <p>Where personal information is used for more than one purpose, we will retain it until the purpose with the latest
      period expires. For more information about our retention policies, please contact us using the contact details
      below.</p>

    <h3><strong>California residents</strong></h3>
    <p>This privacy notice for California residents supplements the information above and applies solely to residents
      of the State of California. We provide this notice to comply with the California Consumer Privacy Act, as modified
      by the California Privacy Rights Act of 2020 (CCPA) and the regulations issued under it; accordingly, this notice
      addresses the specific requirements of the CCPA and should be read together with above Notice. Any terms defined
      in the CCPA have the same meaning when used in this notice; that may differ from what those terms mean when we use
      them in other policies or disclosures.</p>
    <p>The CCPA provides specific privacy rights to California residents, including the right to receive a privacy
      notice and certain rights you may choose to exercise relating to your personal information. Personal information
      is information that identifies, relates to, describes, is reasonably capable of being associated with, or could
      reasonably be linked, directly or indirectly, with a particular person or household. Personal information does not
      include aggregate or deidentified information or publicly available information.</p>

    <h4><strong>Your rights</strong></h4>
    <p>If you are a resident of California, you have the right to submit certain requests relating to your personal
      information as described below.</p>

    <h4><strong>The right to know</strong></h4>
    <p>You have the right to request that we disclose certain information to you about our collection and use of your
      personal information. Once we receive and confirm your verifiable request, we will disclose the following to you
      (unless an exception applies):</p>
    <ul>
      <li>The categories of personal information we collected about you.</li>
      <li>The categories of sources for the personal information we collected about you.</li>
      <li>Our business or commercial purpose for collecting, selling, or sharing personal information.</li>
      <li>The categories of recipients to which we disclosed that personal information.</li>
      <li>The categories of personal information that we sold, and for each category identified, the categories of third
        parties to which we sold that particular category of personal information.
      </li>
      <li>The categories of personal information that we disclosed for a business purpose, and for each category
        identified, the categories of recipients to which we disclosed that particular category of personal information.
      </li>
      <li>The specific pieces of personal information we collected about you.</li>
    </ul>

    <h4><strong>The right to delete</strong></h4>
    <p>You have the right to request that we delete personal information we collected from you, subject to certain
      exceptions.</p>

    <h4><strong>The right to correct</strong></h4>
    <p>If you believe that personal information we maintain about you is inaccurate, you have the right to request that
      we correct that information.</p>

    <h4><strong>When we sell or share personal information and your right to opt out</strong></h4>
    <p>Although we do not sell personal information in exchange for money, some of the ways in which we share personal
      information for advertising may be considered “sales” or “sharing” under the CCPA. We and our digital advertising
      providers collect certain information from your devices when you visit our websites, through cookies and other
      technologies, and when you use our mobile applications. This includes the following categories of personal
      information: Identifiers, Personal Records, Commercial Information, Internet or Other Electronic Network Activity
      Information, Geolocation Data, and Inferences. We share these categories of personal information with our digital
      advertising providers to deliver Vanguard ads that may be of interest to you. We do not have actual knowledge that
      we sell or share the personal information of California residents under 16 years of age.</p>
    <p>The manner in which you may opt out of interest-based advertising may vary depending on your relationship with
      Vanguard. For further information visit our Privacy Center. If you wish to opt out of sharing your personal
      information in connection with our interest-based advertising described above, please visit
      https://optout.aboutads.info/ and follow the opt out instructions for each of your browsers and devices. If you opt
      out of sharing this information, we will not deliver ads based on your interests and activity, but you will still
      receive ads. Note that any choice you make here will only affect this browser and device. Because your opt-out
      preferences are stored in cookies, if you reset your advertising ID or clear your cookies, we will no longer
      recognize your device or your choices, so you must opt out again. To opt back in to interest-based advertising,
      visit https://optout.aboutads.info/ again and select your preferences.</p>

    <h4><strong>Non-discrimination</strong></h4>
    <p>If you choose to exercise any of your privacy rights under the CCPA, you also have the right not to receive
      discriminatory treatment by us.</p>

    <h4><strong>How to submit a CCPA rights request </strong></h4>
    <p>If you are a California resident, you may exercise the rights described above by submitting a verifiable request
      to us by either:</p>
    <p>Using <a href="/aboutus?contact=true">this form</a>; or</p>
    <p>Emailing us at: <a href="mailTo:support@myclassroomeconomy.org">support@myclassroomeconomy.org</a></p>

    <h3><strong>Contact Us</strong></h3>
    <p>If you have questions regarding the My Classroom Economy program or need support please contact us using <a
      href="/aboutus?contact=true">this form</a>.</p>
    <p>If you have any privacy related questions, please email us at <a href="mailto:privacy@vanguard.com">privacy@vanguard.com</a>.
    </p>

    <h3><strong>Changes to this Privacy Notice</strong></h3>
    <p>We will post changes to this Notice on this Site along with the effective date of the changed Notice. We
      recommend that you review this Notice periodically.</p>
    <p><b>Updated August 2024</b></p>
  </div>
</div>