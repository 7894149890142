<div class="parallax">

    <div class="gradesHeaderImg container">
        <div class="heroContent english">
            <h1>Grades K&ndash;1</h1>
        </div>
        <div class="heroContent spanish hidden">
            <h1>Grados K&ndash;1</h1>
        </div>
    </div>
    <section class="backgroundImages" style="top: 0px;">
        <div class="centeredDiv zoom-images">
            <div class="heroOverlay" data-start="background:rgba(0, 0, 0, 0)" data-600-start="background:rgba(0, 0, 0, 0.5)" anchor-target="body"></div>
            <div class="backgroundImage" data-start="transform: scale(1)" data-600-start="transform: scale(1.3)" anchor-target="body"></div>
        </div>
    </section>
    <div class="container main" id="skrollr-body">
        <div class="col-md-4 col-sm-12 col-xs-12 sidebar k1">
            <div class="sidebarNav">
                <span class="accordionCloseFull english" id="gettingStarted" data-toggle="collapse" href="#collapse1">
                    <h4 class="k1">Getting started</h4>
                </span>
                <span class="accordionCloseFull spanish hidden" id="gettingStarted" data-toggle="collapse" href="#collapse1">
                    <h4 class="k1">Empezando</h4>
                </span>
                <ul class="panel-collapse collapse in accordion" id="collapse1">
                    <li href="#howWork" class="active sideNav english">How it works</li>
                    <li href="#curStand" class="sideNav english">Curriculum standards</li>
                    <!-- spanish -->
                    <li href="#howWork" class="active sideNav spanish hidden">Cómo funciona</li>
                    <li href="#curStand" class="sideNav spanish hidden">Estándares curriculares</li>
                </ul>
            </div>
            <div class="sidebarNav">
                <span class="accordionOpenFull english" id="planning" data-toggle="collapse" href="#collapse2">
                    <h4 class="k1">Planning</h4>
                </span>
                <span class="accordionOpenFull spanish hidden" id="planning" data-toggle="collapse" href="#collapse2">
                    <h4 class="k1">Planificación</h4>
                </span>
                <ul class="panel-collapse collapse accordion" id="collapse2">
                    <li href="#proOut" class="sideNav english">Program outline</li>
                    <li href="#jobBon" class="sideNav english">Jobs, bonuses, & rules</li>
                    <li href="#prepMat" class="sideNav english">Preparing materials</li>
                    <!-- spanish -->
                    <li href="#proOut" class="sideNav spanish hidden">Esquema del programa</li>
                    <li href="#jobBon" class="sideNav spanish hidden">Trabajos, Bonificaciones y Reglas de Clase</li>
                    <li href="#prepMat" class="sideNav spanish hidden">Preparación de materiales</li>
                </ul>
            </div>
            <div class="sidebarNav">
                <span class="accordionOpenFull english" id="activities" data-toggle="collapse" href="#collapse3">
                    <h4 class="k1">Activities</h4>
                </span>
                <span class="accordionOpenFull spanish hidden" id="activities" data-toggle="collapse" href="#collapse3">
                    <h4 class="k1">Ocupaciones</h4>
                </span>
                <ul class="panel-collapse collapse accordion" id="collapse3">
                    <li href="#firstDay" class="sideNav english">First day</li>
                    <li href="#schoolStore" class="sideNav english">The school store</li>
                    <li href="#yearEnd" class="sideNav english">Year-end wrap-up</li>
                    <!-- spanish -->
                    <li href="#firstDay" class="sideNav spanish hidden">Primer día</li>
                    <li href="#schoolStore" class="sideNav spanish hidden">La tienda de la escuela</li>
                    <li href="#yearEnd" class="sideNav spanish hidden">Cierre de fin de año</li>
                </ul>
            </div>
            <hr>
            <div class="printguide english">
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/K-1/Program_Guide_K-1.pdf')">
                    Print complete program grades K—1</a>
            </div>
            <!-- spanish -->
            <div class="printguide spanish hidden">
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/K-1/Program_Guide_K-1_ES.pdf')">
                    Imprimir programa completo grados K-1</a>
            </div>
        </div>

        <div class="col-md-8 col-sm-12 col-xs-12 gradeContent k1">
            <!-- HOW IT WORKS -->
            <div id="howWork" class="gradesInnerContent">
                <h3 class="k1 english">How My Classroom Economy Works</h3>
                <div class="infoBlock english">
                    <p>The classroom economy program is a robust classroom management system that benefits both teacher and
                        students, and has minimal impact on the curriculum. Implementing the program is a simple three-step
                        process:
                    </p>
                    <ul class="denselist">
                        <li>First, you spend a few hours before the school year starts&#8212;gathering materials and planning
                            how to customize the program for your school and your own classroom. </li>
                        <li>Second, you spend a few classroom hours in the first month of school introducing the program and
                            teaching the children how to perform their tasks.</li>
                        <li>Third, you monitor their progress throughout the year, helping them learn new tasks when appropriate,
                            and providing support and guidance. </li>
                    </ul>
                    <p> As they perform their tasks, the children are rewarded for helping you manage the classroom efficiently.
                        They have fun and, at the same time, they learn valuable skills&#8212;such as responsibility and
                        the value of saving&#8212;that they will carry with them throughout their lifetimes.</p>
                    <p>The beauty of the program is that you don't need to teach these lessons; rather, the children will experience
                        them and learn for themselves. As one teacher told us, there's a thrill in seeing "the lightbulb
                        go on" when a student realizes he or she hasn't saved enough money to buy a desirable item in the
                        classroom store.</p>

                    <h3 class="k1">Core objectives for students in Grades K&#8211;1</h3>

                    <p>Through My Classroom Economy, students learn valuable life skills in an experiential way.</p>
                    <p>Specifically, they are able to:</p>

                    <ul>
                        <li>Participate in a classroom economy designed to expose them to economic fundamentals.</li>
                        <li>Perform specific classroom jobs to earn a salary.</li>
                        <li>Purchase items at the school store with the money they earn.</li>
                    </ul>

                    <p>At these grade levels, students earn and spend $1 bills in classroom economy currency. Earning and spending
                        money reinforces their counting skills. </p>
                </div>
                <h3 class="k1 spanish hidden">Como Funciona My Classroom Economy</h3>
                <div class="infoBlock spanish hidden">
                    <p>El programa de classroom economy es un programa que beneficia tanto al profesor como a los estudiantes
                        y el programa tiene un impacto mínimo en el currículo. El programa se puede implementar en tres pasos
                        simples:
                    </p>
                    <ul class="denselist">
                        <li>Primero, usted tiene que pasar unas horas antes que comience el año escolar- recolectando materiales
                            y planeando como puede personalizar el programa para su escuela y para sus propias clases.</li>
                        <li>En segundo lugar, usted pasara unas horas en el primer mes escolar presentándole y enseñándole a
                            los estudiantes cómo funciona el programa.</li>
                        <li>Tercero, usted va a supervisar su progreso a traves del año escolar, ayudándoles a aprender nuevas
                            tareas cuando sea apropiado, y proporcionando apoyo y orientación.</li>
                    </ul>
                    <p>A medida que realizan las tareas, los estudiantes son recompensados por ayudarle a manejar la clase de
                        una manera eficiente. Los estudiantes se divierten y, al mismo tiempo, aprenden habilidades valiosas-
                        como la responsabilidad y el valor del ahorro —que van a llevar con ellos a lo largo de su vida.</p>
                    <p>La belleza del programa es que no necesitan enseñar estas lecciones; más bien, los estudiantes van a
                        tener la experiencia y aprenderán por sí mismos. Como nos dijo un profesor, es emocionante ver como
                        “se enciende el bombillo” cuando un alumno se da cuenta que ellos no han ahorrado suficiente dinero
                        para comprar un artículo deseado en la tienda de la clase.</p>

                    <h3 class="k1">Objetivos principales para los estudiantes en Grados K&#8211;1 </h3>

                    <p>Atreves de My Classroom Economy, los estudiantes aprenden lecciones valiosas de la vida de una manera
                        experiencial. </p>
                    <p>Específicamente, son capaces de:</p>

                    <ul>
                        <li>Participar en una economia de la clase, que esta diseñada para exponerlos a los fundamentos económicos.</li>
                        <li>Realizar trabajos en la clase para ganar un salario.</li>
                        <li>Comprar artículos en la tienda de la escuela con el dinero que han ahorrado.</li>
                    </ul>

                    <p>En estos niveles de grado, los estudiantes ganan y gastan billetes de $1 en la moneda de My Classroom
                        Economy. El poder ganar y gastar dinero fortalece la habilidad de contar.</p>
                </div>
            </div>


            <!-- CURRICULUM STANDARDS -->
            <div id="curStand" class=" gradesInnerContent hidden">
                <h3 class="k1 english">Connections to core standards</h3>
                <div class="infoBlock english">
                    <p>The classroom economy is designed in an experiential way to ensure that it supplements rather than interferes
                        with a classroom's core curriculum. It allows students to learn valuable life skills and make progress
                        on topics that are integral to the curriculum. This page is designed to help you make connections
                        between common lessons and the experience of the classroom economy. Our connections are based on
                        the
                        <a href="http://www.corestandards.org" target="_blank">Common Core State Standards</a>.</p>

                    <table class="table">
                        <th>
                            <h3>Standard</h3>
                        </th>
                        <th>
                            <h3>My Classroom Economy</h3>
                        </th>
                        <tr>
                            <td>
                                <strong>Mathematics:</strong>
                                <br>Count to 100 by ones and tens.</td>
                            <td>Students are required to count the dollars they earn, as well as the number they intend to use
                                to purchase items at the school store.</td>
                        </tr>
                        <tr>
                            <td>Represent addition and subtraction with objects, fingers, mental images, drawings, sound, acting
                                out situations, verbal explanations, expressions, or equations.</td>
                            <td>Students explore addition and subtraction when purchasing items at the school store using the
                                dollars they earn.</td>
                        </tr>
                    </table>
                </div>

                <h3 class="k1 spanish hidden">Conexiones para los estándares básicos</h3>
                <div class="infoBlock spanish hidden">
                    <p>My Classroom Economy está diseñado de manera experiencial para asegurar que se complemente en lugar de
                        interferir con el currículo principal de la clase. Les permite a los estudiantes aprender lecciones
                        valiosas de la vida y progresar en temas que son integrales para el currículo. Esta página está diseñada
                        para ayudarle a hacer conexiones entre las lecciones comunes y la experiencia de My Classroom Economy.
                        Nuestras conexiones se basan en los
                        <a href="http://www.corestandards.org" target="_blank">estándares comunes del estado central</a>.</p>

                    <table class="table">
                        <th>
                            <h3>Estándar</h3>
                        </th>
                        <th>
                            <h3>My Classroom Economy</h3>
                        </th>
                        <tr>
                            <td>
                                <strong>Matemáticas:</strong>
                                <br>Cuenta hasta 100 en unos y decenas.</td>
                            <td>Los estudiantes están obligados a contar los dólares que ganan, y también tienen que contar los
                                dólares que tienen la intención de utilizar para comprar artículos en la tienda de la escuela.</td>
                        </tr>
                        <tr>
                            <td>Representan suma y resta con objetos, dedos, imágenes mentales, dibujos, sonido, situaciones
                                de actuación, explicaciones verbales, expresiones o ecuaciones.</td>
                            <td>Los estudiantes exploran la suma y resta al comprar artículos en la tienda de la escuela usando
                                los dólares que ganan.</td>
                        </tr>
                    </table>
                </div>
            </div>


            <!-- PROGRAM OUTLINE -->
            <div id="proOut" class=" gradesInnerContent hidden">
                <h3 class="k1 english">Program Outline</h3>
                <div class="infoBlock english">
                    <b>Planning and preparation</b>
                    <p>Begin planning your classroom economy before the school year starts.</p>
                    <ul>
                        <li>Prepare materials, including student folders and classroom cash.</li>
                        <li>Choose your class rules and decide how they will be reinforced with the classroom cash.</li>
                        <li>Begin to gather rewards for students to purchase at the school store.</li>
                    </ul>
                    <p>Allow 1 to 2 hours for planning and preparation.</p>

                    <b>Introductory activities</b>
                    <p> While explaining your class rules to the students, also explain the key concepts of the classroom economy,
                        including earning a salary and bonus money, paying rent, and buying items at the school store.</p>
                    <p>Allow approximately 1 hour of class time to complete this activity.</p>

                    <b>Ongoing activities</b>
                    <p>Every week students will earn $2 for completing their classroom job, provided they didn't break any of
                        the classroom rules. Additionally, students can earn bonus dollars throughout the week for demonstrating
                        good behavior—all with classroom economy currency.</p>
                    <p>At the end of the month, students pay $3 for "rent" and then have the option to spend the rest of their
                        money at the school store, or to continue to save.</p>
                    <p>Allow 1 to 2 hours per month to complete these activities.</p>

                    <b>Year-end wrap-up</b>
                    <p>Bring the classroom economy program to an end with a discussion and a chance to reflect. Distribute certificates
                        to highlight student successes.</p>
                    <p>Allow approximately 30 minutes for the year-end wrap-up.</p>
                </div>

                <h3 class="k1 spanish hidden">Resumen del programa</h3>
                <div class="infoBlock spanish hidden">
                    <b>Planificacion y preparacion</b>
                    <p>Comience a planificar My Classroom Economy antes que comience el año escolar.</p>
                    <ul>
                        <li>Prepare los materiales, incluya las carpetas de los estudiantes y el efectivo de la clase.</li>
                        <li>Elija las reglas de su clase y decida cómo serán reforzadas con el efectivo de la clase.</li>
                        <li>Comience a reunir recompensas para que los estudiantes compren en la tienda de la escuela.</li>
                    </ul>
                    <p>Permita de 1 a 2 horas para la planificación y preparación del programa.</p>

                    <b>Actividades Iniciales</b>
                    <p>Mientras que le explica las reglas de la clase a los estudiantes, también expliqueles los conceptos claves
                        de My Classroom Economy, que incluyen ganar un salario y bonos, pagar alquiler y comprar artículos
                        en la tienda de la escuela.</p>
                    <p>Permita aproximadamente 1 hora de clase para completar esta actividad.</p>

                    <b>Actividades en curso</b>
                    <p>Cada semana los estudiantes ganarán $2 por completar su trabajo en la clase. Los estudiantes pueden ganar
                        dólares de bonos durante la semana por demostrar un buen comportamiento-en la moneda de My Classroom
                        Economy.
                    </p>
                    <p>Al final del mes, los estudiantes pagan $ 3 por "el alquiler" y luego tienen la opción de gastar el resto
                        de su dinero en la tienda de la escuela o continuar ahorrando.</p>
                    <p>Permita 1 a 2 horas por mes para completar estas actividades.</p>

                    <b>Actividades del fin de año </b>
                    <p>Termine My Classroom Economy con una discusión y una oportunidad para reflexionar. Distribuya certificados
                        para resaltar los éxitos de los estudiantes.</p>
                    <p>Permita aproximadamente 30 minutos para las actividades del fin de año.</p>
                </div>
            </div>

            <!-- JOBS, BONUSES, and CLASS RULES -->
            <div id="jobBon" class=" gradesInnerContent hidden">
                <h3 class="k1 english">Jobs, Bonuses, and Class Rules</h3>
                <h3 class="k1 spanish hidden">Trabajos, Bonificaciones y Reglas de Clase</h3>
                <div class="infoBlock">
                    <p class="english">To start the classroom economy program, you'll need to select:</p>
                    <ul class="english">
                        <li>A set of student jobs.</li>
                        <li>A list of opportunities for bonus money.</li>
                        <li>A list of rules that, if broken, will result in a student earning less money.</li>
                    </ul>
                    <p class="english">Your selections can be from the lists we provide or based on the classroom rules you've already developed.
                        You may choose to involve your students in creating the bonus and rule lists, but this is entirely
                        optional. Once the lists are ready, post them in your classroom and refer to them throughout the
                        year. If you wish, you can use materials from this site as posters or items for a bulletin board.</p>
                    <!-- spanish -->
                    <p class="spanish hidden">Para iniciar My Classroom Economy, tiene que seleccionar:</p>
                    <ul class="spanish hidden">
                        <li>Una lista de trabajos para los estudiantes.</li>
                        <li>Una lista de oportunidades para recibir una bonificación.</li>
                        <li>Una lista de reglas que, que si lo quiebran, resultará en el estudiante ganando menos dinero.</li>
                    </ul>
                    <p class="spanish hidden">Las selecciones que hace pueden ser de las listas que le ofrecemos o basado en las normas de la clase
                        que ya ha desarrollado. Usted puede involucrar a sus estudiantes en la creación de las listas de
                        bonificaciones y las reglas, pero esto es totalmente opcional. Cuando acaben de hacer las listas,
                        publican las en su clase y se refieren a ellos durante todo el año. Si lo desea, puede utilizar los
                        materiales de esta página como pósteres o artículos para un tablón de anuncios.
                    </p>
                    <div>
                        <!-- Nav tabs -->
                        <ul class="nav nav-tabs" role="tablist">
                            <li role="presentation" class="active english">
                                <a href="#jobs" aria-controls="jobs" role="tab" data-toggle="tab">Jobs</a>
                            </li>
                            <li role="presentation" class="active spanish hidden">
                                <a href="#jobs" aria-controls="jobs" role="tab" data-toggle="tab">Trabajos</a>
                            </li>
                            <li role="presentation" class="english">
                                <a href="#bonuses" aria-controls="bonuses" role="tab" data-toggle="tab">Bonuses</a>
                            </li>
                            <li role="presentation" class="spanish hidden">
                                <a href="#bonuses" aria-controls="bonuses" role="tab" data-toggle="tab">Bonificaciones</a>
                            </li>
                            <li role="presentation" class="english">
                                <a href="#rules" aria-controls="rules" role="tab" data-toggle="tab">Class rules</a>
                            </li>
                            <li role="presentation" class="spanish hidden">
                                <a href="#rules" aria-controls="rules" role="tab" data-toggle="tab">Reglas de la clase</a>
                            </li>
                        </ul>
                        <!-- Tab panes -->
                        <div class="tab-content">
                            <div role="tabpanel" class="tab-pane active" id="jobs">
                                <div class="english">
                                    <p>A key component of the classroom economy is for students to hold a job and earn a salary.
                                        Many teachers use classroom jobs as a part of their classroom management system.
                                        In Grades K and 1, the students will earn $2 per week for completing their jobs.</p>
                                    <p>We suggest rotating the jobs every one to two weeks, but if you prefer, you can elect
                                        to have students hold the jobs for the entire year.</p>
                                    <p>You can select jobs from the list below, and you can also create additional jobs to meet
                                        the specific needs of your classroom.</p>
                                </div>
                                <div class="spanish hidden">
                                    <p>Una gran clave de My Classroom Economy es que los estudiantes mantengan un trabajo y
                                        un salario. Muchos profesores utilizan estos trabajos de aula la clase como parte
                                        de su sistema de administración de la clase. En kindergarten y el primer grado, los
                                        estudiantes ganarán $2 por semana para completar sus puestos de trabajo.</p>
                                    <p>Se aconseja rotar trabajos cada una o dos semanas, pero si lo prefiere, puede elegir
                                        que los estudiantes ocupan los trabajos durante todo el año.</p>
                                    <p>Puede seleccionar puestos de trabajo de la lista siguiente, y también puede crear trabajos
                                        adicionales para satisfacer las necesidades específicas de su clase.</p>
                                </div>
                                <table class="table">
                                    <tbody class="english">
                                    <tr>
                                        <th>Jobs</th>
                                        <th>Job Description</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Money Distributor
                                                <br>and Collector</strong>
                                            <br>
                                            <h5>1–2 per class</h5>
                                        </td>
                                        <td>Passes out dollars as directed by the teacher. Collects rent from each student
                                            on the appropriate day.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Line Leader</strong>
                                            <br>
                                            <h5>1 per class</h5>
                                        </td>
                                        <td>Leads the line as students walk through the hallway.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Line Caboose</strong>
                                            <br>
                                            <h5>1 per class</h5>
                                        </td>
                                        <td>Stays at the end of the line as students walk through the hallway. Ensures all
                                            students stay in the line in an orderly manner.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Table Cleaner</strong>
                                            <br>
                                            <h5>1–2 per class</h5>
                                        </td>
                                        <td>Cleans tables after lunch.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Floor Cleaner</strong>
                                            <br>
                                            <h5>1–2 per class</h5>
                                        </td>
                                        <td>Removes trash from floors after lunch.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Paper Passer</strong>
                                            <br>
                                            <h5>1 per class</h5>
                                        </td>
                                        <td>Helps the teacher pass out papers.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Attendance Taker</strong>
                                            <br>
                                            <h5>1 per class</h5>
                                        </td>
                                        <td>Helps the teacher take attendance in the morning.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Messenger</strong>
                                            <br>
                                            <h5>1 per class</h5>
                                        </td>
                                        <td>Delivers written and oral messages to other people in the school.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Calendar Aide</strong>
                                            <br>
                                            <h5>1 per class</h5>
                                        </td>
                                        <td>Updates the calendar daily to display the current day and date.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Pencil Sharpener</strong>
                                            <br>
                                            <h5>1 per class</h5>
                                        </td>
                                        <td>Sharpens pencils for other students. Ensures the class is stocked with sharp
                                            pencils every morning.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Greeter</strong>
                                            <br>
                                            <h5>1 per class</h5>
                                        </td>
                                        <td>Opens the door for students and adults as they enter the room. Greets visitors
                                            with a friendly hello.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Decorator</strong>
                                            <br>
                                            <h5>1–2 per class</h5>
                                        </td>
                                        <td>Helps the teacher with bulletin boards, posters, and other decorations.</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <div class="tip">
                                                <img src="assets/images/lightbulbIcon.svg">
                                                <strong>TIPS:</strong>
                                            </div>
                                            <ul>
                                                <li>Consider creating jobs to match tasks you normally assign to students.
                                                    For example, if you typically have a class pet, you might want to
                                                    hire a Zookeeper to oversee its care.</li>
                                                <li>Avoid choosing jobs that will be difficult for you to teach or manage.
                                                    In the classroom economy, the students should be able to perform
                                                    their jobs without constant supervision. That way, they'll know that
                                                    they have earned their pay—and they will have lessened the burden
                                                    on the teacher, which is an additional goal of the program.</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tbody class="spanish hidden">
                                    <tr>
                                        <th>Trabajos</th>
                                        <th>Descripción del trabajo</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Distribuidor de dinero
                                                <br>y el colector</strong>
                                            <br>
                                            <h5>1–2 por clase</h5>
                                        </td>
                                        <td>Da el dinero como lo indique el profesor. Colecciona el alquiler de cada estudiante
                                            en el día apropiado.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Líder de línea</strong>
                                            <br>
                                            <h5>1 por clase</h5>
                                        </td>
                                        <td>Conduce la línea de estudiantes cuando caminan por los corridores.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Ordenador de la línea</strong>
                                            <br>
                                            <h5>1 por clase</h5>
                                        </td>
                                        <td>Se queda en el extremo de la línea de estudiantes caminando por el corridor.
                                            Asegura que todos los estudiantes permanecen en la línea de una manera ordenada.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Limpiador de mesas</strong>
                                            <br>
                                            <h5>1–2 por clase</h5>
                                        </td>
                                        <td>Limpia las mesas después del almuerzo.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Limpiador de piso</strong>
                                            <br>
                                            <h5>1–2 por clase</h5>
                                        </td>
                                        <td>Recoge la basura de piso después del almuerzo.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Repartidor de papel</strong>
                                            <br>
                                            <h5>1 por clase</h5>
                                        </td>
                                        <td>Ayuda el profesor a entregar los papeles.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Tomador de asistencia</strong>
                                            <br>
                                            <h5>1 por clase</h5>
                                        </td>
                                        <td>Ayuda el profesor a tomar la lista de asistencia en la mañana.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Mensajero</strong>
                                            <br>
                                            <h5>1 por clase</h5>
                                        </td>
                                        <td>Entrega mensajes escritos y orales a otras personas en la escuela.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Ayudante del calendario</strong>
                                            <br>
                                            <h5>1 por clase</h5>
                                        </td>
                                        <td>Actualiza el calendario todos los días para mostrar el día actual y la fecha.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Sacapuntador de lápiz</strong>
                                            <br>
                                            <h5>1 por clase</h5>
                                        </td>
                                        <td>Afila lápices para los estudiantes. Asegura que la clase tiene suficiente lápices
                                            afilados cada mañana.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Saludador</strong>
                                            <br>
                                            <h5>1 por clase</h5>
                                        </td>
                                        <td>Abre la puerta para estudiantes y adultos al entrar a la clase. Recibe a los
                                            visitantes con un saludo amable.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Decorador</strong>
                                            <br>
                                            <h5>1–2 por clase</h5>
                                        </td>
                                        <td>Ayuda el profesor con tablones de anuncios, carteles y otras decoraciones.</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <div class="tip">
                                                <img src="assets/images/lightbulbIcon.svg">
                                                <strong>SUGERENCIAS:</strong>
                                            </div>
                                            <ul>
                                                <li>Considere crear trabajos que coincidan con las tareas y trabajos que
                                                    normalmente asignaa los estudiantes. Por ejemplo, si normalmente
                                                    tienes una mascota de la clase, puede contratar a un guardián del
                                                    zoológico para cuidarlo.</li>
                                                <li>Evitar dar trabajos que van a ser difícil para usted que enseñe o administre.
                                                    En My Classroom Economy, los estudiantes deben ser capaces de realizar
                                                    su trabajo sin supervisión constante. Así, ellos sabrán que se ha
                                                    ganado su salario, y habrá reducido la carga del profesor, que es
                                                    un objetivo adicional del programa.</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- english bonus table -->
                            <div role="tabpanel" class="tab-pane" id="bonuses">
                                <div class="english">
                                    <p>In addition to the salaries that students earn from their jobs, they can earn bonus money
                                        for good behavior. The bonuses give students more opportunities to succeed in the
                                        classroom economy. In addition, they can be useful incentives for your own class
                                        goals.
                                    </p>
                                    <p>Behaviors you can reward with bonus money include:</p>
                                </div>
                                <div class="spanish hidden">
                                    <p>Además de los salarios que ganan los estudiantes desde sus trabajos, ganan dinero de
                                        bonificación por buen comportamiento. Las bonificaciones dan a los estudiantes más
                                        oportunidades para tener éxito en la economía de la clase. Además, pueden ser incentivos
                                        útiles para sus propios objetivos de clase.
                                    </p>
                                    <p>Comportamientos que puedes recompensar con dinero de bonificación incluyen:</p>
                                </div>
                                <table class="table">
                                    <tbody class="english">
                                    <tr>
                                        <th>Activity</th>
                                    </tr>
                                    <tr>
                                        <td>Completing all classroom assignments for the week.</td>
                                    </tr>
                                    <tr>
                                        <td>Assisting other students with tasks.</td>
                                    </tr>
                                    <tr>
                                        <td>Helping the teacher.</td>
                                    </tr>
                                    <tr>
                                        <td>Getting a compliment from another teacher.</td>
                                    </tr>
                                    <tr>
                                        <td>Following classroom rules, such as lining up quickly, being quiet and in your
                                            seat, or raising your hand before speaking.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="tip">
                                                <img src="assets/images/lightbulbIcon.svg">
                                                <strong>TIPS:</strong>
                                            </div>
                                            <ul>
                                                <li>With bonus money, it's far better to give away too much rather than too
                                                    little. At this age, the goal of the classroom economy is to focus
                                                    on positive behavior.</li>
                                                <li>A great way to encourage students to follow your rules is to "catch"
                                                    one student in the act of being good and give him or her a $1 bonus.
                                                </li>
                                                <li>You can reward the entire class with a $1 bonus when they demonstrate
                                                    exceptional behavior.</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tbody class="spanish hidden">
                                    <tr>
                                        <th>Actividad</th>
                                    </tr>
                                    <tr>
                                        <td>Completar todas las tareas de la clase para la semana.</td>
                                    </tr>
                                    <tr>
                                        <td>Ayudar a otros estudiantes con las tareas.</td>
                                    </tr>
                                    <tr>
                                        <td>Ayudando al profesor.</td>
                                    </tr>
                                    <tr>
                                        <td>Recibir un halago de otro profesor.</td>
                                    </tr>
                                    <tr>
                                        <td>Siguiendo las reglas de la clase, como alinear rápidamente, estar tranquilo y
                                            en su asiento o levantar la mano antes de hablar.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="tip">
                                                <img src="assets/images/lightbulbIcon.svg">
                                                <strong>SUGERENCIAS:</strong>
                                            </div>
                                            <ul>
                                                <li>Con el dinero de bonificación, es mucho mejor dar demasiado en comparación
                                                    de dar poco. A esta edad, el objetivo de My Classroom Economy debe
                                                    enfocarse en el comportamiento positivo.</li>
                                                <li>Una gran manera de animar a los estudiantes a seguir las reglas es "encontrar"
                                                    a un estudiante en el acto de ser buena y darle una bonificación
                                                    de $1.
                                                </li>
                                                <li>Puede recompensar toda la clase con una bonificación de $1 cuando demuestran
                                                    comportamiento excepcional.</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>




                            <div role="tabpanel" class="tab-pane" id="rules">
                                <div class="english">
                                    <p>The classroom economy can help you enforce your classroom rules. While students earn
                                        $2 per week for completing their classroom jobs, those who break a class rule that
                                        week will only earn $1. If a student breaks two or more rules in a week, he or she
                                        will earn $0.</p>
                                    <p>A key concept of the classroom economy is to focus on the positive aspects of earning
                                        and saving money. We encourage you to create a short and direct rules list, which
                                        matches your classroom priorities.</p>
                                    <p>It is far more effective to catch students being good and reward them with bonus money
                                        than to focus on punishing students.</p>
                                    <p>Here is a sample of classroom rules:</p>
                                </div>
                                <div class="spanish hidden">
                                    <p>La economía de la clase puede ayudar a reforzar las reglas de su clase. Mientras que
                                        los estudiantes ganan $2 por semana por completar su trabajo de la clase, aquellos
                                        que rompen una regla de la clase esa semana sólo ganarán $1. Si un estudiante rompe
                                        dos o más reglas en una semana, ganarán $0.</p>
                                    <p>Un concepto clave de My Classroom Economy debe enfocarse en los aspectos positivos de
                                        ganar y ahorrar dinero. Le animamos a crear una lista de reglas corta y directa,
                                        que coincide con las prioridades de su clase.</p>
                                    <p>Es mucho más efectivo ser buenos estudiantes y recompensarlos con dinero de la bonificación
                                        que concentrarse en castigar a los estudiantes.</p>
                                    <p>Este es un ejemplo de las reglas de la clase:</p>
                                </div>
                                <table class="table">
                                    <tbody class="english">
                                    <tr>
                                        <th>Rule</th>
                                    </tr>
                                    <tr>
                                        <td>Be polite to your teacher and fellow classmates.</td>
                                    </tr>
                                    <tr>
                                        <td>Keep your table and cubby clean.</td>
                                    </tr>
                                    <tr>
                                        <td>Follow the directions you are provided.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="tip">
                                                <img src="assets/images/lightbulbIcon.svg">
                                                <strong>TIPS:</strong>
                                            </div>
                                            <ul>
                                                <li>Keep a clipboard with a list of students' names so that you can keep
                                                    track of anyone who breaks a rule and deduct his or her pay on Payday.
                                                    You will also want to remind the student of what he or she did to
                                                    earn the penalty.</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tbody class="spanish hidden">
                                    <tr>
                                        <th>Regla</th>
                                    </tr>
                                    <tr>
                                        <td>Ser cortés a sus compañeros de clase, y su profesor.</td>
                                    </tr>
                                    <tr>
                                        <td>Mantenga limpia su mesa.</td>
                                    </tr>
                                    <tr>
                                        <td>Sigue las instrucciones.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="tip">
                                                <img src="assets/images/lightbulbIcon.svg">
                                                <strong>SUGERENCIAS:</strong>
                                            </div>
                                            <ul>
                                                <li>Mantener un portapapeles con una lista de nombres de los estudiantes
                                                    para que puedan realizar un seguimiento de cualquier persona que
                                                    quiebra una regla y reducir su salario el Día del Pago. También tienes
                                                    que recordar al estudiante de lo que él o ella hizo para ganarse
                                                    la multa.</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- PREPARING MATERIALS -->
            <div id="prepMat" class=" gradesInnerContent hidden">
                <h3 class="k1 english">Preparing Materials</h3>
                <!-- spanish -->
                <h3 class="k1 spanish hidden">Preparación de materiales</h3>
                <div class="infoBlock">
                    <div class="english">
                        <p>A little up-front preparation can help to ensure successful implementation of the classroom economy.
                            This page helps you to get organized.</p>
                        <b>Supplies</b>
                        <p>In addition to the materials we provide, you may wish to purchase or gather the following items:</p>
                    </div>
                    <div class="spanish hidden">
                        <p>Preparación un poco adelantada puede ayudar a asegurar una implementación exitosa de la economía
                            de la clase. Esta página le ayuda a organizarse.</p>
                        <b>Materiales</b>
                        <p>Además de los materiales que proporionamos, usted puede desear comprar o reunir los siguientes artículos:</p>
                    </div>
                    <table class="table">
                        <tbody class="english">
                        <tr>
                            <td>
                                <strong>No. 10 business envelopes</strong> in which students can conveniently store their cash.
                            </td>
                            <td>1 per student</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Portfolio folders</strong> in which students can place their cash envelopes for safekeeping.</td>
                            <td>1 per student</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Cash box or envelope</strong> to store excess cash. It is important to have an ample
                                supply of cash on hand so you can distribute bonus money frequently.</td>
                            <td>1 per student</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Items for students to purchase at the store, </strong>such as stickers, markers, pencils,
                                toys, baked goods, or gift certificates.</td>
                            <td>Varies depending on items chosen</td>
                        </tr>
                        </tbody>
                        <tbody class="spanish hidden">
                        <tr>
                            <td>
                                <strong>Sobres de negocios</strong> en que los estudiantes puede convenientemente guardar su
                                efectivo.
                            </td>
                            <td>1 por estudiante</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Carpetas de portafolio</strong> en que los estudiantes pueden colocar sus sobres de dinero
                                para que esté seguramente guardado.</td>
                            <td>1 por estudiante</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Caja de efectivo o sobre</strong> para almacenar el efectivo que queda sobrando. Es importante
                                tener una amplia provisión de dinero en efectivo a mano con que frecuentemente puede
                                distribuir dinero de bonificación.</td>
                            <td>1 por estudiante</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Artículos para estudiantes al comprar en la tienda, </strong>como pegatinas, marcadores,
                                lápices, juguetes, postres o certificados de regalo.</td>
                            <td>Varía en función de ítems elegidos</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="english">
                        <b>Visual displays</b>
                        <p>When explaining how your classroom rules connect to the classroom economy, it is a good idea to display
                            the rules in your classroom. Then you will be able to refer to them throughout the year.</p>
                        <p>Some suggestions include creating:</p>
                    </div>
                    <div class="spanish hidden">
                        <b>Visualizaciones</b>
                        <p>Al explicar cómo conectan las reglas de la clase a My Classroom Economy, es una buena idea mostrar
                            las reglas en la clase. Podrás referir a estas reglas durante todo el año.</p>
                        <p>Algunas sugerencias incluyen la creación de:</p>
                    </div>
                    <table class="table">
                        <tbody class="english">
                        <tr>
                            <td>
                                <strong>Item</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Bulletin board</td>
                        </tr>
                        <tr>
                            <td>Posters (
                                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/K-1/Job_Posters_K-1.pdf')"> jobs</a>, bonuses, rules)</td>
                        </tr>
                        <tr>
                            <td>Presentation</td>
                        </tr>
                        </tbody>
                        <tbody class="spanish hidden">
                        <tr>
                            <td>
                                <strong>Artículo</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Tablón de anuncios</td>
                        </tr>
                        <tr>
                            <td>Carteles (
                                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/K-1/Job_Posters_K-1_ES.pdf')"> puestos de trabajo</a>, bonificación, reglas)</td>
                        </tr>
                        <tr>
                            <td>Presentación</td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="english">
                        <b>Print classroom economy cash</b>
                        <p>To start, we recommend printing about $150 in cash:</p>
                    </div>
                    <div class="spanish hidden">
                        <b>Imprima dinero de My Classroom Economy </b>
                        <p>Para empezar, le recomendamos imprimir cerca de $150 en efectivo:</p>
                    </div>
                    <table class="table">
                        <tbody class="english">
                        <tr>
                            <td>
                                <strong>Denomination</strong>
                            </td>
                            <td>
                                <strong>Quantity</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/K-1/Bills_1_K-1.pdf')">$1 bills</a>
                            </td>
                            <td>$150 (twenty-five sheets)</td>
                        </tr>
                        </tbody>
                        <tbody class="spanish hidden">
                        <tr>
                            <td>
                                <strong>Denominación</strong>
                            </td>
                            <td>
                                <strong>Cantidad</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/K-1/Bills_1_K-1_ES.pdf')">billetes de $1</a>
                            </td>
                            <td>$150 (25 hojas)</td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="english">
                        <b>Other materials</b>
                        <p>For the year-end wrap-up you'll need reward
                            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/K-1/Saver_Cert_K-1.pdf')">certificates</a>.</p>
                    </div>
                    <div class="spanish hidden">
                        <b>Otros materiales</b>
                        <p>Para el fin del año tendrá
                            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/K-1/Saver_Cert_K-1_ES.pdf')">certificados de recompensa</a>.</p>
                    </div>
                </div>

                <h3 class="k1 spanish hidden">Preparación de materiales</h3>
                <div class="infoBlock spanish hidden">
                    <p>Preparación un poco adelantada puede ayudar a asegurar una implementación exitosa de la economía de la
                        clase. Esta página le ayuda a organizarse.</p>
                    <b>materiales</b>
                    <p>Además de los materiales que proporionamos, usted puede desear comprar o reunir los siguientes artículos:</p>
                    <table class="table">
                        <tbody>
                        <tr>
                            <td>
                                <strong>Sobres de negocios</strong> en que los estudiantes puede convenientemente guardar su
                                efectivo.
                            </td>
                            <td>1 por estudiante</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Carpetas de portafolio</strong> en que los estudiantes pueden colocar sus sobres de dinero
                                para que esté seguramente guardado.</td>
                            <td>1 por estudiante</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Caja de efectivo o sobre</strong> para almacenar el efectivo que queda sobrando. Es importante
                                tener una amplia provisión de dinero en efectivo a mano con que frecuentemente puede
                                distribuir dinero de bonificación.</td>
                            <td>1 por estudiante</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Artículos para estudiantes al comprar en la tienda, </strong>como pegatinas, marcadores,
                                lápices, juguetes, postres o certificados de regalo.</td>
                            <td>Varía en función de ítems elegidos</td>
                        </tr>
                        </tbody>
                    </table>

                    <b>Visualizaciones</b>
                    <p>Al explicar cómo conectan las reglas de la clase a My Classroom Economy, es una buena idea mostrar las
                        reglas en la clase. Podrás referir a estas reglas durante todo el año.</p>

                    <p>Algunas sugerencias incluyen la creación de:</p>
                    <table class="table">
                        <tbody>
                        <tr>
                            <td>
                                <strong>Artículo</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Tablón de anuncios</td>
                        </tr>
                        <tr>
                            <td>Carteles (
                                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/K-1/Job_Posters_K-1.pdf')"> puestos de trabajo</a>, bonificación, reglas)</td>
                        </tr>
                        <tr>
                            <td>Presentación</td>
                        </tr>
                        </tbody>
                    </table>


                    <b>Imprima dinero de My Classroom Economy</b>
                    <p>Para empezar, le recomendamos imprimir cerca de $150 en efectivo:</p>
                    <table class="table">
                        <tbody>
                        <tr>
                            <td>
                                <strong>Denominación</strong>
                            </td>
                            <td>
                                <strong>Cantidad</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/K-1/Bills_1_K-1_ES.pdf')">billetes de $1</a>
                            </td>
                            <td>$150 (25 hojas)</td>
                        </tr>
                        </tbody>
                    </table>

                    <b>OTROS MATERIALES</b>
                    <p>Para el fin del año tendrá
                        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/K-1/Saver_Cert_K-1_ES.pdf')">certificados de recompensa</a>.</p>

                </div>
            </div>

            <!-- FIRST DAY -->
            <div id="firstDay" class=" gradesInnerContent hidden">
                <h3 class="k1 english">First day</h3>
                <div class="infoBlock english">

                    <p>On the first day of the program, you can explain the rules of the classroom economy as you would with
                        any classroom management system.</p>
                    <div class="headerTime">
                        <b>Preparation</b>
                        <span>60&ndash;120 minutes</span>
                    </div>
                    <ul>
                        <li>Gather student folders to be handed out at the start of class.</li>
                        <li>Gather envelopes to be placed in the folders to hold the students' money.</li>
                        <li>Display some of the items that will be sold at the school store.</li>
                        <li>Customize the visual displays of your classroom rules, which will connect to the rewards and consequences
                            for the students.</li>
                    </ul>

                    <div class="headerTime">
                        <b>In class</b>
                        <span>30&ndash;60 minutes</span>
                    </div>
                    <p>Welcome the students and introduce your class rules with the following key additions:</p>
                    <ul>
                        <li>Students earn $2 in classroom money each week for completing their jobs ($8 per month).</li>
                        <li>Students earn an additional $1 as bonus money for good behavior.</li>
                        <li>Students found misbehaving during the week will earn less money for their "salary."</li>
                        <ul>
                            <li>Students only earn $1 if they break one rule.</li>
                            <li>Students earn nothing if they break two or more rules.</li>
                        </ul>
                        <li>Students pay $3 per month to rent their cubbies and seats.</li>
                        <li>Students can purchase items from the school store with any money they have left after paying the
                            monthly rent.</li>
                    </ul>
                    <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>TIPS:</strong>
                    </div>
                    <p>If you like to include your students in creating materials for class activities, feel free to have them
                        help make your visual displays during class time, or after the opening day.</p>

                    <p>Some students at this age may have a difficult time understanding the concept of rent. An alternative
                        way to present it is to charge $3 for the right to shop at the school store.</p>
                </div>

                <h3 class="k1 spanish hidden">Primer día</h3>
                <div class="infoBlock spanish hidden">
                    <p>En el primer día del programa, puede explicar las reglas de la economía de la clase como lo haría con
                        cualquier sistema de administración de clase.</p>
                    <div class="headerTime">
                        <b>Preparación</b>
                        <span>60&ndash;120 minutos</span>
                    </div>
                    <ul>
                        <li>Reúne carpetas para los estudiantes para repartir los al comienzo de clase.</li>
                        <li>Recoger los sobres para colocar en las carpetas para mantener el dinero de los estudiantes.</li>
                        <li>Mostrar algunos de los artículos que se venderán en la tienda de la escuela.</li>
                        <li>Personalizar la muestra visual de las reglas dela clase , que conectarán a las recompensas y las
                            consecuencias para los estudiantes.</li>
                    </ul>

                    <div class="headerTime">
                        <b>En la clase </b>
                        <span>30&ndash;60 minutos</span>
                    </div>
                    <p>Bienvenida a los estudiantes e introducir las reglas de su clase con las siguientes adiciones claves:</p>
                    <ul>
                        <li>Los estudiantes ganan 2 dólares en dinero de la clase cada semana para completar sus puestos de trabajo
                            ($8 por mes).</li>
                        <li>Los estudiantes ganan un adicional $1 como dinero de bonificación por buen comportamiento.</li>
                        <li>Los estudiantes que se comportan mal durante la semana ganará menos dinero por su "salario".</li>
                        <ul>
                            <li>Los estudiantes ganan sólo $1 si rompen una regla.</li>
                            <li>Los estudiantes ganan nada si se rompen dos o más reglas.</li>
                        </ul>
                        <li>Los estudiantes pagan $3 por mes para alquilar sus escritorios y asientos.</li>
                        <li>Estudiantes pueden comprar artículos de la tienda escolar con todo el dinero que les queda después
                            de pagar el alquiler mensual.</li>
                    </ul>
                    <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>SUGERENCIAS:</strong>
                    </div>
                    <p>Si desea incluir a sus estudiantes en la creación de materiales para las actividades de clase, deja que
                        ayuden crear una muestra visual durante el tiempo de clase o después de la inauguración.</p>
                    <p>Algunos estudiantes a esta edad pueden tener un tiempo difícil entender el concepto de alquiler. Una
                        manera alternativa para presentarla es cobrar $3 por el derecho a comprar en la tienda de la escuela.</p>
                </div>
            </div>

            <!-- THE SCHOOL STORE -->
            <div id="schoolStore" class=" gradesInnerContent hidden">
                <h3 class="k1 english">The school store</h3>
                <div class="infoBlock english">
                    <p>The school store is an important component of the classroom economy. Children look forward to buying
                        things at the store because it is a fun reward for all of their hard work. From a learning perspective,
                        it teaches the value of saving, because students are eager to save up for larger-ticket items. We
                        suggest opening the school store once per month.</p>

                    <b>Gathering school store items</b>
                    <p>Get the community involved by asking local businesses or vendors to donate items. Ask parents to donate,
                        too, and also involve your peers. Some items can be intangible.</p>
                    <p>Possible store items:</p>
                    <table class="table">
                        <tr>
                            <th>Tangible</th>
                            <th>Intangible</th>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>Healthy snacks.</li>
                                    <li>Small toys.</li>
                                    <li>Sports balls.</li>
                                    <li>Pencils.</li>
                                    <li>Erasers.</li>
                                    <li>Baked goods.</li>
                                    <li>Children's movies.</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Extra recess or computer time.</li>
                                    <li> Extra time with the teacher.</li>
                                    <li>Special privileges for a day.</li>
                                </ul>
                            </td>
                        </tr>
                    </table>

                    <div class="headerTime">
                        <b>In class</b>
                        <span>15 minutes</span>
                    </div>
                    <ul>
                        <li>Open the school store after the students pay rent.
                        <li>Tell the students to count out $3 from their cash envelopes for monthly rent. Collect (or have
                            your Money Collector collect) the $3 from each student.</li>
                        <li>Allow students time to count the remaining dollars they have in their envelopes after paying
                            rent.
                        </li>
                        <li>Explain that they can spend as much of their money as they want at the school store, but that
                            they might want to save up for larger purchases.</li>
                        <li>Give the children time to shop at the store.</li>
                    </ul>

                    <div class="headerTime">
                        <b>Follow-up discussion</b>
                        <span>10 minutes</span>
                    </div>
                    <p>After the store activity, you might want to take a few minutes to talk it over with the students. This
                        is a great opportunity to reinforce the importance of saving.</p>
                </div>

                <h3 class="k1 spanish hidden">La tienda de la clase</h3>
                <div class="infoBlock spanish hidden">

                    <p>La tienda es una parte muy importante de la economía de la clase. Para los estudiantes, comprar cosas
                        en la tienda es un premio por haber trabajado duro. A los niños les enseña el valor de ahorrar para
                        poder comprar cosas más atractivas. Sugerimos que abran la tienda una vez al mes.</p>

                    <b>Agregando cosas para vender</b>
                    <p>Solicita participación de la comunidad con donaciones de negocios locales, los padres de los estudiantes,
                        y tus pares. Puedes vender cosas tangibles o intangibles.</p>
                    <p>Sugerencias:</p>
                    <table class="table">
                        <tr>
                            <th>Tangible</th>
                            <th>Intangible</th>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>Mecato saludables.</li>
                                    <li>Juguetes pequeños.</li>
                                    <li>Balones deportivos.</li>
                                    <li>Lápices.</li>
                                    <li>Borradores.</li>
                                    <li>Productos De panaderías, o pastelerías.</li>
                                    <li>Películas para jóvenes.</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Tiempo extra para el recre, o con la computadora.</li>
                                    <li>Tiempo extra con el/la maestro/a.</li>
                                    <li>Privilegios especiales.</li>
                                </ul>
                            </td>
                        </tr>
                    </table>

                    <div class="headerTime">
                        <b>En la clase </b>
                        <span>15 minutos</span>
                    </div>
                    <ul>
                        <li>Abra la tienda después de que los estudiantes paguen el alquiler.
                        <li>Tienen que sacar $3 de sus sobres de dinero para el alquiler mensual. Recoge (tú o el coleccionista
                            de dinero) los $3 de cada estudiante.</li>
                        <li>Lo estudiantes ahora contarán cuánto les sobra después de pagar el alquiler.</li>
                        <li>Explícales que pueden gastar cuánto quieran en la tienda, pero también pueden ahorrar para poder
                            comprar cosas más caras.</li>
                        <li>Permítales suficiente tiempo para hacer sus compras.</li>
                    </ul>

                    <div class="headerTime">
                        <b>Debate de siguimiento</b>
                        <span>10 minutos</span>
                    </div>
                    <p>Después de cerrar la tienda, dirige una discusión con los estudiantes. Ésta es una buena oportunidad
                        para reforzar la importancia de ahorrar.</p>
                </div>
            </div>

            <!-- YEAR END WRAP UP -->
            <div id="yearEnd" class=" gradesInnerContent hidden">
                <h3 class="k1 english">Year-end wrap-up</h3>
                <div class="infoBlock english">

                    <p>Plan a time near the end of the year for students to reflect on what they have learned in the program
                        and for you to tie everything together.</p>
                    <p>It is also a time to celebrate the students' successes and reward them for their hard work.</p>

                    <b>Certificates</b>
                    <p>Students should be rewarded for participating in the program and saving their classroom money.</p>
                    <p>We suggest giving certificates to all of your students to celebrate their achievements (use our certificate).</p>

                    <b>Debriefing session</b>
                    <p> You may also want to hold a brief discussion with your students to reinforce what they have learned.
                        Here are some questions to help guide the discussion:</p>
                    <ul>
                        <li>What did you learn about money this year?</li>
                        <li>What was your favorite part about having money?</li>
                        <li>How can you save more money next year?</li>
                    </ul>

                    <b>Class rewards</b>
                    <p>Another option is to allow students to pool their money to purchase a class reward, such as:</p>
                    <ul>
                        <li>A class party.</li>
                        <li>A movie day.</li>
                        <li>A crazy hat day.</li>
                    </ul>

                </div>
                <h3 class="k1 spanish hidden">Conclusión del año</h3>
                <div class="infoBlock spanish hidden">

                    <p>A finales del año, planea una conclusión del programa para que los estudiantes puedan reflexionar sobre
                        lo que aprendieron.</p>
                    <p>Ésta es una oportunidad de celebrar los éxitos de los estudiantes y de premiarlos por su trabajo.</p>

                    <b>Certificados</b>
                    <p>Hay que premiar a los estudiantes por participar en el programa y ahorrar su dinero.</p>
                    <p>Sugerimos les des certificados a todos los estudiantes para celebrar sus logros (utiliza nuestro certificado).
                    </p>

                    <b>Debate de seguimiento</b>
                    <p>Los estudiantes beneficiarán de una discusión para reforzar lo que aprendieron durante el año. Le presentamos
                        algunas preguntas para guiar la discusión:</p>
                    <ul>
                        <li>¿Qué aprendiste sobre el dinero este año?</li>
                        <li>¿Qué te gustó más de tener dinero?</li>
                        <li>¿Cómo puedes ahorra más el año que viene?</li>
                    </ul>

                    <b>Premios para la clase</b>
                    <p>Puedes permitirles a los estudiantes que agreguen su dinero para comprar un premio para la clase, por
                        ejemplo:
                    </p>
                    <ul>
                        <li>Una fiesta.</li>
                        <li>Día de cine.</li>
                        <li>Día para llevar sombreros cómicos.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<grades-footer></grades-footer>