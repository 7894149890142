<div class="container main" id="skrollr-body">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <h3 class="about">Terms of Use</h3>

    <b>1. Introduction</b>
    <p>Welcome to My Classroom Economy—Vanguard’s proprietary financial literary program that enables educators to teach
      their students about financial responsibility through fun, experiential learning.</p>
    <p>These Terms of Use (“Terms of Use”) outline the products and services provided to you on this website and the
      conditions you must follow to use the My Classroom Economy website (the “Site”) and the materials provided herein
      (the “Materials”). If you do not agree to these Terms of Use, do not access or use the Site or the Materials.</p>
    <p>Vanguard may periodically update or modify these Terms of Use, and any such modifications will be effective
      immediately. You should regularly check these Terms of Use for any modifications. </p>
    <p>Your use of the Site and the Materials is subject to our Privacy Policy, which outlines how we collect, use,
      share, and store you and your students’ personal information.</p>
    <p>Nothing contained in these Terms of Use is intended to modify or amend any other written agreement you may have
      with Vanguard (whether related to your use of the Site or otherwise).</p>

    <b>2. The My Classroom Economy Program</b>
    <p>The My Classroom Economy program is comprised of a range of resources and guidance that enables educators to
      create their own “economy” within their classroom. For example, the program provides resources and guidelines for
      students to earn “money” for performing “jobs” in the classroom, to earn bonuses for strong academic performance
      and good behavior, to purchase goods and services within the classroom, to impose “fines” on students for certain
      acts or behavior, and to save and invest their “money” for future use.</p>
    <p>Vanguard offers two My Classroom Economy experiences through the Site:</p>
    <ul>
      <li>a full digital experience that allows educators to create user accounts, enroll their students in the My
        Classroom Economy program, and administer the program entirely digitally through the Site (the “Digital
        Experience”); and
      </li>
      <li>a “paper based” experience that allows educators to download and print the Materials directly from the Site,
        and to use the Materials to administer the program in their classrooms (the “Paper Based Experience”).
      </li>
    </ul>
    <p>Both experiences are offered for educational and/or illustrative purposes only. Although the features of both
      experiences are intended to simulate real-life economic scenarios and principles, nothing in the My Classroom
      Economy program should be construed as factual representations of any real economy, as financial, legal or tax
      advice or recommendations, or as the views of Vanguard, its affiliates, or its employees.</p>
    <p>No currency offered as part of the My Classroom Economy program holds any cash value.</p>
    <p>For the avoidance of doubt, and unless explicitly stated otherwise herein, your participation in the Digital
      Experience and/or the Paper Based Experience is governed by these Terms of Use.</p>

    <b>3. Limited License and Restrictions on Use</b>
    <p>Vanguard grants you a limited, revocable, non-exclusive, non-transferable license to (a) access, view and use the
      Site and the Digital Experience, and display the Site and Digital Experience to yourself and to your students, and
      (b) access, view, use, download and print the Materials available on the Site, and distribute, forward or transmit
      the Materials to your students, all solely to provide education or instruction to your students (except as
      otherwise authorized by Vanguard in writing). You are responsible for obtaining and maintaining any equipment,
      services or materials that you need to access the Site. Vanguard reserves all rights not expressly granted to you
      in this Section 3 and, more broadly, these Terms of Use.</p>
    <p>Except as otherwise stated in these Terms of Use (or as otherwise authorized by Vanguard in writing), you may not
      (and may not enable others to):</p>
    <ul>
      <li>modify, copy, create derivative works from, sell, or commercially exploit any portion or element of the Site,
        the Materials, the Digital Experience, or the Paper Based Experience;
      </li>
      <li>display or redeliver any portion of the Site, the Materials, the Digital Experience, or the Paper Based
        Experience using “framing” or similar technology;
      </li>
      <li>engage in any conduct that could damage, disable or overburden the Site, the Materials, the Digital
        Experience, or the Paper Based Experience, or any systems, networks, servers or accounts related to the
        foregoing, including without limitation, using devices or software that provide repeated automated access to the
        Site; or
      </li>
      <li>probe, scan or test the vulnerability of the Site, the Materials, the Digital Experience, or the Paper Based
        Experience, or attempt to gain unauthorized access to systems, networks, servers or accounts connected or
        associated with this Site through hacking, password or data mining, or any other means of circumventing any
        access-limiting, user authentication or security device of any services, systems, networks, servers or accounts
        related to this Site.
      </li>
    </ul>
    <p>You further agree that you will not use the Site to submit, upload, post, e-mail, transmit, or otherwise make
      available any content that:</p>
    <ul>
      <li>is unlawful, harmful, threatening, abusive, menacing, harassing, tortious, defamatory, vulgar, pornographic,
        obscene, offensive, libelous, invasive of another’s privacy or publicity rights, hateful, or otherwise
        objectionable;
      </li>
      <li>is derogatory toward Vanguard, its affiliates, its employees, or any Vanguard product or service; or</li>
      <li>infringes, violates, or misappropriates any patent, trademark, trade secret, copyright, or other intellectual
        property right.
      </li>
    </ul>

    <b>4. Terms of Use Specific to the Digital Experience</b>
    <u>Creating Accounts</u>
    <p>To participate in the Digital Experience, you must create an account. Accounts are not required to access the
      Site or the Materials generally, or to use the Paper Based Experience.</p>
    <p>To create an account, you must be an educator (e.g., teacher, principal, classroom aide, or other education
      professional). You are required to provide your name, school name and zip code, and email address as part of the
      registration process. By creating an account, you warrant and represent that you are authorized to use the Site,
      Materials, the Digital Experience, and/or the Paper Based Experience with your students on behalf of your school
      and/or district, and that you have obtained any required consent from the parents and guardians of your students
      to use the Site, Materials, the Digital Experience, and/or the Paper Based Experience. Vanguard reserves the
      right, but shall not be obligated, to verify your employment and other personal information provided. Vanguard
      reserves the right to terminate your account if you do not meet the requirements of this Section 4.</p>
    <u>Student Accounts</u>
    <p>Educators have the option of creating and managing accounts for individual students in their class. Students are
      not permitted to create accounts for themselves.</p>
    <p>Should an educator opt to create a student account(s), they should provide the first name (and last initial if
      they have multiple students with the same first name) for each student. They can either create unique usernames
      for each student or allow the Site to randomly generate a unique username for each student. Students can log into
      the Site for the first time using their unique username and a generic temporary password provided to all students
      in the class. Students will then create their own unique password for future logins. Should a student forget their
      password, they will need to reach out to the appropriate educator to reset the password.</p>
    <u>Cost</u>
    <p>There is currently no cost to access and use the Digital Experience. However, Vanguard reserves the right to
      charge for access and use of the Digital Experience in the future. Vanguard will communicate any decision to
      charge for the Digital Experience in advance.</p>
    <u>Functionality</u>
    <p>Vanguard is always working to develop the Digital Experience and create new functions and features for the
      Digital Experience. However, Vanguard has no responsibility to maintain any function or feature of the Digital
      Experience.</p>
    <u>Data Sharing, Privacy and Security</u>
    <p>By providing data through the Site and the Digital Experience, you grant Vanguard a limited, revocable,
      non-exclusive, non-transferable, fully paid, worldwide license to access, use, display and format the data for the
      purpose of providing the Site and the Digital Experience to you.</p>
    <p>Other than as outlined in the “Creating Accounts” and “Student Accounts” sections above, you should not provide
      any personally identifiable information to Vanguard through the Digital Experience. To the extent such information
      is provided, please review the My Classroom Economy Privacy Policy, which governs the ways that Vanguard may use
      that information.</p>
    <p>Although Vanguard takes measures to maintain and safeguard your data, Vanguard does not guarantee the
      confidentiality, integrity, or availability of any data (personally identifiable or otherwise) provided to
      Vanguard through the Digital Experience, and cannot guarantee its ability to restore, retrieve, or recreate such
      data. You acknowledge that you provide any such data at your own risk.</p>
    <u>Communication with Vanguard</u>
    <p>Vanguard reserves the right to communicate with you via email about updates to the functionality and features of
      the Site and the Digital Experience. You may opt out of any such communications by contacting us. Additionally,
      you may contact Vanguard directly regarding the Site, the Digital Experience, or your account by filling out the
      “Contact Us” form on the Site.</p>

    <b>5. Terms of Use Specific to the Paper Based Experience</b>
    <u>Ordering Printed Materials</u>
    <p>If your school lacks the resources to print or copy Materials from the Site, you may request that Vanguard send
      you printed Materials free of charge by contacting us. Vanguard reserves the right to deny any such request, or to
      cease the practice of providing free printed Materials in general, in its sole discretion.</p>

    <b>6. Intellectual Property</b>
    <p>Vanguard owns all right, title and interest in and to the Site, the Materials, the Digital Experience, and the
      Paper Based Experience, as well as the VANGUARD, MY CLASSROOM ECONOMY, and MY HOME ECONOMY trademarks (the
      “Vanguard Marks”). Your rights in and to Vanguard’s intellectual property are strictly limited to the license
      grant outlined in Section 3 above.</p>
    <p>You are not permitted to use or display the Vanguard Marks, any content from the Site, or the Materials, in any
      manner that could, in Vanguard’s sole discretion, suggest any sponsorship or endorsement between or among you,
      your school or school district, Vanguard, and/or the My Classroom Economy program.</p>

    <b>7. Copyright Notice and Claim Information</b>
    <p>Pursuant to Section 512(c)(2) of the Copyright Revision Act, as enacted through the Digital Millennium Copyright
      Act, Vanguard designates an agent as described below to receive notifications of claimed copyright infringement:
    <p>Pamela Crocker<br>
      Associate General Counsel<br>
      The Vanguard Group, Inc.<br>
      P.O. Box 2600<br>
      Valley Forge, PA 19482-2600<br>
      Telephone: (610) 669-6100<br>
      Email: <a href="mailTo:intellectualproperty@vanguard.com">intellectualproperty@vanguard.com</a>
    </p>

    <b>8. Third Party Content</b>
    <p>To the extent data and other materials appearing on this Site is provided by third parties, Vanguard cannot
      guarantee and is not responsible for their accuracy, timeliness, completeness or suitability for use. Vanguard is
      not responsible for, and does not prepare, edit or endorse, the content, advertising, products or other materials
      on or available from any website owned or operated by a third party that is linked to this Site via hyperlink. The
      fact that Vanguard has provided a link to a third party's website does not constitute an implicit or explicit
      endorsement, authorization, sponsorship or affiliation by Vanguard with respect to such website, its owners,
      providers or services. You will use any such third-party content at your own risk and you agree that Vanguard is
      not liable for any loss or damage that you may suffer by using third party websites or any content, advertising,
      products or other materials in connection therewith.</p>

    <b>9. Termination</b>
    <p>Any rights granted to you herein will terminate immediately upon any violation by you of these Terms of Use.
      Vanguard reserves the right to permanently or temporarily terminate your access to the Site, the Materials, the
      Digital Experience (and any accounts you create on your behalf or on the behalf of your students) and the Paper
      Based Experience for any reason, in Vanguard’s sole discretion, and without any notice or liability to you.</p>

    <b>10. Warranty Disclaimers</b>
    <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</p>
    <p>YOUR USE OF THIS SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE FULL EXTENT PERMITTED UNDER
      APPLICABLE LAW, VANGUARD AND ITS AFFILIATES, SUBSIDIARIES, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, LICENSORS AND
      ANY THIRD PARTY INFORMATION PROVIDERS AND VENDORS EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED,
      INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A
      PARTICULAR PURPOSE, AND ALL WARRANTIES REGARDING SECURITY, CURRENCY, CORRECTNESS, QUALITY, ACCURACY, COMPLETENESS,
      RELIABILITY, PERFORMANCE, TIMELINESS OR CONTINUED AVAILABILITY, WITH RESPECT TO (I) THE SITE; (II) ANY MATERIALS,
      PRODUCTS OR SERVICES AVAILABLE ON OR THROUGH THE SITE; (III) USE OF THE SITE, MATERIALS, PRODUCTS OR SERVICES; AND
      (IV) THE RESULTS OF THE USE OF THE SITE, MATERIALS, PRODUCTS OR SERVICES.</p>
    <p>FURTHER, VANGUARD AND ITS AFFILIATES, SUBSIDIARIES, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, LICENSORS AND ANY
      THIRD-PARTY INFORMATION PROVIDERS AND VENDORS EXPRESSLY DISCLAIM ALL WARRANTIES WITH RESPECT TO ANY DELAYS OR
      ERRORS IN THE TRANSMISSION OR DELIVERY OF ANY MATERIALS, PRODUCTS OR SERVICES AVAILABLE THROUGH THIS SITE.</p>
    <p>EXCEPT AS PROVIDED BY LAW, NEITHER VANGUARD NOR ITS THIRD-PARTY INFORMATION PROVIDERS AND VENDORS HAS ANY
      RESPONSIBILITY TO MAINTAIN THE MATERIALS, PRODUCTS OR SERVICES OFFERED ON THE SITE OR TO SUPPLY CORRECTIONS,
      UPDATES OR RELEASES FOR THE SAME.</p>
    <p>TO THE EXTENT ANY JURISDICTION DOES NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SOME OF THE ABOVE EXCLUSIONS
      MAY NOT APPLY TO YOU. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY HAVE OTHER RIGHTS, WHICH VARY
      FROM JURISDICTION TO JURISDICTION.</p>

    <b>11. Liability and Indemnity</b>
    <p>ANY MATERIALS DOWNLOADED OR OTHERWISE OBTAINED THROUGH THIS SITE ARE DONE AT YOUR OWN RISK. YOU ARE SOLELY
      RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER EQUIPMENT, OR LOSS OF DATA THAT RESULTS FROM SUCH
      DOWNLOAD.</p>
    <p>NEITHER VANGUARD NOR ITS AFFILIATES, SUBSIDIARIES, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, LICENSORS OR ANY THIRD
      PARTY INFORMATION PROVIDERS AND VENDORS WILL BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
      CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, REVENUE,
      INCOME, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, OR DAMAGES CAUSED BY THEFT, UNAUTHORIZED ACCESS, SYSTEMS
      FAILURE OR COMMUNICATIONS LINE FAILURE, OR THE COST OF PROCURING SUBSTITUTE GOODS OR SERVICES, CAUSED BY THE USE
      OF OR INABILITY TO USE THE SITE, MATERIALS OR ANY PRODUCTS OR SERVICES PROVIDED HEREIN, OR ANY OTHER MATTER
      RELATING TO THIS SITE, EVEN IF VANGUARD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURSIDICTIONS DO
      NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSIONS OR
      LIMITATIONS MAY NOT APPLY TO YOU. TO THE EXTENT THAT A JURISDICTION DOES NOT PERMIT THE EXCLUSION OR LIMITATION OF
      LIABILITY AS SET FORTH HEREIN, THE LIABILITY OF VANGUARD AND ITS AFFILIATES, SUBSIDIARIES, DIRECTORS, OFFICERS,
      EMPLOYEES, AGENTS, LICENSORS AND ANY THIRD-PARTY INFORMATION PROVIDERS AND VENDORS IS LIMITED TO THE EXTENT
      PERMITTED BY LAW IN SUCH JURISDICTIONS.</p>
    <p>You agree to indemnify, defend and hold harmless Vanguard, its subsidiaries and affiliates, and each of its and
      their officers, directors, employees and agents, from and against all claims, demands, liabilities, damages,
      losses or expenses, including attorney's fees and costs, arising out of or related to your improper use of this
      Site, or any violation by you of these Terms of Use.</p>

    <b>12. Integration and Severability</b>
    <p>If any provision of these Terms of Use is deemed unlawful, void or unenforceable, then that provision will be
      deemed severable from these Terms of Use and will not affect the validity and enforceability of the remaining
      provisions.</p>
    <p>These Terms of Use represent the entire agreement between you and Vanguard relating to the subject matter
      herein.</p>

    <b>13. Applicable Law and Venue</b>
    <p>The laws of the Commonwealth of Pennsylvania, United States of America, without regard to principles of conflict
      of laws, govern these Terms of Use and any dispute that might arise between you and Vanguard. If you take legal
      action relating to these Terms of Use, you agree to file such action either in the Court of Common Pleas of
      Chester County, Pennsylvania, or the United States District Court for the Eastern District of Pennsylvania and in
      no other venue, and you consent and submit to the personal jurisdiction of those courts for the purposes of
      litigating any such action.</p>

  </div>
</div>