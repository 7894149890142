<div class="parallax">
  <div class="gradesHeaderImg container">
    <div class="heroContent english">
      <h1>About Us</h1>
    </div>
    <div class="heroContent spanish hidden">
      <h1>Sobre Nosotros</h1>
    </div>
  </div>
  <section class="backgroundImages" style="top: 0px;">
    <div class="centeredDiv zoom-images">
      <div anchor-target="body" class="heroOverlay"
           data-600-start="background:rgba(0, 0, 0, 0.5)" data-start="background:rgba(0, 0, 0, 0)"></div>
      <div anchor-target="body" class="backgroundImage" data-600-start="transform: scale(1.3)"
           data-start="transform: scale(1)" id="aboutHeader"></div>
    </div>
  </section>
  <div class="container main" id="skrollr-body">
    <div class="col-md-4 col-sm-4 col-xs-12 sidebar about english">
      <div class="sidebarNav">
            <span class="sideNav active" href="#theIdea">
               <h4 class="about" id="theIdeaLink">The Idea</h4>
            </span>
      </div>
      <div class="sidebarNav">
            <span class="sideNav" href="#vanguard">
               <h4 class="about">Vanguard</h4>
            </span>
      </div>
      <div class="sidebarNav">
            <span class="sideNav" href="#acknowledgements">
               <h4 class="about">Acknowledgements</h4>
            </span>
      </div>
      <div class="sidebarNav">
            <span class="sideNav" href="#contact">
               <h4 class="about" id="contactUsLink">Contact Us</h4>
            </span>
      </div>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-12 sidebar about spanish hidden">
      <div class="sidebarNav">
            <span class="sideNav active" href="#theIdea">
               <h4 class="about" id="theIdeaLink">La Idea</h4>
            </span>
      </div>
      <div class="sidebarNav">
            <span class="sideNav" href="#vanguard">
               <h4 class="about">Vanguard</h4>
            </span>
      </div>
      <div class="sidebarNav">
            <span class="sideNav" href="#acknowledgements">
               <h4 class="about">Agradecimientos</h4>
            </span>
      </div>
      <div class="sidebarNav">
            <span class="sideNav" href="#contact">
               <h4 class="about" id="contactUsLink">Contactenos</h4>
            </span>
      </div>
    </div>
    <div class="col-md-8 col-sm-8 col-xs-12 gradeContent">
      <!-- THE IDEA -->
      <div class="gradesInnerContent about" id="theIdea">
        <h3 class="about english">The Idea</h3>
        <div class="infoBlock english">
          <div class="rightImg"><img src="assets/images/projector.svg"></div>
          <b>It started with a 10-year-old . . .</b>
          <p>Vanguard, a leading investment management company, is committed to financial literacy for all ages. We
            started the classroom economy project after an employee's 10-year-old son, a fourth-grader, asked a
            seemingly simple question during dinner: "I wonder when I'll be able to pay off my mortgage?"</p>
          <p>Intrigued by the discovery that her son had a classroom mortgage and was earning a paycheck for his
            classroom job, the employee contacted her son's teacher. </p>
          <p>The classroom economy idea offered a way to teach young people not only the concept of financial
            discipline but also—very importantly—the rewards that go with it.</p>
          <p>Vanguard set out to build on those ideas and to make them available, free of charge, to teachers
            everywhere, knowing that the more help we can provide to educators, the more they can do to teach
            children financial skills that can last a lifetime.</p>
          <p>Vanguard employees were invited to donate their time and expertise to the project and have done so
            enthusiastically. As a result, we are able to offer program guides and supporting materials to any
            U.S. teacher who wants to try this method of classroom management, and to help his or her students
            learn the benefits of financial responsibility by experiencing it.</p>
          <p>If you have questions or would like to give us feedback about the My Classroom Economy program or this
            website please <a href="/aboutus?contact=true">contact us</a>.</p>
        </div>
        <h3 class="about spanish hidden">La Idea</h3>
        <div class="infoBlock spanish hidden">
          <div class="rightImg"><img src="assets/images/projector.svg"></div>
          <b>Comenzó con un niño de 10 años . . .</b>
          <p>Vanguard, una empresa líder en administración de inversiones, está comprometida con la educación
            financiera para todas las edades. Empezamos el proyecto de My Classroom Economy después de que un hijo
            de 10 años de un empleado, un alumno de cuarto grado, formuló una pregunta aparentemente simple
            durante la cena: "Me preguntó cuándo podré pagar mi hipoteca?"</p>
          <p>Intrigada por el descubrimiento de que su hijo tenía una hipoteca en la clase y estaba ganando un
            sueldo por su trabajo en la clase, la empleada se puso en contacto con el profesor de su hijo.</p>
          <p>La idea de la economía de la clase ofrece una manera de enseñar a los jóvenes no solo el concepto de
            disciplina financiera sino también, muy importante, las recompensas que lo acompañan.</p>
          <p>Vanguard se propuso desarrollar esas ideas y ponerlas a disposición, de forma gratuita, para los
            estudiantes de todo el mundo. Sabiendo que mientras más ayuda podamos proporcionar a los profesores,
            más podrán hacer para enseñar a los niños habilidades financieras que les durarán toda la vida.</p>
          <p>Los empleados de Vanguard fueron invitados a donar su tiempo y experiencia al proyecto y lo han hecho
            de manera entusiasta. Como resultado, podemos ofrecer Guías de Programas y materiales de apoyo a
            cualquier profesor en los Estados Unidos que desee probar este método de gestión de la clase y ayudar
            a sus estudiantes de una manera practica aprender los beneficios de la responsabilidad financiera.</p>
          <p>Si tiene alguna pregunta o quiere darnos su opinión sobre el programa My Classroom Economy o este
            sitio de la web, <a href="/aboutus?contact=true">contáctenos</a>.</p>
        </div>
      </div>
      <!-- VANGUARD -->
      <div class="gradesInnerContent about hidden" id="vanguard">
        <h3 class="about">Vanguard</h3>
        <div class="infoBlock english">
                <span>
               <div class="rightImg"><img src="assets/images/vanguard.svg"></div>
               <b>A longtime advocate of financial education</b>
                </span>
          <p>Vanguard is one of the
            world's largest global
            investment management firms, serving more than 20 million investors in the
            United States and abroad. We are unique among mutual fund companies because
            we're client-owned (Vanguard is owned by its funds, which are owned by our
            clients).</p>
          <p>Our clients come first in everything we do. Vanguard has long advocated for
            investor education, recognizing the correlation between investment success and
            the application of basic financial principles.</p>
          <p>The company is also committed to the well-being of the community, both locally
            and broadly. The Vanguard Gives Back program supports employees in donating
            their time, talent, and treasure to assist the areas where we live and work.
            More than half of Vanguard's 17,000 crew members give their time to a variety of
            programs, including educational programs, group volunteer days, food drives,
            holiday giving, and workplace giving campaigns.</p>
          <p>My Classroom Economy brings together our dedication to financial education and
            our desire to support the broad community. Employees from across the
            organization volunteered their time to create this website and all it contains.
          </p>
        </div>
        <div class="infoBlock spanish hidden">
          <div class="rightImg"><img src="assets/images/vanguard.svg"></div>
          <b>Es un defensor de la educación financiera, desde hace mucho tiempo</b>
          <p>Vanguard es una de las firmas de inversiones globales más grandes del mundo, y presta
            servicios a más de 20 millones de inversionistas en los Estados Unidos y en el extranjero. Somos
            únicos entre las compañías de fondos mutuos porque somos propiedad del cliente (es decir, Vanguard es
            propiedad de sus fondos, que son propiedad de nuestros clientes).</p>
          <p>Nuestros clientes son lo primero en todo lo que hacemos. Vanguard ha defendido durante mucho tiempo la
            educación de los inversionistas, reconociendo la correlación entre el éxito de la inversión y la
            aplicación de principios financieros básicos.</p>
          <p>La empresa también está comprometida con el bienestar de la comunidad, tanto a nivel local como a
            nivel general. El programa Vanguard Gives Back ayuda a los empleados a donar su tiempo y talento para
            ayudar a las comunidades donde vivimos y trabajamos. Más de la mitad de los 17,000
            miembros de la tripulación de
            Vanguard dedican su tiempo a una variedad de programas, que incluyen programas educativos, días de
            voluntariado grupal, donaciones de alimentos, feriados y campañas de donaciones en el lugar de trabajo
          </p>
          <p>My Classroom Economy combina nuestra dedicación a la educación financiera con nuestro deseo de apoyar
            a la comunidad. Los empleados de toda la organización ofrecen voluntariamente su tiempo para crear
            este sitio de la web y todo lo que contiene.</p>
        </div>
      </div>
      <div class="gradesInnerContent about hidden" id="acknowledgements">
        <h3 class="about english">Acknowledgements</h3>
        <div class="infoBlock english">
          <div class="rightImg"><img src="assets/images/paper.svg"></div>
          <b>Developed with the help of educators</b>
          <p>In developing the program, materials, and website for My Classroom Economy, we sought the feedback and
            guidance of educators like you.</p>
          <p>Our special thanks go to these contributors:</p>
          <p><strong>Chris Salerno</strong>, the fourth-grade teacher who first introduced us to this experiential
            learning technique. His contributions to our program have been immeasurable. Mr. Salerno's
            implementation of the classroom economy, inspired Vanguard to begin the project.</p>
          <p>By providing samples of his materials, answering numerous questions, and allowing the team to watch
            the classroom economy in action. Mr. Salerno has been an invaluable resource.</p>
          <p><strong>Research and pilot participants</strong>, who provided insights into the daily activities of
            the classroom and delivered feedback about the program and the resources we are providing. We are
            deeply appreciative of the schools, the administrators, and especially the teachers who participated
            in a pilot group using My Classroom Economy resources during the 2011-2012 school year. Their
            criticisms, suggestions, and creative ideas help continue to shape the program.</p>
        </div>
        <h3 class="about spanish hidden">Agradecimientos</h3>
        <div class="infoBlock spanish hidden">
          <div class="rightImg"><img src="assets/images/paper.svg"></div>
          <b>Desarrollado con la ayuda de profesores</b>
          <p>Al desarrollar el programa, los materiales, y el sitio de la web de My Classroom Economy, buscamos los
            comentarios y opiniones de profesores como usted.</p>
          <p>Nuestro agradecimiento especial a estos contribuyentes:</p>
          <p><strong>Chris Salerno</strong>, el maestro de cuarto grado que nos introdujo por primera vez a esta
            técnica de aprendizaje experiencial. Sus contribuciones a nuestro programa han sido inconmensurables.
            La implementación del Sr. Salerno de un programa de economía a su clase inspiró a Vanguard para
            comenzar el proyecto.</p>
          <p>Al proporcionar muestras de sus materiales, responder numerosas preguntas, y permitir que el equipo
            observara el programa de economía en acción en sus clases, el Sr. Salerno ha sido un recurso
            invaluable.</p>
          <p><strong>Investigadores y participantes piloto</strong>, quienes proporcionaron información sobre las
            actividades diarias de la clase y enviaron comentarios sobre el programa y los recursos que estamos
            ofreciendo. Estamos profundamente agradecidos a las escuelas, los administradores y especialmente los
            profesores que participaron en un grupo piloto utilizando los recursos de My Classroom Economy durante
            el año escolar 2011-2012. Sus críticas, sugerencias, e ideas creativas ayudan a seguir dándole forma
            al programa.</p>
        </div>
      </div>
      <div class="gradesInnerContent hidden" id="contact">
        <app-contact-us>
        </app-contact-us>
      </div>
  </div>
</div>