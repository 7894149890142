import { Component, ViewEncapsulation } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'grades11',
  templateUrl: './grades11-12.component.html',
  styleUrls: ['./grades11-12.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Grades1112Component {
  constructor(
    public modalService: ModalService,
  ) { }
}
