import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_DOMAIN_EXTERNAL, API_PATH } from "../config/api.constants";
import { Observable } from "rxjs";
import { RequestBody } from "src/app/models/event-interface";
import { MaterialRequestAttribute } from "src/app/models/material-interface";


@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private readonly http: HttpClient) { }

    createMaterial(materialDetails: MaterialRequestAttribute): Observable<any> {
        const apiRequestBody: RequestBody = {
          endpoint: API_PATH.createMaterial,
          method: 'POST',
          requestBody: materialDetails
        };
        return this.http.post<any>(API_DOMAIN_EXTERNAL.url, apiRequestBody);
    }

    validateZipCode(zipCode: string): Observable<any> {
        const apiRequestBody: RequestBody = {
          endpoint: API_PATH.validateZipCode + zipCode,
          method: 'GET',
          requestBody: null
        };
        return this.http.post<any>(API_DOMAIN_EXTERNAL.url, apiRequestBody);
    }
}
