<div class="container">
    <div class="footer_padding english">
        <footer>&copy;2011-2024 The Vanguard Group, Inc. All rights reserved.
            <a href="/terms">Terms & conditions</a> |
            <a href="/privacy">Privacy policy</a> |
            <a href="/aboutus?contact=true">Contact us</a> |
            <a href="https://www.facebook.com/groups/myclassroomeconomy/"><img src="assets/images/icons/facebook-icon.png" width="42" height="46" title="Facebook Link" alt="Facebook" style="display: inline"></a>
        </footer>
    </div>
    <div class="footer_padding spanish hidden">
        <footer>&copy;2011-2024 The Vanguard Group, Inc. tiene todos los derechos reservados.
            <a href="/terms">Términos y Condiciones</a> |
            <a href="/privacy">Póliza de Privacidad</a> |
            <a href="/aboutus?contact=true">Contáctenos</a>
        </footer>
    </div>
</div>
