import { Injectable } from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { MaterialsFormComponent } from "src/app/materials-form/materials-form.components";
import Cookie from 'js-cookie';
import { AdobeLaunchService } from "./adobe-launch.service";

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    ngbModalOptions: ModalOptions = {
        backdrop: 'static',
        class: 'custom-modal'
      };
      
    constructor(
        private readonly modalService: BsModalService,
        public modalRef: BsModalRef,
        private readonly adobeLaunchService: AdobeLaunchService
    ) { }

    openMaterialsForm(materialUrlPath: string) {
        const fileDetails = this.getFileInfo(materialUrlPath);

        if (Cookie.get('canDownloadMaterials') !== 'true') {
            this.ngbModalOptions.initialState = {
                materialUrlPath,
                fileDetails
            };
            this.modalRef = this.modalService.show(
                MaterialsFormComponent,
                this.ngbModalOptions
            );
        } else {
            //Adobe launch implementation
            this.adobeLaunchService.contentDownloaded(fileDetails);

            window.open(materialUrlPath, '_blank');
        }
    }

    getFileInfo(filePath) {
        const fileArray = filePath.split('/')
        const filelNameArray = fileArray.pop().split('.')
      
        return {
            fileName: filelNameArray[0] || '',
            fileType: filelNameArray[1] || '',
            fileURL: filePath
        };
      }
}
