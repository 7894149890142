<div class="container main">
  <div class="col-md-12 col-sm-12 col-xs-12" style="margin:0 auto;">
    <div class="col-md-3 col-sm-12 col-xs-12"></div>
    <div class="col-md-6 col-sm-12 col-xs-12" style="margin-top:50px;margin-bottom:50px;">
      <img src="assets/images/papersIcongreen.svg" alt="Papers icon"  class="hidden-xs" style="float:right;">
      <h2 class="caption_headings">Oops!</h2>
      <div class="english">
        <p>The page you requested cannot be found.</p>
        <p>Try our <a href="/home">Home page</a> if you continue to get this message.</p>
      </div>
      <div class="spanish hidden">
        <p>La página solicitada no pudo ser encontrada.</p>
        <p>Pruebe nuestra <a href="/home">página de inicio</a> si continúa recibiendo este mensaje.</p>
      </div>
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12"></div>
  </div>
</div>