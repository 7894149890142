import { Component, ViewEncapsulation } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'grades6',
  templateUrl: './grades6.component.html',
  styleUrls: ['./grades6.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Grades6Component {
  constructor(
    public modalService: ModalService
  ) { }
}
