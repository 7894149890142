import { Component, ViewEncapsulation } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'grades2-3',
  templateUrl: './grades2-3.component.html',
  styleUrls: ['./grades2-3.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Grades23Component {
  constructor(
    public modalService: ModalService
  ) { }
}
