<div class="container marketing main">
  <div style="text-align:center">
    <img src="assets/images/banner_01.png" width="100%">
  </div>
  <br>
  <br>
  <br>
  <div class="col-md-11 col-sm-12 col-xs-12 welcomeCopy">
    <!--<h2 class="welcome_header">Healthy financial habits can begin at any age and last a lifetime.</h2>-->
    <h1 class="welcome_header english">My Classroom Economy has reached 1 million students!</h1>
    <br>
    <div style="text-align:center">
      <video controls src="https://vanguard.vo.llnwd.net/o1/EXT/MyCE/MyCE_presentation_v2.mp4" type="video/mp4"
             width="100%"></video>
    </div>
    <br>

    <p>
      The My Classroom Economy numbers have doubled since 2016 and in late 2020 we surpassed the very exciting milestone
      of connecting with our one millionth student.
    </p>
  </div>
  <div style="text-align:center">
    <img src="assets/images/timeline.png" width="100%">
  </div>
  <div class="col-md-11 col-sm-12 col-xs-12 welcomeCopy" style="padding-top: 2%; padding-bottom: 1%;">
    <p>
      Powered entirely by Vanguard volunteers, the program has developed and spread as a financial education force. MyCE
      runs
      in all 50 states and several foreign countries and is backed by about 150 Vanguard volunteers and countless family
      and friends,
      who advocate for the program and spread the word among their personal networks.
    </p>
  </div>
  <div style="text-align:center; padding-bottom: 10%;">
    <img src="assets/images/logo_bottom.png" width="100%">
  </div>
</div>

<div style="clear:both;"></div>